@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Metal+Mania&display=swap");
@font-face {
  font-family: micro;
  src: url(./images/Micro.d0788893.woff2) format("woff2");
}

@font-face {
  font-family: rainy;
  src: url(./images/rainyhearts.ttf);
}

body .MuiPaper-elevation1 {
  /* box-shadow:0px 5px 15px rgb(7 12 16 / 73%); */
  /* box-shadow: 0px 0px 3px 3px rgb(7 94 165 / 86%); */
  /* box-shadow: 0px 0px 3px 3px rgb(184 115 251); */
  /* box-shadow: 0px 3px 5px -1px rgb(197 122 224 / 73%),
    0px 5px 8px 0px rgb(212 131 241 / 62%),
    0px 1px 14px 0px rgb(240 145 255 / 79%); */
}

body .MuiPaper-elevation2 {
  /* box-shadow: 0px 5px 5px rgb(20 41 61 / 73%); */
  /* box-shadow: 0px 0px 3px 3px rgb(7 94 165 / 86%); */
  /* box-shadow: 0px 5px 5px rgb(127 72 185 / 76%); */
  /* box-shadow: 0px 3px 5px -1px rgb(197 122 224 / 43%),
    0px 5px 8px 0px rgb(212 131 241 / 47%),
    0px 1px 14px 0px rgb(158 63 173 / 59%); */
}

body {
  /* background-color: #0e151a; */
  /* background: linear-gradient(
    180deg,
    rgb(212 210 210) 0,
    #cdcdcde6 100%
  ) !important; */
  background-color: transparent !important;
}

.MuiCardHeader-content {
  color: #faf5f5 !important;
  padding-top: 18px;
  font-weight: bold;
}

.MuiCardHeader-subheader {
  color: #9e9e9e !important;
}

.MuiDivider-root {
  background-color: rgb(255 255 255 / 70%) !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
}

.MuiDataGrid-root {
  color: white !important;
  opacity: 0.9 !important;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  text-align: center !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  border: 0.5px solid rgba(239, 238, 238, 0.732) !important;
  text-overflow: ellipsis;
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 30px;
  }
}

.MuiCardContent-root {
  padding: 24px 0 !important;
}
