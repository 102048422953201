@import url(https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Metal+Mania&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;500&display=swap);
@font-face {
  font-family: micro;
  src: url(/static/media/Micro.d0788893.f8989cf0.woff2) format("woff2");
}

@font-face {
  font-family: rainy;
  src: url(/static/media/rainyhearts.2f088ce2.ttf);
}

body .MuiPaper-elevation1 {
  /* box-shadow:0px 5px 15px rgb(7 12 16 / 73%); */
  /* box-shadow: 0px 0px 3px 3px rgb(7 94 165 / 86%); */
  /* box-shadow: 0px 0px 3px 3px rgb(184 115 251); */
  /* box-shadow: 0px 3px 5px -1px rgb(197 122 224 / 73%),
    0px 5px 8px 0px rgb(212 131 241 / 62%),
    0px 1px 14px 0px rgb(240 145 255 / 79%); */
}

body .MuiPaper-elevation2 {
  /* box-shadow: 0px 5px 5px rgb(20 41 61 / 73%); */
  /* box-shadow: 0px 0px 3px 3px rgb(7 94 165 / 86%); */
  /* box-shadow: 0px 5px 5px rgb(127 72 185 / 76%); */
  /* box-shadow: 0px 3px 5px -1px rgb(197 122 224 / 43%),
    0px 5px 8px 0px rgb(212 131 241 / 47%),
    0px 1px 14px 0px rgb(158 63 173 / 59%); */
}

body {
  /* background-color: #0e151a; */
  /* background: linear-gradient(
    180deg,
    rgb(212 210 210) 0,
    #cdcdcde6 100%
  ) !important; */
  background-color: transparent !important;
}

.MuiCardHeader-content {
  color: #faf5f5 !important;
  padding-top: 18px;
  font-weight: bold;
}

.MuiCardHeader-subheader {
  color: #9e9e9e !important;
}

.MuiDivider-root {
  background-color: rgb(255 255 255 / 70%) !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
}

.MuiDataGrid-root {
  color: white !important;
  opacity: 0.9 !important;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  text-align: center !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  border: 0.5px solid rgba(239, 238, 238, 0.732) !important;
  text-overflow: ellipsis;
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 30px;
  }
}

.MuiCardContent-root {
  padding: 24px 0 !important;
}

@charset "UTF-8"; /*!
 * HUD v1.6.0 (https://seantheme.com/hud/)
 * Copyright 2022 SeanTheme
 */ /*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
body{
  cursor: url(/static/media/Trident.2f124991.png), auto;	
}
 

:root {
  --bs-blue: #4e9cff;
  --bs-indigo: #bba4ff;
  --bs-purple: #de8cff;
  --bs-pink: #e83293;
  --bs-red: #ff6060;
  --bs-orange: #ff9f0c;
  --bs-yellow: #f9e80d;
  --bs-green: #1ecc33;
  --bs-teal: #3cd2a5;
  --bs-cyan: #30beff;
  --bs-white: #ffffff;
  --bs-gray: #526872;
  --bs-gray-dark: #35434a;
  --bs-gray-100: #c0cacf;
  --bs-gray-200: #a8b6bc;
  --bs-gray-300: #90a2aa;
  --bs-gray-400: #798e98;
  --bs-gray-500: #617a86;
  --bs-gray-600: #526872;
  --bs-gray-700: #44555e;
  --bs-gray-800: #35434a;
  --bs-gray-900: #273136;
  --bs-default: #a8b6bc;
  --bs-theme: #000;
  --bs-primary: #4e9cff;
  --bs-secondary: #798e98;
  --bs-warning: #ff9f0c;
  --bs-danger: #ff6060;
  --bs-success: #e83293;
  --bs-info: #30beff;
  --bs-dark: #35434a;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-purple: #de8cff;
  --bs-indigo: #bba4ff;
  --bs-red: #ff6060;
  --bs-pink: #e83293;
  --bs-green: #1ecc33;
  --bs-yellow: #f9e80d;
  --bs-teal: #3cd2a5;
  --bs-muted: #617a86;
  --bs-light: #c0cacf;
  --bs-lime: #81f32d;
  --bs-gray-100: #c0cacf;
  --bs-gray-200: #a8b6bc;
  --bs-gray-300: #90a2aa;
  --bs-gray-400: #798e98;
  --bs-gray-500: #617a86;
  --bs-gray-600: #526872;
  --bs-gray-700: #44555e;
  --bs-gray-800: #35434a;
  --bs-gray-900: #273136;
  --bs-default-rgb: 168, 182, 188;
  --bs-theme-rgb: 232, 50, 147;
  --bs-primary-rgb: 78, 156, 255;
  --bs-secondary-rgb: 121, 142, 152;
  --bs-warning-rgb: 255, 159, 12;
  --bs-danger-rgb: 255, 96, 96;
  --bs-success-rgb: 232, 50, 147;
  --bs-info-rgb: 48, 190, 255;
  --bs-dark-rgb: 53, 67, 74;
  --bs-black-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-purple-rgb: 222, 140, 255;
  --bs-indigo-rgb: 187, 164, 255;
  --bs-red-rgb: 255, 96, 96;
  --bs-pink-rgb: 232, 50, 147;
  --bs-green-rgb: 30, 204, 51;
  --bs-yellow-rgb: 249, 232, 13;
  --bs-teal-rgb: 60, 210, 165;
  --bs-muted-rgb: 97, 122, 134;
  --bs-light-rgb: 192, 202, 207;
  --bs-lime-rgb: 129, 243, 45;
  --bs-gray-100-rgb: 192, 202, 207;
  --bs-gray-200-rgb: 168, 182, 188;
  --bs-gray-300-rgb: 144, 162, 170;
  --bs-gray-400-rgb: 121, 142, 152;
  --bs-gray-500-rgb: 97, 122, 134;
  --bs-gray-600-rgb: 82, 104, 114;
  --bs-gray-700-rgb: 68, 85, 94;
  --bs-gray-800-rgb: 53, 67, 74;
  --bs-gray-900-rgb: 39, 49, 54;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg-rgb: 39, 49, 54;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: Chakra Petch, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: rgba(255, 255, 255, 0.75);
  --bs-body-bg: #273136;
}
*,
::after,
::before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: Chakra Petch, sans-serif;
  font-family: var(--bs-body-font-family);
  font-size: 0.875rem;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  font-weight: var(--bs-body-font-weight);
  line-height: 1.5;
  line-height: var(--bs-body-line-height);
  color: rgba(255, 255, 255, 0.75);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: #273136;
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
}
.h1,
h1 {
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.1875rem;
  }
}
.h2,
h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.75rem;
  }
}
.h3,
h3 {
  font-size: calc(1.278125rem + 0.3375vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.53125rem;
  }
}
.h4,
h4 {
  font-size: calc(1.25625rem + 0.075vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.3125rem;
  }
}
.h5,
h5 {
  font-size: 1.09375rem;
}
.h6,
h6 {
  font-size: 0.875rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-bs-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 600;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.875em;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #e83293;
  text-decoration: underline;
}
a:hover {
  color: rgba(232, 50, 147, 0.5);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: #e83293;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #273136;
  border-radius: 2px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #798e98;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::-webkit-file-upload-button {
  font: inherit;
}
::file-selector-button {
  font: inherit;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #526872;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #273136;
  border: 1px solid #90a2aa;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: #526872;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 8px;
  padding-right: var(--bs-gutter-x, 8px);
  padding-left: 8px;
  padding-left: var(--bs-gutter-x, 8px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1660px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 16px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col {
  flex: 1 0;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1660px) {
  .col-xxl {
    flex: 1 0;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1900px) {
  .col-xxxl {
    flex: 1 0;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: rgba(255, 255, 255, 0.75);
  --bs-table-striped-bg: rgba(255, 255, 255, 0.1);
  --bs-table-active-color: rgba(255, 255, 255, 0.75);
  --bs-table-active-bg: rgba(255, 255, 255, 0.15);
  --bs-table-hover-color: rgba(255, 255, 255, 0.75);
  --bs-table-hover-bg: rgba(255, 255, 255, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.75);
  vertical-align: top;
  border-color: rgba(255, 255, 255, 0.3);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.table-primary {
  --bs-table-bg: #274e80;
  --bs-table-striped-bg: #3d608d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #476993;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #375b8a;
  --bs-table-hover-color: #ffffff;
  color: #fff;
  border-color: #3d608d;
}
.table-secondary {
  --bs-table-bg: #3d474c;
  --bs-table-striped-bg: #50595e;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #5a6367;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #4c5559;
  --bs-table-hover-color: #ffffff;
  color: #fff;
  border-color: #50595e;
}
.table-success {
  --bs-table-bg: #74194a;
  --bs-table-striped-bg: #82305c;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #893c65;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #7e2a58;
  --bs-table-hover-color: #ffffff;
  color: #fff;
  border-color: #82305c;
}
.table-info {
  --bs-table-bg: #185f80;
  --bs-table-striped-bg: #2f6f8d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3b7793;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #296b8a;
  --bs-table-hover-color: #ffffff;
  color: #fff;
  border-color: #2f6f8d;
}
.table-warning {
  --bs-table-bg: #805006;
  --bs-table-striped-bg: #8d621f;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #936a2b;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #8a5d19;
  --bs-table-hover-color: #ffffff;
  color: #fff;
  border-color: #8d621f;
}
.table-danger {
  --bs-table-bg: #803030;
  --bs-table-striped-bg: #8d4545;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #934f4f;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #8a4040;
  --bs-table-hover-color: #ffffff;
  color: #fff;
  border-color: #8d4545;
}
.table-light {
  --bs-table-bg: #c0cacf;
  --bs-table-striped-bg: #adb6ba;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #a3acb0;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #b2bbbf;
  --bs-table-hover-color: #000000;
  color: #000;
  border-color: #adb6ba;
}
.table-dark {
  --bs-table-bg: #35434a;
  --bs-table-striped-bg: #49565c;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #535f65;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #445158;
  --bs-table-hover-color: #ffffff;
  color: #fff;
  border-color: #49565c;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1659.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1899.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.8125rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #798e98;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.15);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.15);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: rgba(155, 155, 155, 0.1925);
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: rgba(155, 155, 155, 0.1925);
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.15);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: rgba(155, 155, 155, 0.1925);
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 2px;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}
.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 4px;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 4px;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgba(255, 255, 255, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #a8b6bc;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.75);
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.8125rem;
  border-radius: 2px;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 8px;
}
.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgba(255, 255, 255, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.form-check-input:checked {
  background-color: #e83293;
  border-color: #e83293;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba%280, 0, 0, 0.85%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='rgba%280, 0, 0, 0.85%29'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #e83293;
  border-color: #e83293;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba%280, 0, 0, 0.85%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.5;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.3%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.5%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.85%29'/%3e%3c/svg%3e");
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.65;
}
.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #e83293, 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #e83293, 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #e83293;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c52b7d;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #e83293;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c52b7d;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #90a2aa;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #90a2aa;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 2px;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #e83293;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #000;
  background-color: rgba(232, 50, 147, 0.9);
  border-radius: 4px;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #e83293;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23e83293' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #e83293;
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #e83293;
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23e83293' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #e83293;
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.25);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #e83293;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #e83293;
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #e83293;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #ff6060;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #000;
  background-color: rgba(255, 96, 96, 0.9);
  border-radius: 4px;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #ff6060;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff6060'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6060' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #ff6060;
  box-shadow: 0 0 0 0.25rem rgba(255, 96, 96, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #ff6060;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff6060'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6060' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #ff6060;
  box-shadow: 0 0 0 0.25rem rgba(255, 96, 96, 0.25);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #ff6060;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #ff6060;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 96, 96, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #ff6060;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: rgba(255, 255, 255, 0.75);
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}
.btn-default {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-default:hover {
  color: #000;
  background-color: #bec8cd;
  border-color: #bec8cd;
}
.btn-check:focus + .btn-default,
.btn-default:focus {
  color: #000;
  background-color: #bec8cd;
  border-color: #bec8cd;
  box-shadow: 0 0 0 0.25rem rgba(143, 155, 160, 0.5);
}
.btn-check:active + .btn-default,
.btn-check:checked + .btn-default,
.btn-default.active,
.btn-default:active,
.show > .btn-default.dropdown-toggle {
  color: #000;
  background-color: #c6d0d3;
  border-color: #c6d0d3;
}
.btn-check:active + .btn-default:focus,
.btn-check:checked + .btn-default:focus,
.btn-default.active:focus,
.btn-default:active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(143, 155, 160, 0.5);
}
.btn-default.disabled,
.btn-default:disabled {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-theme {
  color: rgb(255, 255, 255);
  background-color: #000000;
  border-color: #000000;
}
.btn-theme:hover {
  color: rgb(255, 255, 255);
  background-color: #000000;
  border-color: #000000;
}
.btn-check:focus + .btn-theme,
.btn-theme:focus {
  color: rgb(255, 255, 255);
  background-color: #000000;
  border-color: #000000;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5)
}
.btn-check:active + .btn-theme,
.btn-check:checked + .btn-theme,
.btn-theme.active,
.btn-theme:active,
.show > .btn-theme.dropdown-toggle {
  color: #000;
  background-color: #f07ab9;
  border-color: #f07ab9;
}
.btn-check:active + .btn-theme:focus,
.btn-check:checked + .btn-theme:focus,
.btn-theme.active:focus,
.btn-theme:active:focus,
.show > .btn-theme.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 43, 125, 0.5);
}
.btn-theme.disabled,
.btn-theme:disabled {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-primary {
  color: #000;
  background-color: #4e9cff;
  border-color: #4e9cff;
}
.btn-primary:hover {
  color: #000;
  background-color: #7ab5ff;
  border-color: #7ab5ff;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #000;
  background-color: #7ab5ff;
  border-color: #7ab5ff;
  box-shadow: 0 0 0 0.25rem rgba(66, 133, 217, 0.5);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #8cbfff;
  border-color: #8cbfff;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 133, 217, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #000;
  background-color: #4e9cff;
  border-color: #4e9cff;
}
.btn-secondary {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-secondary:hover {
  color: #000;
  background-color: #9baab2;
  border-color: #9baab2;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #000;
  background-color: #9baab2;
  border-color: #9baab2;
  box-shadow: 0 0 0 0.25rem rgba(103, 121, 129, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(103, 121, 129, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-warning {
  color: #000;
  background-color: #ff9f0c;
  border-color: #ff9f0c;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffb749;
  border-color: #ffb749;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffb749;
  border-color: #ffb749;
  box-shadow: 0 0 0 0.25rem rgba(217, 135, 10, 0.5);
}
.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffc161;
  border-color: #ffc161;
}
.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 135, 10, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #ff9f0c;
  border-color: #ff9f0c;
}
.btn-danger {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-danger:hover {
  color: #000;
  background-color: #f88;
  border-color: #f88;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #000;
  background-color: #f88;
  border-color: #f88;
  box-shadow: 0 0 0 0.25rem rgba(217, 82, 82, 0.5);
}
.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #ff9898;
  border-color: #ff9898;
}
.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 82, 82, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-success {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-success:hover {
  color: #000;
  background-color: #ee65ae;
  border-color: #ee65ae;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #000;
  background-color: #ee65ae;
  border-color: #ee65ae;
  box-shadow: 0 0 0 0.25rem rgba(197, 43, 125, 0.5);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #f07ab9;
  border-color: #f07ab9;
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 43, 125, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-info {
  color: #000;
  background-color: #30beff;
  border-color: #30beff;
}
.btn-info:hover {
  color: #000;
  background-color: #64ceff;
  border-color: #64ceff;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #64ceff;
  border-color: #64ceff;
  box-shadow: 0 0 0 0.25rem rgba(41, 162, 217, 0.5);
}
.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #78d5ff;
  border-color: #78d5ff;
}
.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(41, 162, 217, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #000;
  background-color: #30beff;
  border-color: #30beff;
}
.btn-dark {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-dark:hover {
  color: #fff;
  background-color: #283238;
  border-color: #283238;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #283238;
  border-color: #283238;
  box-shadow: 0 0 0 0.25rem rgba(83, 95, 101, 0.5);
}
.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #222c30;
  border-color: #222c30;
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(83, 95, 101, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black:focus,
.btn-check:focus + .btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-black.active,
.btn-black:active,
.btn-check:active + .btn-black,
.btn-check:checked + .btn-black,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-black.active:focus,
.btn-black:active:focus,
.btn-check:active + .btn-black:focus,
.btn-check:checked + .btn-black:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-white,
.btn-white:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:active + .btn-white,
.btn-check:checked + .btn-white,
.btn-white.active,
.btn-white:active,
.show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:active + .btn-white:focus,
.btn-check:checked + .btn-white:focus,
.btn-white.active:focus,
.btn-white:active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-purple {
  color: #000;
  background-color: #de8cff;
  border-color: #de8cff;
}
.btn-purple:hover {
  color: #000;
  background-color: #e6a9ff;
  border-color: #e6a9ff;
}
.btn-check:focus + .btn-purple,
.btn-purple:focus {
  color: #000;
  background-color: #e6a9ff;
  border-color: #e6a9ff;
  box-shadow: 0 0 0 0.25rem rgba(189, 119, 217, 0.5);
}
.btn-check:active + .btn-purple,
.btn-check:checked + .btn-purple,
.btn-purple.active,
.btn-purple:active,
.show > .btn-purple.dropdown-toggle {
  color: #000;
  background-color: #eab4ff;
  border-color: #eab4ff;
}
.btn-check:active + .btn-purple:focus,
.btn-check:checked + .btn-purple:focus,
.btn-purple.active:focus,
.btn-purple:active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 119, 217, 0.5);
}
.btn-purple.disabled,
.btn-purple:disabled {
  color: #000;
  background-color: #de8cff;
  border-color: #de8cff;
}
.btn-indigo {
  color: #000;
  background-color: #bba4ff;
  border-color: #bba4ff;
}
.btn-indigo:hover {
  color: #000;
  background-color: #cbf;
  border-color: #cbf;
}
.btn-check:focus + .btn-indigo,
.btn-indigo:focus {
  color: #000;
  background-color: #cbf;
  border-color: #cbf;
  box-shadow: 0 0 0 0.25rem rgba(159, 139, 217, 0.5);
}
.btn-check:active + .btn-indigo,
.btn-check:checked + .btn-indigo,
.btn-indigo.active,
.btn-indigo:active,
.show > .btn-indigo.dropdown-toggle {
  color: #000;
  background-color: #d3c4ff;
  border-color: #d3c4ff;
}
.btn-check:active + .btn-indigo:focus,
.btn-check:checked + .btn-indigo:focus,
.btn-indigo.active:focus,
.btn-indigo:active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(159, 139, 217, 0.5);
}
.btn-indigo.disabled,
.btn-indigo:disabled {
  color: #000;
  background-color: #bba4ff;
  border-color: #bba4ff;
}
.btn-red {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-red:hover {
  color: #000;
  background-color: #f88;
  border-color: #f88;
}
.btn-check:focus + .btn-red,
.btn-red:focus {
  color: #000;
  background-color: #f88;
  border-color: #f88;
  box-shadow: 0 0 0 0.25rem rgba(217, 82, 82, 0.5);
}
.btn-check:active + .btn-red,
.btn-check:checked + .btn-red,
.btn-red.active,
.btn-red:active,
.show > .btn-red.dropdown-toggle {
  color: #000;
  background-color: #ff9898;
  border-color: #ff9898;
}
.btn-check:active + .btn-red:focus,
.btn-check:checked + .btn-red:focus,
.btn-red.active:focus,
.btn-red:active:focus,
.show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 82, 82, 0.5);
}
.btn-red.disabled,
.btn-red:disabled {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-pink {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-pink:hover {
  color: #000;
  background-color: #ee65ae;
  border-color: #ee65ae;
}
.btn-check:focus + .btn-pink,
.btn-pink:focus {
  color: #000;
  background-color: #ee65ae;
  border-color: #ee65ae;
  box-shadow: 0 0 0 0.25rem rgba(197, 43, 125, 0.5);
}
.btn-check:active + .btn-pink,
.btn-check:checked + .btn-pink,
.btn-pink.active,
.btn-pink:active,
.show > .btn-pink.dropdown-toggle {
  color: #000;
  background-color: #f07ab9;
  border-color: #f07ab9;
}
.btn-check:active + .btn-pink:focus,
.btn-check:checked + .btn-pink:focus,
.btn-pink.active:focus,
.btn-pink:active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 43, 125, 0.5);
}
.btn-pink.disabled,
.btn-pink:disabled {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-green {
  color: #000;
  background-color: #1ecc33;
  border-color: #1ecc33;
}
.btn-green:hover {
  color: #000;
  background-color: #56d966;
  border-color: #56d966;
}
.btn-check:focus + .btn-green,
.btn-green:focus {
  color: #000;
  background-color: #56d966;
  border-color: #56d966;
  box-shadow: 0 0 0 0.25rem rgba(26, 173, 43, 0.5);
}
.btn-check:active + .btn-green,
.btn-check:checked + .btn-green,
.btn-green.active,
.btn-green:active,
.show > .btn-green.dropdown-toggle {
  color: #000;
  background-color: #6dde7a;
  border-color: #6dde7a;
}
.btn-check:active + .btn-green:focus,
.btn-check:checked + .btn-green:focus,
.btn-green.active:focus,
.btn-green:active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 173, 43, 0.5);
}
.btn-green.disabled,
.btn-green:disabled {
  color: #000;
  background-color: #1ecc33;
  border-color: #1ecc33;
}
.btn-yellow {
  color: #000;
  background-color: #f9e80d;
  border-color: #f9e80d;
}
.btn-yellow:hover {
  color: #000;
  background-color: #fbee4a;
  border-color: #fbee4a;
}
.btn-check:focus + .btn-yellow,
.btn-yellow:focus {
  color: #000;
  background-color: #fbee4a;
  border-color: #fbee4a;
  box-shadow: 0 0 0 0.25rem rgba(212, 197, 11, 0.5);
}
.btn-check:active + .btn-yellow,
.btn-check:checked + .btn-yellow,
.btn-yellow.active,
.btn-yellow:active,
.show > .btn-yellow.dropdown-toggle {
  color: #000;
  background-color: #fbf062;
  border-color: #fbf062;
}
.btn-check:active + .btn-yellow:focus,
.btn-check:checked + .btn-yellow:focus,
.btn-yellow.active:focus,
.btn-yellow:active:focus,
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(212, 197, 11, 0.5);
}
.btn-yellow.disabled,
.btn-yellow:disabled {
  color: #000;
  background-color: #f9e80d;
  border-color: #f9e80d;
}
.btn-teal {
  color: #000;
  background-color: #3cd2a5;
  border-color: #3cd2a5;
}
.btn-teal:hover {
  color: #000;
  background-color: #6dddbc;
  border-color: #6dddbc;
}
.btn-check:focus + .btn-teal,
.btn-teal:focus {
  color: #000;
  background-color: #6dddbc;
  border-color: #6dddbc;
  box-shadow: 0 0 0 0.25rem rgba(51, 179, 140, 0.5);
}
.btn-check:active + .btn-teal,
.btn-check:checked + .btn-teal,
.btn-teal.active,
.btn-teal:active,
.show > .btn-teal.dropdown-toggle {
  color: #000;
  background-color: #80e2c5;
  border-color: #80e2c5;
}
.btn-check:active + .btn-teal:focus,
.btn-check:checked + .btn-teal:focus,
.btn-teal.active:focus,
.btn-teal:active:focus,
.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(51, 179, 140, 0.5);
}
.btn-teal.disabled,
.btn-teal:disabled {
  color: #000;
  background-color: #3cd2a5;
  border-color: #3cd2a5;
}
.btn-muted {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-muted:hover {
  color: #fff;
  background-color: #495c65;
  border-color: #495c65;
}
.btn-check:focus + .btn-muted,
.btn-muted:focus {
  color: #fff;
  background-color: #495c65;
  border-color: #495c65;
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-check:active + .btn-muted,
.btn-check:checked + .btn-muted,
.btn-muted.active,
.btn-muted:active,
.show > .btn-muted.dropdown-toggle {
  color: #fff;
  background-color: #3f4f57;
  border-color: #3f4f57;
}
.btn-check:active + .btn-muted:focus,
.btn-check:checked + .btn-muted:focus,
.btn-muted.active:focus,
.btn-muted:active:focus,
.show > .btn-muted.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-muted.disabled,
.btn-muted:disabled {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-light {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-light:hover {
  color: #000;
  background-color: #d0d7db;
  border-color: #d0d7db;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #d0d7db;
  border-color: #d0d7db;
  box-shadow: 0 0 0 0.25rem rgba(163, 172, 176, 0.5);
}
.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #d6dde0;
  border-color: #d6dde0;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 172, 176, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-lime {
  color: #000;
  background-color: #81f32d;
  border-color: #81f32d;
}
.btn-lime:hover {
  color: #000;
  background-color: #a1f662;
  border-color: #a1f662;
}
.btn-check:focus + .btn-lime,
.btn-lime:focus {
  color: #000;
  background-color: #a1f662;
  border-color: #a1f662;
  box-shadow: 0 0 0 0.25rem rgba(110, 207, 38, 0.5);
}
.btn-check:active + .btn-lime,
.btn-check:checked + .btn-lime,
.btn-lime.active,
.btn-lime:active,
.show > .btn-lime.dropdown-toggle {
  color: #000;
  background-color: #adf777;
  border-color: #adf777;
}
.btn-check:active + .btn-lime:focus,
.btn-check:checked + .btn-lime:focus,
.btn-lime.active:focus,
.btn-lime:active:focus,
.show > .btn-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 207, 38, 0.5);
}
.btn-lime.disabled,
.btn-lime:disabled {
  color: #000;
  background-color: #81f32d;
  border-color: #81f32d;
}
.btn-gray-100 {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-gray-100:hover {
  color: #000;
  background-color: #d0d7db;
  border-color: #d0d7db;
}
.btn-check:focus + .btn-gray-100,
.btn-gray-100:focus {
  color: #000;
  background-color: #d0d7db;
  border-color: #d0d7db;
  box-shadow: 0 0 0 0.25rem rgba(163, 172, 176, 0.5);
}
.btn-check:active + .btn-gray-100,
.btn-check:checked + .btn-gray-100,
.btn-gray-100.active,
.btn-gray-100:active,
.show > .btn-gray-100.dropdown-toggle {
  color: #000;
  background-color: #d6dde0;
  border-color: #d6dde0;
}
.btn-check:active + .btn-gray-100:focus,
.btn-check:checked + .btn-gray-100:focus,
.btn-gray-100.active:focus,
.btn-gray-100:active:focus,
.show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 172, 176, 0.5);
}
.btn-gray-100.disabled,
.btn-gray-100:disabled {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-gray-200 {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-gray-200:hover {
  color: #000;
  background-color: #bec8cd;
  border-color: #bec8cd;
}
.btn-check:focus + .btn-gray-200,
.btn-gray-200:focus {
  color: #000;
  background-color: #bec8cd;
  border-color: #bec8cd;
  box-shadow: 0 0 0 0.25rem rgba(143, 155, 160, 0.5);
}
.btn-check:active + .btn-gray-200,
.btn-check:checked + .btn-gray-200,
.btn-gray-200.active,
.btn-gray-200:active,
.show > .btn-gray-200.dropdown-toggle {
  color: #000;
  background-color: #c6d0d3;
  border-color: #c6d0d3;
}
.btn-check:active + .btn-gray-200:focus,
.btn-check:checked + .btn-gray-200:focus,
.btn-gray-200.active:focus,
.btn-gray-200:active:focus,
.show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(143, 155, 160, 0.5);
}
.btn-gray-200.disabled,
.btn-gray-200:disabled {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-gray-300 {
  color: #000;
  background-color: #90a2aa;
  border-color: #90a2aa;
}
.btn-gray-300:hover {
  color: #000;
  background-color: #acb9bf;
  border-color: #acb9bf;
}
.btn-check:focus + .btn-gray-300,
.btn-gray-300:focus {
  color: #000;
  background-color: #acb9bf;
  border-color: #acb9bf;
  box-shadow: 0 0 0 0.25rem rgba(122, 138, 145, 0.5);
}
.btn-check:active + .btn-gray-300,
.btn-check:checked + .btn-gray-300,
.btn-gray-300.active,
.btn-gray-300:active,
.show > .btn-gray-300.dropdown-toggle {
  color: #000;
  background-color: #b7c3c8;
  border-color: #b7c3c8;
}
.btn-check:active + .btn-gray-300:focus,
.btn-check:checked + .btn-gray-300:focus,
.btn-gray-300.active:focus,
.btn-gray-300:active:focus,
.show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(122, 138, 145, 0.5);
}
.btn-gray-300.disabled,
.btn-gray-300:disabled {
  color: #000;
  background-color: #90a2aa;
  border-color: #90a2aa;
}
.btn-gray-400 {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-gray-400:hover {
  color: #000;
  background-color: #9baab2;
  border-color: #9baab2;
}
.btn-check:focus + .btn-gray-400,
.btn-gray-400:focus {
  color: #000;
  background-color: #9baab2;
  border-color: #9baab2;
  box-shadow: 0 0 0 0.25rem rgba(103, 121, 129, 0.5);
}
.btn-check:active + .btn-gray-400,
.btn-check:checked + .btn-gray-400,
.btn-gray-400.active,
.btn-gray-400:active,
.show > .btn-gray-400.dropdown-toggle {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active + .btn-gray-400:focus,
.btn-check:checked + .btn-gray-400:focus,
.btn-gray-400.active:focus,
.btn-gray-400:active:focus,
.show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(103, 121, 129, 0.5);
}
.btn-gray-400.disabled,
.btn-gray-400:disabled {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-gray-500 {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-gray-500:hover {
  color: #fff;
  background-color: #495c65;
  border-color: #495c65;
}
.btn-check:focus + .btn-gray-500,
.btn-gray-500:focus {
  color: #fff;
  background-color: #495c65;
  border-color: #495c65;
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-check:active + .btn-gray-500,
.btn-check:checked + .btn-gray-500,
.btn-gray-500.active,
.btn-gray-500:active,
.show > .btn-gray-500.dropdown-toggle {
  color: #fff;
  background-color: #3f4f57;
  border-color: #3f4f57;
}
.btn-check:active + .btn-gray-500:focus,
.btn-check:checked + .btn-gray-500:focus,
.btn-gray-500.active:focus,
.btn-gray-500:active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-gray-500.disabled,
.btn-gray-500:disabled {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-gray-600 {
  color: #fff;
  background-color: #526872;
  border-color: #526872;
}
.btn-gray-600:hover {
  color: #fff;
  background-color: #3e4e56;
  border-color: #3e4e56;
}
.btn-check:focus + .btn-gray-600,
.btn-gray-600:focus {
  color: #fff;
  background-color: #3e4e56;
  border-color: #3e4e56;
  box-shadow: 0 0 0 0.25rem rgba(108, 127, 135, 0.5);
}
.btn-check:active + .btn-gray-600,
.btn-check:checked + .btn-gray-600,
.btn-gray-600.active,
.btn-gray-600:active,
.show > .btn-gray-600.dropdown-toggle {
  color: #fff;
  background-color: #35444a;
  border-color: #35444a;
}
.btn-check:active + .btn-gray-600:focus,
.btn-check:checked + .btn-gray-600:focus,
.btn-gray-600.active:focus,
.btn-gray-600:active:focus,
.show > .btn-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 127, 135, 0.5);
}
.btn-gray-600.disabled,
.btn-gray-600:disabled {
  color: #fff;
  background-color: #526872;
  border-color: #526872;
}
.btn-gray-700 {
  color: #fff;
  background-color: #44555e;
  border-color: #44555e;
}
.btn-gray-700:hover {
  color: #fff;
  background-color: #334047;
  border-color: #334047;
}
.btn-check:focus + .btn-gray-700,
.btn-gray-700:focus {
  color: #fff;
  background-color: #334047;
  border-color: #334047;
  box-shadow: 0 0 0 0.25rem rgba(96, 111, 118, 0.5);
}
.btn-check:active + .btn-gray-700,
.btn-check:checked + .btn-gray-700,
.btn-gray-700.active,
.btn-gray-700:active,
.show > .btn-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #2c373d;
  border-color: #2c373d;
}
.btn-check:active + .btn-gray-700:focus,
.btn-check:checked + .btn-gray-700:focus,
.btn-gray-700.active:focus,
.btn-gray-700:active:focus,
.show > .btn-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(96, 111, 118, 0.5);
}
.btn-gray-700.disabled,
.btn-gray-700:disabled {
  color: #fff;
  background-color: #44555e;
  border-color: #44555e;
}
.btn-gray-800 {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-gray-800:hover {
  color: #fff;
  background-color: #283238;
  border-color: #283238;
}
.btn-check:focus + .btn-gray-800,
.btn-gray-800:focus {
  color: #fff;
  background-color: #283238;
  border-color: #283238;
  box-shadow: 0 0 0 0.25rem rgba(83, 95, 101, 0.5);
}
.btn-check:active + .btn-gray-800,
.btn-check:checked + .btn-gray-800,
.btn-gray-800.active,
.btn-gray-800:active,
.show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #222c30;
  border-color: #222c30;
}
.btn-check:active + .btn-gray-800:focus,
.btn-check:checked + .btn-gray-800:focus,
.btn-gray-800.active:focus,
.btn-gray-800:active:focus,
.show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(83, 95, 101, 0.5);
}
.btn-gray-800.disabled,
.btn-gray-800:disabled {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-gray-900 {
  color: #fff;
  background-color: #273136;
  border-color: #273136;
}
.btn-gray-900:hover {
  color: #fff;
  background-color: #1d2529;
  border-color: #1d2529;
}
.btn-check:focus + .btn-gray-900,
.btn-gray-900:focus {
  color: #fff;
  background-color: #1d2529;
  border-color: #1d2529;
  box-shadow: 0 0 0 0.25rem rgba(71, 80, 84, 0.5);
}
.btn-check:active + .btn-gray-900,
.btn-check:checked + .btn-gray-900,
.btn-gray-900.active,
.btn-gray-900:active,
.show > .btn-gray-900.dropdown-toggle {
  color: #fff;
  background-color: #192023;
  border-color: #192023;
}
.btn-check:active + .btn-gray-900:focus,
.btn-check:checked + .btn-gray-900:focus,
.btn-gray-900.active:focus,
.btn-gray-900:active:focus,
.show > .btn-gray-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(71, 80, 84, 0.5);
}
.btn-gray-900.disabled,
.btn-gray-900:disabled {
  color: #fff;
  background-color: #273136;
  border-color: #273136;
}
.btn-outline-default {
  color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-outline-default:hover {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:focus + .btn-outline-default,
.btn-outline-default:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-check:active + .btn-outline-default,
.btn-check:checked + .btn-outline-default,
.btn-outline-default.active,
.btn-outline-default.dropdown-toggle.show,
.btn-outline-default:active {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active + .btn-outline-default:focus,
.btn-check:checked + .btn-outline-default:focus,
.btn-outline-default.active:focus,
.btn-outline-default.dropdown-toggle.show:focus,
.btn-outline-default:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-outline-default.disabled,
.btn-outline-default:disabled {
  color: #a8b6bc;
  background-color: transparent;
}
.btn-outline-theme {
  color: #e83293;
  border-color: #e83293;
}
.btn-outline-theme:hover {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:focus + .btn-outline-theme,
.btn-outline-theme:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
.btn-check:active + .btn-outline-theme,
.btn-check:checked + .btn-outline-theme,
.btn-outline-theme.active,
.btn-outline-theme.dropdown-toggle.show,
.btn-outline-theme:active {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:active + .btn-outline-theme:focus,
.btn-check:checked + .btn-outline-theme:focus,
.btn-outline-theme.active:focus,
.btn-outline-theme.dropdown-toggle.show:focus,
.btn-outline-theme:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
.btn-outline-theme.disabled,
.btn-outline-theme:disabled {
  color: #e83293;
  background-color: transparent;
}
.btn-outline-primary {
  color: #4e9cff;
  border-color: #4e9cff;
}
.btn-outline-primary:hover {
  color: #000;
  background-color: #4e9cff;
  border-color: #4e9cff;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(78, 156, 255, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #000;
  background-color: #4e9cff;
  border-color: #4e9cff;
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(78, 156, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #4e9cff;
  background-color: transparent;
}
.btn-outline-secondary {
  color: #798e98;
  border-color: #798e98;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #798e98;
  background-color: transparent;
}
.btn-outline-warning {
  color: #ff9f0c;
  border-color: #ff9f0c;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ff9f0c;
  border-color: #ff9f0c;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 159, 12, 0.5);
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #ff9f0c;
  border-color: #ff9f0c;
}
.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 159, 12, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9f0c;
  background-color: transparent;
}
.btn-outline-danger {
  color: #ff6060;
  border-color: #ff6060;
}
.btn-outline-danger:hover {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 96, 96, 0.5);
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 96, 96, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ff6060;
  background-color: transparent;
}
.btn-outline-success {
  color: #e83293;
  border-color: #e83293;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #e83293;
  background-color: transparent;
}
.btn-outline-info {
  color: #30beff;
  border-color: #30beff;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #30beff;
  border-color: #30beff;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(48, 190, 255, 0.5);
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #000;
  background-color: #30beff;
  border-color: #30beff;
}
.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(48, 190, 255, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #30beff;
  background-color: transparent;
}
.btn-outline-dark {
  color: #35434a;
  border-color: #35434a;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(53, 67, 74, 0.5);
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(53, 67, 74, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #35434a;
  background-color: transparent;
}
.btn-outline-black {
  color: #000;
  border-color: #000;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:focus + .btn-outline-black,
.btn-outline-black:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:active + .btn-outline-black,
.btn-check:checked + .btn-outline-black,
.btn-outline-black.active,
.btn-outline-black.dropdown-toggle.show,
.btn-outline-black:active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:active + .btn-outline-black:focus,
.btn-check:checked + .btn-outline-black:focus,
.btn-outline-black.active:focus,
.btn-outline-black.dropdown-toggle.show:focus,
.btn-outline-black:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black.disabled,
.btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}
.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white,
.btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:active + .btn-outline-white,
.btn-check:checked + .btn-outline-white,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show,
.btn-outline-white:active {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:active + .btn-outline-white:focus,
.btn-check:checked + .btn-outline-white:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus,
.btn-outline-white:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-purple {
  color: #de8cff;
  border-color: #de8cff;
}
.btn-outline-purple:hover {
  color: #000;
  background-color: #de8cff;
  border-color: #de8cff;
}
.btn-check:focus + .btn-outline-purple,
.btn-outline-purple:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 140, 255, 0.5);
}
.btn-check:active + .btn-outline-purple,
.btn-check:checked + .btn-outline-purple,
.btn-outline-purple.active,
.btn-outline-purple.dropdown-toggle.show,
.btn-outline-purple:active {
  color: #000;
  background-color: #de8cff;
  border-color: #de8cff;
}
.btn-check:active + .btn-outline-purple:focus,
.btn-check:checked + .btn-outline-purple:focus,
.btn-outline-purple.active:focus,
.btn-outline-purple.dropdown-toggle.show:focus,
.btn-outline-purple:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 140, 255, 0.5);
}
.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #de8cff;
  background-color: transparent;
}
.btn-outline-indigo {
  color: #bba4ff;
  border-color: #bba4ff;
}
.btn-outline-indigo:hover {
  color: #000;
  background-color: #bba4ff;
  border-color: #bba4ff;
}
.btn-check:focus + .btn-outline-indigo,
.btn-outline-indigo:focus {
  box-shadow: 0 0 0 0.25rem rgba(187, 164, 255, 0.5);
}
.btn-check:active + .btn-outline-indigo,
.btn-check:checked + .btn-outline-indigo,
.btn-outline-indigo.active,
.btn-outline-indigo.dropdown-toggle.show,
.btn-outline-indigo:active {
  color: #000;
  background-color: #bba4ff;
  border-color: #bba4ff;
}
.btn-check:active + .btn-outline-indigo:focus,
.btn-check:checked + .btn-outline-indigo:focus,
.btn-outline-indigo.active:focus,
.btn-outline-indigo.dropdown-toggle.show:focus,
.btn-outline-indigo:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(187, 164, 255, 0.5);
}
.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
  color: #bba4ff;
  background-color: transparent;
}
.btn-outline-red {
  color: #ff6060;
  border-color: #ff6060;
}
.btn-outline-red:hover {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-check:focus + .btn-outline-red,
.btn-outline-red:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 96, 96, 0.5);
}
.btn-check:active + .btn-outline-red,
.btn-check:checked + .btn-outline-red,
.btn-outline-red.active,
.btn-outline-red.dropdown-toggle.show,
.btn-outline-red:active {
  color: #000;
  background-color: #ff6060;
  border-color: #ff6060;
}
.btn-check:active + .btn-outline-red:focus,
.btn-check:checked + .btn-outline-red:focus,
.btn-outline-red.active:focus,
.btn-outline-red.dropdown-toggle.show:focus,
.btn-outline-red:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 96, 96, 0.5);
}
.btn-outline-red.disabled,
.btn-outline-red:disabled {
  color: #ff6060;
  background-color: transparent;
}
.btn-outline-pink {
  color: #e83293;
  border-color: #e83293;
}
.btn-outline-pink:hover {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:focus + .btn-outline-pink,
.btn-outline-pink:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
.btn-check:active + .btn-outline-pink,
.btn-check:checked + .btn-outline-pink,
.btn-outline-pink.active,
.btn-outline-pink.dropdown-toggle.show,
.btn-outline-pink:active {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:active + .btn-outline-pink:focus,
.btn-check:checked + .btn-outline-pink:focus,
.btn-outline-pink.active:focus,
.btn-outline-pink.dropdown-toggle.show:focus,
.btn-outline-pink:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #e83293;
  background-color: transparent;
}
.btn-outline-green {
  color: #1ecc33;
  border-color: #1ecc33;
}
.btn-outline-green:hover {
  color: #000;
  background-color: #1ecc33;
  border-color: #1ecc33;
}
.btn-check:focus + .btn-outline-green,
.btn-outline-green:focus {
  box-shadow: 0 0 0 0.25rem rgba(30, 204, 51, 0.5);
}
.btn-check:active + .btn-outline-green,
.btn-check:checked + .btn-outline-green,
.btn-outline-green.active,
.btn-outline-green.dropdown-toggle.show,
.btn-outline-green:active {
  color: #000;
  background-color: #1ecc33;
  border-color: #1ecc33;
}
.btn-check:active + .btn-outline-green:focus,
.btn-check:checked + .btn-outline-green:focus,
.btn-outline-green.active:focus,
.btn-outline-green.dropdown-toggle.show:focus,
.btn-outline-green:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(30, 204, 51, 0.5);
}
.btn-outline-green.disabled,
.btn-outline-green:disabled {
  color: #1ecc33;
  background-color: transparent;
}
.btn-outline-yellow {
  color: #f9e80d;
  border-color: #f9e80d;
}
.btn-outline-yellow:hover {
  color: #000;
  background-color: #f9e80d;
  border-color: #f9e80d;
}
.btn-check:focus + .btn-outline-yellow,
.btn-outline-yellow:focus {
  box-shadow: 0 0 0 0.25rem rgba(249, 232, 13, 0.5);
}
.btn-check:active + .btn-outline-yellow,
.btn-check:checked + .btn-outline-yellow,
.btn-outline-yellow.active,
.btn-outline-yellow.dropdown-toggle.show,
.btn-outline-yellow:active {
  color: #000;
  background-color: #f9e80d;
  border-color: #f9e80d;
}
.btn-check:active + .btn-outline-yellow:focus,
.btn-check:checked + .btn-outline-yellow:focus,
.btn-outline-yellow.active:focus,
.btn-outline-yellow.dropdown-toggle.show:focus,
.btn-outline-yellow:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(249, 232, 13, 0.5);
}
.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
  color: #f9e80d;
  background-color: transparent;
}
.btn-outline-teal {
  color: #3cd2a5;
  border-color: #3cd2a5;
}
.btn-outline-teal:hover {
  color: #000;
  background-color: #3cd2a5;
  border-color: #3cd2a5;
}
.btn-check:focus + .btn-outline-teal,
.btn-outline-teal:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 210, 165, 0.5);
}
.btn-check:active + .btn-outline-teal,
.btn-check:checked + .btn-outline-teal,
.btn-outline-teal.active,
.btn-outline-teal.dropdown-toggle.show,
.btn-outline-teal:active {
  color: #000;
  background-color: #3cd2a5;
  border-color: #3cd2a5;
}
.btn-check:active + .btn-outline-teal:focus,
.btn-check:checked + .btn-outline-teal:focus,
.btn-outline-teal.active:focus,
.btn-outline-teal.dropdown-toggle.show:focus,
.btn-outline-teal:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 210, 165, 0.5);
}
.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
  color: #3cd2a5;
  background-color: transparent;
}
.btn-outline-muted {
  color: #617a86;
  border-color: #617a86;
}
.btn-outline-muted:hover {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-check:focus + .btn-outline-muted,
.btn-outline-muted:focus {
  box-shadow: 0 0 0 0.25rem rgba(97, 122, 134, 0.5);
}
.btn-check:active + .btn-outline-muted,
.btn-check:checked + .btn-outline-muted,
.btn-outline-muted.active,
.btn-outline-muted.dropdown-toggle.show,
.btn-outline-muted:active {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-check:active + .btn-outline-muted:focus,
.btn-check:checked + .btn-outline-muted:focus,
.btn-outline-muted.active:focus,
.btn-outline-muted.dropdown-toggle.show:focus,
.btn-outline-muted:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(97, 122, 134, 0.5);
}
.btn-outline-muted.disabled,
.btn-outline-muted:disabled {
  color: #617a86;
  background-color: transparent;
}
.btn-outline-light {
  color: #c0cacf;
  border-color: #c0cacf;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(192, 202, 207, 0.5);
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(192, 202, 207, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #c0cacf;
  background-color: transparent;
}
.btn-outline-lime {
  color: #81f32d;
  border-color: #81f32d;
}
.btn-outline-lime:hover {
  color: #000;
  background-color: #81f32d;
  border-color: #81f32d;
}
.btn-check:focus + .btn-outline-lime,
.btn-outline-lime:focus {
  box-shadow: 0 0 0 0.25rem rgba(129, 243, 45, 0.5);
}
.btn-check:active + .btn-outline-lime,
.btn-check:checked + .btn-outline-lime,
.btn-outline-lime.active,
.btn-outline-lime.dropdown-toggle.show,
.btn-outline-lime:active {
  color: #000;
  background-color: #81f32d;
  border-color: #81f32d;
}
.btn-check:active + .btn-outline-lime:focus,
.btn-check:checked + .btn-outline-lime:focus,
.btn-outline-lime.active:focus,
.btn-outline-lime.dropdown-toggle.show:focus,
.btn-outline-lime:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(129, 243, 45, 0.5);
}
.btn-outline-lime.disabled,
.btn-outline-lime:disabled {
  color: #81f32d;
  background-color: transparent;
}
.btn-outline-gray-100 {
  color: #c0cacf;
  border-color: #c0cacf;
}
.btn-outline-gray-100:hover {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-check:focus + .btn-outline-gray-100,
.btn-outline-gray-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(192, 202, 207, 0.5);
}
.btn-check:active + .btn-outline-gray-100,
.btn-check:checked + .btn-outline-gray-100,
.btn-outline-gray-100.active,
.btn-outline-gray-100.dropdown-toggle.show,
.btn-outline-gray-100:active {
  color: #000;
  background-color: #c0cacf;
  border-color: #c0cacf;
}
.btn-check:active + .btn-outline-gray-100:focus,
.btn-check:checked + .btn-outline-gray-100:focus,
.btn-outline-gray-100.active:focus,
.btn-outline-gray-100.dropdown-toggle.show:focus,
.btn-outline-gray-100:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(192, 202, 207, 0.5);
}
.btn-outline-gray-100.disabled,
.btn-outline-gray-100:disabled {
  color: #c0cacf;
  background-color: transparent;
}
.btn-outline-gray-200 {
  color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-outline-gray-200:hover {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:focus + .btn-outline-gray-200,
.btn-outline-gray-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-check:active + .btn-outline-gray-200,
.btn-check:checked + .btn-outline-gray-200,
.btn-outline-gray-200.active,
.btn-outline-gray-200.dropdown-toggle.show,
.btn-outline-gray-200:active {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active + .btn-outline-gray-200:focus,
.btn-check:checked + .btn-outline-gray-200:focus,
.btn-outline-gray-200.active:focus,
.btn-outline-gray-200.dropdown-toggle.show:focus,
.btn-outline-gray-200:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-outline-gray-200.disabled,
.btn-outline-gray-200:disabled {
  color: #a8b6bc;
  background-color: transparent;
}
.btn-outline-gray-300 {
  color: #90a2aa;
  border-color: #90a2aa;
}
.btn-outline-gray-300:hover {
  color: #000;
  background-color: #90a2aa;
  border-color: #90a2aa;
}
.btn-check:focus + .btn-outline-gray-300,
.btn-outline-gray-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(144, 162, 170, 0.5);
}
.btn-check:active + .btn-outline-gray-300,
.btn-check:checked + .btn-outline-gray-300,
.btn-outline-gray-300.active,
.btn-outline-gray-300.dropdown-toggle.show,
.btn-outline-gray-300:active {
  color: #000;
  background-color: #90a2aa;
  border-color: #90a2aa;
}
.btn-check:active + .btn-outline-gray-300:focus,
.btn-check:checked + .btn-outline-gray-300:focus,
.btn-outline-gray-300.active:focus,
.btn-outline-gray-300.dropdown-toggle.show:focus,
.btn-outline-gray-300:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(144, 162, 170, 0.5);
}
.btn-outline-gray-300.disabled,
.btn-outline-gray-300:disabled {
  color: #90a2aa;
  background-color: transparent;
}
.btn-outline-gray-400 {
  color: #798e98;
  border-color: #798e98;
}
.btn-outline-gray-400:hover {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-check:focus + .btn-outline-gray-400,
.btn-outline-gray-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-check:active + .btn-outline-gray-400,
.btn-check:checked + .btn-outline-gray-400,
.btn-outline-gray-400.active,
.btn-outline-gray-400.dropdown-toggle.show,
.btn-outline-gray-400:active {
  color: #000;
  background-color: #798e98;
  border-color: #798e98;
}
.btn-check:active + .btn-outline-gray-400:focus,
.btn-check:checked + .btn-outline-gray-400:focus,
.btn-outline-gray-400.active:focus,
.btn-outline-gray-400.dropdown-toggle.show:focus,
.btn-outline-gray-400:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(121, 142, 152, 0.5);
}
.btn-outline-gray-400.disabled,
.btn-outline-gray-400:disabled {
  color: #798e98;
  background-color: transparent;
}
.btn-outline-gray-500 {
  color: #617a86;
  border-color: #617a86;
}
.btn-outline-gray-500:hover {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-check:focus + .btn-outline-gray-500,
.btn-outline-gray-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(97, 122, 134, 0.5);
}
.btn-check:active + .btn-outline-gray-500,
.btn-check:checked + .btn-outline-gray-500,
.btn-outline-gray-500.active,
.btn-outline-gray-500.dropdown-toggle.show,
.btn-outline-gray-500:active {
  color: #fff;
  background-color: #617a86;
  border-color: #617a86;
}
.btn-check:active + .btn-outline-gray-500:focus,
.btn-check:checked + .btn-outline-gray-500:focus,
.btn-outline-gray-500.active:focus,
.btn-outline-gray-500.dropdown-toggle.show:focus,
.btn-outline-gray-500:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(97, 122, 134, 0.5);
}
.btn-outline-gray-500.disabled,
.btn-outline-gray-500:disabled {
  color: #617a86;
  background-color: transparent;
}
.btn-outline-gray-600 {
  color: #526872;
  border-color: #526872;
}
.btn-outline-gray-600:hover {
  color: #fff;
  background-color: #526872;
  border-color: #526872;
}
.btn-check:focus + .btn-outline-gray-600,
.btn-outline-gray-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 104, 114, 0.5);
}
.btn-check:active + .btn-outline-gray-600,
.btn-check:checked + .btn-outline-gray-600,
.btn-outline-gray-600.active,
.btn-outline-gray-600.dropdown-toggle.show,
.btn-outline-gray-600:active {
  color: #fff;
  background-color: #526872;
  border-color: #526872;
}
.btn-check:active + .btn-outline-gray-600:focus,
.btn-check:checked + .btn-outline-gray-600:focus,
.btn-outline-gray-600.active:focus,
.btn-outline-gray-600.dropdown-toggle.show:focus,
.btn-outline-gray-600:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 104, 114, 0.5);
}
.btn-outline-gray-600.disabled,
.btn-outline-gray-600:disabled {
  color: #526872;
  background-color: transparent;
}
.btn-outline-gray-700 {
  color: #44555e;
  border-color: #44555e;
}
.btn-outline-gray-700:hover {
  color: #fff;
  background-color: #44555e;
  border-color: #44555e;
}
.btn-check:focus + .btn-outline-gray-700,
.btn-outline-gray-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(68, 85, 94, 0.5);
}
.btn-check:active + .btn-outline-gray-700,
.btn-check:checked + .btn-outline-gray-700,
.btn-outline-gray-700.active,
.btn-outline-gray-700.dropdown-toggle.show,
.btn-outline-gray-700:active {
  color: #fff;
  background-color: #44555e;
  border-color: #44555e;
}
.btn-check:active + .btn-outline-gray-700:focus,
.btn-check:checked + .btn-outline-gray-700:focus,
.btn-outline-gray-700.active:focus,
.btn-outline-gray-700.dropdown-toggle.show:focus,
.btn-outline-gray-700:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(68, 85, 94, 0.5);
}
.btn-outline-gray-700.disabled,
.btn-outline-gray-700:disabled {
  color: #44555e;
  background-color: transparent;
}
.btn-outline-gray-800 {
  color: #35434a;
  border-color: #35434a;
}
.btn-outline-gray-800:hover {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-check:focus + .btn-outline-gray-800,
.btn-outline-gray-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(53, 67, 74, 0.5);
}
.btn-check:active + .btn-outline-gray-800,
.btn-check:checked + .btn-outline-gray-800,
.btn-outline-gray-800.active,
.btn-outline-gray-800.dropdown-toggle.show,
.btn-outline-gray-800:active {
  color: #fff;
  background-color: #35434a;
  border-color: #35434a;
}
.btn-check:active + .btn-outline-gray-800:focus,
.btn-check:checked + .btn-outline-gray-800:focus,
.btn-outline-gray-800.active:focus,
.btn-outline-gray-800.dropdown-toggle.show:focus,
.btn-outline-gray-800:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(53, 67, 74, 0.5);
}
.btn-outline-gray-800.disabled,
.btn-outline-gray-800:disabled {
  color: #35434a;
  background-color: transparent;
}
.btn-outline-gray-900 {
  color: #273136;
  border-color: #273136;
}
.btn-outline-gray-900:hover {
  color: #fff;
  background-color: #273136;
  border-color: #273136;
}
.btn-check:focus + .btn-outline-gray-900,
.btn-outline-gray-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 49, 54, 0.5);
}
.btn-check:active + .btn-outline-gray-900,
.btn-check:checked + .btn-outline-gray-900,
.btn-outline-gray-900.active,
.btn-outline-gray-900.dropdown-toggle.show,
.btn-outline-gray-900:active {
  color: #fff;
  background-color: #273136;
  border-color: #273136;
}
.btn-check:active + .btn-outline-gray-900:focus,
.btn-check:checked + .btn-outline-gray-900:focus,
.btn-outline-gray-900.active:focus,
.btn-outline-gray-900.dropdown-toggle.show:focus,
.btn-outline-gray-900:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 49, 54, 0.5);
}
.btn-outline-gray-900.disabled,
.btn-outline-gray-900:disabled {
  color: #273136;
  background-color: transparent;
}
.btn-link {
  font-weight: 400;
  color: #e83293;
  text-decoration: underline;
}
.btn-link:hover {
  color: rgba(232, 50, 147, 0.5);
}
.btn-link.disabled,
.btn-link:disabled {
  color: #526872;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  border-radius: 2px;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  list-style: none;
  background-color: rgba(29, 40, 53, 0.98);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1660px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1900px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 6px 20px;
  clear: both;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-theme-color);
  text-decoration: none;
  background-color: #000;
  background-color: var(--bs-theme);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #617a86;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 20px;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 6px 20px;
  color: rgba(255, 255, 255, 0.5);
}
.dropdown-menu-dark {
  color: #90a2aa;
  background-color: #35434a;
  border-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #90a2aa;
}
.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: var(--bs-theme-color);
  background-color: #000;
  background-color: var(--bs-theme);
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #617a86;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #90a2aa;
}
.dropdown-menu-dark .dropdown-header {
  color: #617a86;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #e83293;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: rgba(232, 50, 147, 0.5);
}
.nav-link.disabled {
  color: #798e98;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.3) transparent;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #798e98;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.3) transparent;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 4px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: rgba(0, 0, 0, 0.85);
  background-color: #e83293;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: 75vh;
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-sm .offcanvas-bottom,
  .navbar-expand-sm .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-md .offcanvas-bottom,
  .navbar-expand-md .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-lg .offcanvas-bottom,
  .navbar-expand-lg .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-xl .offcanvas-bottom,
  .navbar-expand-xl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1660px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-xxl .offcanvas-bottom,
  .navbar-expand-xxl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1900px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-xxxl .offcanvas-bottom,
  .navbar-expand-xxxl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  -webkit-transform: none;
          transform: none;
}
.navbar-expand .offcanvas-bottom,
.navbar-expand .offcanvas-top {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.navbar-light .navbar-brand {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.3);
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: none;
  background-clip: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.card-title {
  margin-bottom: 0.5rem;
}
.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: 1rem;
}
.card-header {
  padding: 0.55rem 1rem;
  margin-bottom: 0;
  background-color: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.card-header:first-child {
  border-radius: 0;
}
.card-footer {
  padding: 0.55rem 1rem;
  background-color: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.card-footer:last-child {
  border-radius: 0;
}
.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.55rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: none;
  border-bottom-color: none;
}
.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card-group > .card {
  margin-bottom: 8px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  background-color: rgba(29, 40, 53, 0.95);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #e83293;
  background-color: none;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e83293'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.75%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: rgba(255, 255, 255, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: rgba(29, 40, 53, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.15);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-body {
  padding: 1rem 1.25rem;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: 0.7875rem;
  list-style: none;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
  content: "/";
  content: var(--bs-breadcrumb-divider, "/");
}
.breadcrumb-item.active {
  color: #fff;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  color: #fff;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.15);
  border: 0 solid rgba(255, 255, 255, 0.3);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
}
.page-link:focus {
  z-index: 3;
  color: rgba(232, 50, 147, 0.5);
  background-color: #a8b6bc;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: rgba(0, 0, 0, 0.85);
  background-color: #e83293;
  border-color: #e83293;
}
.page-item.disabled .page-link {
  color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0);
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 4px;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 600;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}
.alert-default {
  color: #fff;
  background-color: #656d71;
  border-color: #e5e9eb;
}
.alert-default .alert-link {
  color: #ccc;
}
.alert-theme {
  color: #fff;
  background-color: #8b1e58;
  border-color: #f8c2df;
}
.alert-theme .alert-link {
  color: #ccc;
}
.alert-primary {
  color: #fff;
  background-color: #2f5e99;
  border-color: #cae1ff;
}
.alert-primary .alert-link {
  color: #ccc;
}
.alert-secondary {
  color: #fff;
  background-color: #49555b;
  border-color: #d7dde0;
}
.alert-secondary .alert-link {
  color: #ccc;
}
.alert-warning {
  color: #fff;
  background-color: #995f07;
  border-color: #ffe2b6;
}
.alert-warning .alert-link {
  color: #ccc;
}
.alert-danger {
  color: #fff;
  background-color: #993a3a;
  border-color: #ffcfcf;
}
.alert-danger .alert-link {
  color: #ccc;
}
.alert-success {
  color: #fff;
  background-color: #8b1e58;
  border-color: #f8c2df;
}
.alert-success .alert-link {
  color: #ccc;
}
.alert-info {
  color: #fff;
  background-color: #1d7299;
  border-color: #c1ecff;
}
.alert-info .alert-link {
  color: #ccc;
}
.alert-dark {
  color: #fff;
  background-color: #20282c;
  border-color: #c2c7c9;
}
.alert-dark .alert-link {
  color: #ccc;
}
.alert-black {
  color: #fff;
  background-color: #000;
  border-color: #b3b3b3;
}
.alert-black .alert-link {
  color: #ccc;
}
.alert-white {
  color: #fff;
  background-color: #999;
  border-color: #fff;
}
.alert-white .alert-link {
  color: #ccc;
}
.alert-purple {
  color: #fff;
  background-color: #855499;
  border-color: #f5ddff;
}
.alert-purple .alert-link {
  color: #ccc;
}
.alert-indigo {
  color: #fff;
  background-color: #706299;
  border-color: #ebe4ff;
}
.alert-indigo .alert-link {
  color: #ccc;
}
.alert-red {
  color: #fff;
  background-color: #993a3a;
  border-color: #ffcfcf;
}
.alert-red .alert-link {
  color: #ccc;
}
.alert-pink {
  color: #fff;
  background-color: #8b1e58;
  border-color: #f8c2df;
}
.alert-pink .alert-link {
  color: #ccc;
}
.alert-green {
  color: #fff;
  background-color: #127a1f;
  border-color: #bcf0c2;
}
.alert-green .alert-link {
  color: #ccc;
}
.alert-yellow {
  color: #f9e80d;
  background-color: #958b08;
  border-color: #fdf8b6;
}
.alert-yellow .alert-link {
  color: #c7ba0a;
}
.alert-teal {
  color: #fff;
  background-color: #247e63;
  border-color: #c5f2e4;
}
.alert-teal .alert-link {
  color: #ccc;
}
.alert-muted {
  color: #fff;
  background-color: #3a4950;
  border-color: #d0d7db;
}
.alert-muted .alert-link {
  color: #ccc;
}
.alert-light {
  color: #c0cacf;
  background-color: #73797c;
  border-color: #eceff1;
}
.alert-light .alert-link {
  color: #9aa2a6;
}
.alert-lime {
  color: #81f32d;
  background-color: #4d921b;
  border-color: #d9fbc0;
}
.alert-lime .alert-link {
  color: #67c224;
}
.alert-gray-100 {
  color: #c0cacf;
  background-color: #73797c;
  border-color: #eceff1;
}
.alert-gray-100 .alert-link {
  color: #9aa2a6;
}
.alert-gray-200 {
  color: #fff;
  background-color: #656d71;
  border-color: #e5e9eb;
}
.alert-gray-200 .alert-link {
  color: #ccc;
}
.alert-gray-300 {
  color: #fff;
  background-color: #566166;
  border-color: #dee3e6;
}
.alert-gray-300 .alert-link {
  color: #ccc;
}
.alert-gray-400 {
  color: #fff;
  background-color: #49555b;
  border-color: #d7dde0;
}
.alert-gray-400 .alert-link {
  color: #ccc;
}
.alert-gray-500 {
  color: #fff;
  background-color: #3a4950;
  border-color: #d0d7db;
}
.alert-gray-500 .alert-link {
  color: #ccc;
}
.alert-gray-600 {
  color: #fff;
  background-color: #313e44;
  border-color: #cbd2d5;
}
.alert-gray-600 .alert-link {
  color: #ccc;
}
.alert-gray-700 {
  color: #fff;
  background-color: #293338;
  border-color: #c7cccf;
}
.alert-gray-700 .alert-link {
  color: #ccc;
}
.alert-gray-800 {
  color: #fff;
  background-color: #20282c;
  border-color: #c2c7c9;
}
.alert-gray-800 .alert-link {
  color: #ccc;
}
.alert-gray-900 {
  color: #fff;
  background-color: #171d20;
  border-color: #bec1c3;
}
.alert-gray-900 .alert-link {
  color: #ccc;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4e9cff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: #fff;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #fff;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.25);
}
.list-group-item-action:active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #fff;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
  background-color: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: rgba(255, 255, 255, 0.35);
  pointer-events: none;
  background-color: none;
}
.list-group-item.active {
  z-index: 2;
  color: rgba(0, 0, 0, 0.85);
  background-color: #e83293;
  border-color: #e83293;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1660px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1900px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-default {
  color: #656d71;
  background-color: #eef0f2;
}
.list-group-item-default.list-group-item-action:focus,
.list-group-item-default.list-group-item-action:hover {
  color: #656d71;
  background-color: #d6d8da;
}
.list-group-item-default.list-group-item-action.active {
  color: #fff;
  background-color: #656d71;
  border-color: #656d71;
}
.list-group-item-theme {
  color: #8b1e58;
  background-color: #fad6e9;
}
.list-group-item-theme.list-group-item-action:focus,
.list-group-item-theme.list-group-item-action:hover {
  color: #8b1e58;
  background-color: #e1c1d2;
}
.list-group-item-theme.list-group-item-action.active {
  color: #fff;
  background-color: #8b1e58;
  border-color: #8b1e58;
}
.list-group-item-primary {
  color: #2f5e99;
  background-color: #dcebff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #2f5e99;
  background-color: #c6d4e6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2f5e99;
  border-color: #2f5e99;
}
.list-group-item-secondary {
  color: #49555b;
  background-color: #e4e8ea;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #49555b;
  background-color: #cdd1d3;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #49555b;
  border-color: #49555b;
}
.list-group-item-warning {
  color: #995f07;
  background-color: #ffecce;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #995f07;
  background-color: #e6d4b9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #995f07;
  border-color: #995f07;
}
.list-group-item-danger {
  color: #993a3a;
  background-color: #ffdfdf;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #993a3a;
  background-color: #e6c9c9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #993a3a;
  border-color: #993a3a;
}
.list-group-item-success {
  color: #8b1e58;
  background-color: #fad6e9;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #8b1e58;
  background-color: #e1c1d2;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #8b1e58;
  border-color: #8b1e58;
}
.list-group-item-info {
  color: #1d7299;
  background-color: #d6f2ff;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #1d7299;
  background-color: #c1dae6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1d7299;
  border-color: #1d7299;
}
.list-group-item-dark {
  color: #20282c;
  background-color: #d7d9db;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #20282c;
  background-color: #c2c3c5;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #20282c;
  border-color: #20282c;
}
.list-group-item-black {
  color: #000;
  background-color: #ccc;
}
.list-group-item-black.list-group-item-action:focus,
.list-group-item-black.list-group-item-action:hover {
  color: #000;
  background-color: #b8b8b8;
}
.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.list-group-item-white {
  color: #666;
  background-color: #fff;
}
.list-group-item-white.list-group-item-action:focus,
.list-group-item-white.list-group-item-action:hover {
  color: #666;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #666;
  border-color: #666;
}
.list-group-item-purple {
  color: #855499;
  background-color: #f8e8ff;
}
.list-group-item-purple.list-group-item-action:focus,
.list-group-item-purple.list-group-item-action:hover {
  color: #855499;
  background-color: #dfd1e6;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #855499;
  border-color: #855499;
}
.list-group-item-indigo {
  color: #706299;
  background-color: #f1edff;
}
.list-group-item-indigo.list-group-item-action:focus,
.list-group-item-indigo.list-group-item-action:hover {
  color: #706299;
  background-color: #d9d5e6;
}
.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #706299;
  border-color: #706299;
}
.list-group-item-red {
  color: #993a3a;
  background-color: #ffdfdf;
}
.list-group-item-red.list-group-item-action:focus,
.list-group-item-red.list-group-item-action:hover {
  color: #993a3a;
  background-color: #e6c9c9;
}
.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #993a3a;
  border-color: #993a3a;
}
.list-group-item-pink {
  color: #8b1e58;
  background-color: #fad6e9;
}
.list-group-item-pink.list-group-item-action:focus,
.list-group-item-pink.list-group-item-action:hover {
  color: #8b1e58;
  background-color: #e1c1d2;
}
.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #8b1e58;
  border-color: #8b1e58;
}
.list-group-item-green {
  color: #127a1f;
  background-color: #d2f5d6;
}
.list-group-item-green.list-group-item-action:focus,
.list-group-item-green.list-group-item-action:hover {
  color: #127a1f;
  background-color: #bdddc1;
}
.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #127a1f;
  border-color: #127a1f;
}
.list-group-item-yellow {
  color: #645d05;
  background-color: #fefacf;
}
.list-group-item-yellow.list-group-item-action:focus,
.list-group-item-yellow.list-group-item-action:hover {
  color: #645d05;
  background-color: #e5e1ba;
}
.list-group-item-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #645d05;
  border-color: #645d05;
}
.list-group-item-teal {
  color: #185442;
  background-color: #d8f6ed;
}
.list-group-item-teal.list-group-item-action:focus,
.list-group-item-teal.list-group-item-action:hover {
  color: #185442;
  background-color: #c2ddd5;
}
.list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: #185442;
  border-color: #185442;
}
.list-group-item-muted {
  color: #3a4950;
  background-color: #dfe4e7;
}
.list-group-item-muted.list-group-item-action:focus,
.list-group-item-muted.list-group-item-action:hover {
  color: #3a4950;
  background-color: #c9cdd0;
}
.list-group-item-muted.list-group-item-action.active {
  color: #fff;
  background-color: #3a4950;
  border-color: #3a4950;
}
.list-group-item-light {
  color: #4d5153;
  background-color: #f2f4f5;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #4d5153;
  background-color: #dadcdd;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #4d5153;
  border-color: #4d5153;
}
.list-group-item-lime {
  color: #346112;
  background-color: #e6fdd5;
}
.list-group-item-lime.list-group-item-action:focus,
.list-group-item-lime.list-group-item-action:hover {
  color: #346112;
  background-color: #cfe4c0;
}
.list-group-item-lime.list-group-item-action.active {
  color: #fff;
  background-color: #346112;
  border-color: #346112;
}
.list-group-item-gray-100 {
  color: #4d5153;
  background-color: #f2f4f5;
}
.list-group-item-gray-100.list-group-item-action:focus,
.list-group-item-gray-100.list-group-item-action:hover {
  color: #4d5153;
  background-color: #dadcdd;
}
.list-group-item-gray-100.list-group-item-action.active {
  color: #fff;
  background-color: #4d5153;
  border-color: #4d5153;
}
.list-group-item-gray-200 {
  color: #656d71;
  background-color: #eef0f2;
}
.list-group-item-gray-200.list-group-item-action:focus,
.list-group-item-gray-200.list-group-item-action:hover {
  color: #656d71;
  background-color: #d6d8da;
}
.list-group-item-gray-200.list-group-item-action.active {
  color: #fff;
  background-color: #656d71;
  border-color: #656d71;
}
.list-group-item-gray-300 {
  color: #566166;
  background-color: #e9ecee;
}
.list-group-item-gray-300.list-group-item-action:focus,
.list-group-item-gray-300.list-group-item-action:hover {
  color: #566166;
  background-color: #d2d4d6;
}
.list-group-item-gray-300.list-group-item-action.active {
  color: #fff;
  background-color: #566166;
  border-color: #566166;
}
.list-group-item-gray-400 {
  color: #49555b;
  background-color: #e4e8ea;
}
.list-group-item-gray-400.list-group-item-action:focus,
.list-group-item-gray-400.list-group-item-action:hover {
  color: #49555b;
  background-color: #cdd1d3;
}
.list-group-item-gray-400.list-group-item-action.active {
  color: #fff;
  background-color: #49555b;
  border-color: #49555b;
}
.list-group-item-gray-500 {
  color: #3a4950;
  background-color: #dfe4e7;
}
.list-group-item-gray-500.list-group-item-action:focus,
.list-group-item-gray-500.list-group-item-action:hover {
  color: #3a4950;
  background-color: #c9cdd0;
}
.list-group-item-gray-500.list-group-item-action.active {
  color: #fff;
  background-color: #3a4950;
  border-color: #3a4950;
}
.list-group-item-gray-600 {
  color: #313e44;
  background-color: #dce1e3;
}
.list-group-item-gray-600.list-group-item-action:focus,
.list-group-item-gray-600.list-group-item-action:hover {
  color: #313e44;
  background-color: #c6cbcc;
}
.list-group-item-gray-600.list-group-item-action.active {
  color: #fff;
  background-color: #313e44;
  border-color: #313e44;
}
.list-group-item-gray-700 {
  color: #293338;
  background-color: #dadddf;
}
.list-group-item-gray-700.list-group-item-action:focus,
.list-group-item-gray-700.list-group-item-action:hover {
  color: #293338;
  background-color: #c4c7c9;
}
.list-group-item-gray-700.list-group-item-action.active {
  color: #fff;
  background-color: #293338;
  border-color: #293338;
}
.list-group-item-gray-800 {
  color: #20282c;
  background-color: #d7d9db;
}
.list-group-item-gray-800.list-group-item-action:focus,
.list-group-item-gray-800.list-group-item-action:hover {
  color: #20282c;
  background-color: #c2c3c5;
}
.list-group-item-gray-800.list-group-item-action.active {
  color: #fff;
  background-color: #20282c;
  border-color: #20282c;
}
.list-group-item-gray-900 {
  color: #171d20;
  background-color: #d4d6d7;
}
.list-group-item-gray-900.list-group-item-action:focus,
.list-group-item-gray-900.list-group-item-action:hover {
  color: #171d20;
  background-color: #bfc1c2;
}
.list-group-item-gray-900.list-group-item-action.active {
  color: #fff;
  background-color: #171d20;
  border-color: #171d20;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #fff;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 4px;
  opacity: 0.5;
}
.btn-close:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
  opacity: 1;
}
.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(32, 43, 54, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
  border-radius: 0;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.5rem;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #fff;
  background-color: rgba(32, 43, 54, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}
.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: none;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #1d2835;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.95;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1659.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1899.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Chakra Petch", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}
.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}
.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff;
}
.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Chakra Petch", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 1rem 1rem;
  color: rgba(255, 255, 255, 0.75);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert(1) grayscale(100);
          filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: none;
  background-clip: padding-box;
  outline: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #1d2835;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.95;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}
.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.offcanvas.show {
  -webkit-transform: none;
          transform: none;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}
@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}
@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.link-default {
  color: #a8b6bc;
}
.link-default:focus,
.link-default:hover {
  color: #b9c5c9;
}
.link-theme {
  color: #e83293;
}
.link-theme:focus,
.link-theme:hover {
  color: #ed5ba9;
}
.link-primary {
  color: #4e9cff;
}
.link-primary:focus,
.link-primary:hover {
  color: #71b0ff;
}
.link-secondary {
  color: #798e98;
}
.link-secondary:focus,
.link-secondary:hover {
  color: #94a5ad;
}
.link-warning {
  color: #ff9f0c;
}
.link-warning:focus,
.link-warning:hover {
  color: #ffb23d;
}
.link-danger {
  color: #ff6060;
}
.link-danger:focus,
.link-danger:hover {
  color: #ff8080;
}
.link-success {
  color: #e83293;
}
.link-success:focus,
.link-success:hover {
  color: #ed5ba9;
}
.link-info {
  color: #30beff;
}
.link-info:focus,
.link-info:hover {
  color: #59cbff;
}
.link-dark {
  color: #35434a;
}
.link-dark:focus,
.link-dark:hover {
  color: #2a363b;
}
.link-black {
  color: #000;
}
.link-black:focus,
.link-black:hover {
  color: #000;
}
.link-white {
  color: #fff;
}
.link-white:focus,
.link-white:hover {
  color: #fff;
}
.link-purple {
  color: #de8cff;
}
.link-purple:focus,
.link-purple:hover {
  color: #e5a3ff;
}
.link-indigo {
  color: #bba4ff;
}
.link-indigo:focus,
.link-indigo:hover {
  color: #c9b6ff;
}
.link-red {
  color: #ff6060;
}
.link-red:focus,
.link-red:hover {
  color: #ff8080;
}
.link-pink {
  color: #e83293;
}
.link-pink:focus,
.link-pink:hover {
  color: #ed5ba9;
}
.link-green {
  color: #1ecc33;
}
.link-green:focus,
.link-green:hover {
  color: #4bd65c;
}
.link-yellow {
  color: #f9e80d;
}
.link-yellow:focus,
.link-yellow:hover {
  color: #faed3d;
}
.link-teal {
  color: #3cd2a5;
}
.link-teal:focus,
.link-teal:hover {
  color: #63dbb7;
}
.link-muted {
  color: #617a86;
}
.link-muted:focus,
.link-muted:hover {
  color: #4e626b;
}
.link-light {
  color: #c0cacf;
}
.link-light:focus,
.link-light:hover {
  color: #cdd5d9;
}
.link-lime {
  color: #81f32d;
}
.link-lime:focus,
.link-lime:hover {
  color: #9af557;
}
.link-gray-100 {
  color: #c0cacf;
}
.link-gray-100:focus,
.link-gray-100:hover {
  color: #cdd5d9;
}
.link-gray-200 {
  color: #a8b6bc;
}
.link-gray-200:focus,
.link-gray-200:hover {
  color: #b9c5c9;
}
.link-gray-300 {
  color: #90a2aa;
}
.link-gray-300:focus,
.link-gray-300:hover {
  color: #a6b5bb;
}
.link-gray-400 {
  color: #798e98;
}
.link-gray-400:focus,
.link-gray-400:hover {
  color: #94a5ad;
}
.link-gray-500 {
  color: #617a86;
}
.link-gray-500:focus,
.link-gray-500:hover {
  color: #4e626b;
}
.link-gray-600 {
  color: #526872;
}
.link-gray-600:focus,
.link-gray-600:hover {
  color: #42535b;
}
.link-gray-700 {
  color: #44555e;
}
.link-gray-700:focus,
.link-gray-700:hover {
  color: #36444b;
}
.link-gray-800 {
  color: #35434a;
}
.link-gray-800:focus,
.link-gray-800:hover {
  color: #2a363b;
}
.link-gray-900 {
  color: #273136;
}
.link-gray-900:focus,
.link-gray-900:hover {
  color: #1f272b;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1660px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1900px) {
  .sticky-xxxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.035) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}
.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}
.border {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-default {
  border-color: #a8b6bc !important;
}
.border-theme {
  border-color: #e83293 !important;
}
.border-primary {
  border-color: #4e9cff !important;
}
.border-secondary {
  border-color: #798e98 !important;
}
.border-warning {
  border-color: #ff9f0c !important;
}
.border-danger {
  border-color: #ff6060 !important;
}
.border-success {
  border-color: #e83293 !important;
}
.border-info {
  border-color: #30beff !important;
}
.border-dark {
  border-color: #35434a !important;
}
.border-black {
  border-color: #000 !important;
}
.border-white {
  border-color: #fff !important;
}
.border-purple {
  border-color: #de8cff !important;
}
.border-indigo {
  border-color: #bba4ff !important;
}
.border-red {
  border-color: #ff6060 !important;
}
.border-pink {
  border-color: #e83293 !important;
}
.border-green {
  border-color: #1ecc33 !important;
}
.border-yellow {
  border-color: #f9e80d !important;
}
.border-teal {
  border-color: #3cd2a5 !important;
}
.border-muted {
  border-color: #617a86 !important;
}
.border-light {
  border-color: #c0cacf !important;
}
.border-lime {
  border-color: #81f32d !important;
}
.border-gray-100 {
  border-color: #c0cacf !important;
}
.border-gray-200 {
  border-color: #a8b6bc !important;
}
.border-gray-300 {
  border-color: #90a2aa !important;
}
.border-gray-400 {
  border-color: #798e98 !important;
}
.border-gray-500 {
  border-color: #617a86 !important;
}
.border-gray-600 {
  border-color: #526872 !important;
}
.border-gray-700 {
  border-color: #44555e !important;
}
.border-gray-800 {
  border-color: #35434a !important;
}
.border-gray-900 {
  border-color: #273136 !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important;
}
.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}
.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}
.gap-3 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}
.gap-4 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}
.gap-5 {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 3rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 3rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}
.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.mt-n2 {
  margin-top: -0.5rem !important;
}
.mt-n3 {
  margin-top: -1rem !important;
}
.mt-n4 {
  margin-top: -1.5rem !important;
}
.mt-n5 {
  margin-top: -3rem !important;
}
.me-n1 {
  margin-right: -0.25rem !important;
}
.me-n2 {
  margin-right: -0.5rem !important;
}
.me-n3 {
  margin-right: -1rem !important;
}
.me-n4 {
  margin-right: -1.5rem !important;
}
.me-n5 {
  margin-right: -3rem !important;
}
.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.mb-n3 {
  margin-bottom: -1rem !important;
}
.mb-n4 {
  margin-bottom: -1.5rem !important;
}
.mb-n5 {
  margin-bottom: -3rem !important;
}
.ms-n1 {
  margin-left: -0.25rem !important;
}
.ms-n2 {
  margin-left: -0.5rem !important;
}
.ms-n3 {
  margin-left: -1rem !important;
}
.ms-n4 {
  margin-left: -1.5rem !important;
}
.ms-n5 {
  margin-left: -3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 3rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 3rem !important;
}
.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace !important;
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}
.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-3 {
  font-size: calc(1.278125rem + 0.3375vw) !important;
}
.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}
.fs-5 {
  font-size: 1.09375rem !important;
}
.fs-6 {
  font-size: 0.875rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-default {
  --bs-text-opacity: 1;
  color: rgba(168, 182, 188, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-default-rgb), var(--bs-text-opacity)) !important;
}
.text-theme {
  --bs-text-opacity: 1;
  color: rgba(232, 50, 147, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-theme-rgb), var(--bs-text-opacity)) !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(78, 156, 255, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(121, 142, 152, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(255, 159, 12, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(255, 96, 96, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(232, 50, 147, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(48, 190, 255, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(53, 67, 74, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-purple {
  --bs-text-opacity: 1;
  color: rgba(222, 140, 255, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}
.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(187, 164, 255, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}
.text-red {
  --bs-text-opacity: 1;
  color: rgba(255, 96, 96, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}
.text-pink {
  --bs-text-opacity: 1;
  color: rgba(232, 50, 147, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}
.text-green {
  --bs-text-opacity: 1;
  color: rgba(30, 204, 51, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}
.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(249, 232, 13, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}
.text-teal {
  --bs-text-opacity: 1;
  color: rgba(60, 210, 165, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #798e98 !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-lime {
  --bs-text-opacity: 1;
  color: rgba(129, 243, 45, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-lime-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(168, 182, 188, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(144, 162, 170, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-300-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(121, 142, 152, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-500 {
  --bs-text-opacity: 1;
  color: rgba(97, 122, 134, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-500-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(82, 104, 114, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-600-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-700 {
  --bs-text-opacity: 1;
  color: rgba(68, 85, 94, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-700-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(53, 67, 74, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-900 {
  --bs-text-opacity: 1;
  color: rgba(39, 49, 54, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-gray-900-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.bg-default {
  --bs-bg-opacity: 1;
  background-color: rgba(
    168, 182, 188,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-default-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-theme {
  --bs-bg-opacity: 1;
  background-color: rgba(232, 50, 147, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    78, 156, 255,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    121, 142, 152,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    255, 159, 12,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 96, 96, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    232, 50, 147,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 190, 255, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(53, 67, 74, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(222, 140, 255, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}
.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(187, 164, 255, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}
.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 96, 96, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}
.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(232, 50, 147, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}
.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(30, 204, 51, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}
.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(249, 232, 13, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}
.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(60, 210, 165, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}
.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 122, 134, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(192, 202, 207, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-lime {
  --bs-bg-opacity: 1;
  background-color: rgba(129, 243, 45, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-lime-rgb), var(--bs-bg-opacity)) !important;
}
.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    192, 202, 207,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-100-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    168, 182, 188,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-200-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    144, 162, 170,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-300-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    121, 142, 152,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-400-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    97, 122, 134,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-500-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    82, 104, 114,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-600-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    68, 85, 94,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-700-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    53, 67, 74,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-800-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(
    39, 49, 54,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-gray-900-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    39, 49, 54,
    var(--bs-bg-opacity)
  ) !important;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-gradient {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  ) !important;
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
      user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: 4px !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 2px !important;
}
.rounded-2 {
  border-radius: 4px !important;
}
.rounded-3 {
  border-radius: 8px !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.rounded-end {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.rounded-start {
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .gap-sm-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .gap-sm-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .gap-md-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .gap-md-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .gap-lg-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .gap-lg-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .gap-xl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .gap-xl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1660px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .gap-xxl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1900px) {
  .float-xxxl-start {
    float: left !important;
  }
  .float-xxxl-end {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-grid {
    display: grid !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxxl-none {
    display: none !important;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxxl-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }
  .gap-xxxl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }
  .gap-xxxl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
  .gap-xxxl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .gap-xxxl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }
  .gap-xxxl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
  .order-xxxl-first {
    order: -1 !important;
  }
  .order-xxxl-0 {
    order: 0 !important;
  }
  .order-xxxl-1 {
    order: 1 !important;
  }
  .order-xxxl-2 {
    order: 2 !important;
  }
  .order-xxxl-3 {
    order: 3 !important;
  }
  .order-xxxl-4 {
    order: 4 !important;
  }
  .order-xxxl-5 {
    order: 5 !important;
  }
  .order-xxxl-last {
    order: 6 !important;
  }
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxxl-auto {
    margin-top: auto !important;
  }
  .me-xxxl-0 {
    margin-right: 0 !important;
  }
  .me-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxxl-auto {
    margin-left: auto !important;
  }
  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxxl-n3 {
    margin: -1rem !important;
  }
  .m-xxxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxxl-start {
    text-align: left !important;
  }
  .text-xxxl-end {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.53125rem !important;
  }
  .fs-4 {
    font-size: 1.3125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.m-1px {
  margin: 1px !important;
}
.m-2px {
  margin: 2px !important;
}
.m-3px {
  margin: 3px !important;
}
.m-4px {
  margin: 4px !important;
}
.m-5px {
  margin: 5px !important;
}
.m-6px {
  margin: 6px !important;
}
.m-7px {
  margin: 7px !important;
}
.m-8px {
  margin: 8px !important;
}
.m-9px {
  margin: 9px !important;
}
.m-10px {
  margin: 10px !important;
}
.m-5px {
  margin: 5px !important;
}
.m-10px {
  margin: 10px !important;
}
.m-15px {
  margin: 15px !important;
}
.m-20px {
  margin: 20px !important;
}
.m-25px {
  margin: 25px !important;
}
.m-30px {
  margin: 30px !important;
}
.m-35px {
  margin: 35px !important;
}
.m-40px {
  margin: 40px !important;
}
.m-45px {
  margin: 45px !important;
}
.m-50px {
  margin: 50px !important;
}
.mt-1px {
  margin-top: 1px !important;
}
.mt-2px {
  margin-top: 2px !important;
}
.mt-3px {
  margin-top: 3px !important;
}
.mt-4px {
  margin-top: 4px !important;
}
.mt-5px {
  margin-top: 5px !important;
}
.mt-6px {
  margin-top: 6px !important;
}
.mt-7px {
  margin-top: 7px !important;
}
.mt-8px {
  margin-top: 8px !important;
}
.mt-9px {
  margin-top: 9px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-5px {
  margin-top: 5px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-15px {
  margin-top: 15px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-25px {
  margin-top: 25px !important;
}
.mt-30px {
  margin-top: 30px !important;
}
.mt-35px {
  margin-top: 35px !important;
}
.mt-40px {
  margin-top: 40px !important;
}
.mt-45px {
  margin-top: 45px !important;
}
.mt-50px {
  margin-top: 50px !important;
}
.me-1px {
  margin-right: 1px !important;
}
.me-2px {
  margin-right: 2px !important;
}
.me-3px {
  margin-right: 3px !important;
}
.me-4px {
  margin-right: 4px !important;
}
.me-5px {
  margin-right: 5px !important;
}
.me-6px {
  margin-right: 6px !important;
}
.me-7px {
  margin-right: 7px !important;
}
.me-8px {
  margin-right: 8px !important;
}
.me-9px {
  margin-right: 9px !important;
}
.me-10px {
  margin-right: 10px !important;
}
.me-5px {
  margin-right: 5px !important;
}
.me-10px {
  margin-right: 10px !important;
}
.me-15px {
  margin-right: 15px !important;
}
.me-20px {
  margin-right: 20px !important;
}
.me-25px {
  margin-right: 25px !important;
}
.me-30px {
  margin-right: 30px !important;
}
.me-35px {
  margin-right: 35px !important;
}
.me-40px {
  margin-right: 40px !important;
}
.me-45px {
  margin-right: 45px !important;
}
.me-50px {
  margin-right: 50px !important;
}
.mb-1px {
  margin-bottom: 1px !important;
}
.mb-2px {
  margin-bottom: 2px !important;
}
.mb-3px {
  margin-bottom: 3px !important;
}
.mb-4px {
  margin-bottom: 4px !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mb-6px {
  margin-bottom: 6px !important;
}
.mb-7px {
  margin-bottom: 7px !important;
}
.mb-8px {
  margin-bottom: 8px !important;
}
.mb-9px {
  margin-bottom: 9px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mb-15px {
  margin-bottom: 15px !important;
}
.mb-20px {
  margin-bottom: 20px !important;
}
.mb-25px {
  margin-bottom: 25px !important;
}
.mb-30px {
  margin-bottom: 30px !important;
}
.mb-35px {
  margin-bottom: 35px !important;
}
.mb-40px {
  margin-bottom: 40px !important;
}
.mb-45px {
  margin-bottom: 45px !important;
}
.mb-50px {
  margin-bottom: 50px !important;
}
.ms-1px {
  margin-left: 1px !important;
}
.ms-2px {
  margin-left: 2px !important;
}
.ms-3px {
  margin-left: 3px !important;
}
.ms-4px {
  margin-left: 4px !important;
}
.ms-5px {
  margin-left: 5px !important;
}
.ms-6px {
  margin-left: 6px !important;
}
.ms-7px {
  margin-left: 7px !important;
}
.ms-8px {
  margin-left: 8px !important;
}
.ms-9px {
  margin-left: 9px !important;
}
.ms-10px {
  margin-left: 10px !important;
}
.ms-5px {
  margin-left: 5px !important;
}
.ms-10px {
  margin-left: 10px !important;
}
.ms-15px {
  margin-left: 15px !important;
}
.ms-20px {
  margin-left: 20px !important;
}
.ms-25px {
  margin-left: 25px !important;
}
.ms-30px {
  margin-left: 30px !important;
}
.ms-35px {
  margin-left: 35px !important;
}
.ms-40px {
  margin-left: 40px !important;
}
.ms-45px {
  margin-left: 45px !important;
}
.ms-50px {
  margin-left: 50px !important;
}
.mx-1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}
.mx-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.mx-3px {
  margin-left: 3px !important;
  margin-right: 3px !important;
}
.mx-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mx-5px {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mx-6px {
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.mx-7px {
  margin-left: 7px !important;
  margin-right: 7px !important;
}
.mx-8px {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx-9px {
  margin-left: 9px !important;
  margin-right: 9px !important;
}
.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-5px {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-15px {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mx-20px {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-25px {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.mx-30px {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.mx-35px {
  margin-left: 35px !important;
  margin-right: 35px !important;
}
.mx-40px {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.mx-45px {
  margin-left: 45px !important;
  margin-right: 45px !important;
}
.mx-50px {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.my-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.my-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.my-5px {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-6px {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.my-7px {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}
.my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.my-9px {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}
.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-5px {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.my-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.my-25px {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.my-30px {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.my-35px {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.my-40px {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.my-45px {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.my-50px {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.p-1px {
  padding: 1px !important;
}
.p-2px {
  padding: 2px !important;
}
.p-3px {
  padding: 3px !important;
}
.p-4px {
  padding: 4px !important;
}
.p-5px {
  padding: 5px !important;
}
.p-6px {
  padding: 6px !important;
}
.p-7px {
  padding: 7px !important;
}
.p-8px {
  padding: 8px !important;
}
.p-9px {
  padding: 9px !important;
}
.p-10px {
  padding: 10px !important;
}
.p-5px {
  padding: 5px !important;
}
.p-10px {
  padding: 10px !important;
}
.p-15px {
  padding: 15px !important;
}
.p-20px {
  padding: 20px !important;
}
.p-25px {
  padding: 25px !important;
}
.p-30px {
  padding: 30px !important;
}
.p-35px {
  padding: 35px !important;
}
.p-40px {
  padding: 40px !important;
}
.p-45px {
  padding: 45px !important;
}
.p-50px {
  padding: 50px !important;
}
.pt-1px {
  padding-top: 1px !important;
}
.pt-2px {
  padding-top: 2px !important;
}
.pt-3px {
  padding-top: 3px !important;
}
.pt-4px {
  padding-top: 4px !important;
}
.pt-5px {
  padding-top: 5px !important;
}
.pt-6px {
  padding-top: 6px !important;
}
.pt-7px {
  padding-top: 7px !important;
}
.pt-8px {
  padding-top: 8px !important;
}
.pt-9px {
  padding-top: 9px !important;
}
.pt-10px {
  padding-top: 10px !important;
}
.pt-5px {
  padding-top: 5px !important;
}
.pt-10px {
  padding-top: 10px !important;
}
.pt-15px {
  padding-top: 15px !important;
}
.pt-20px {
  padding-top: 20px !important;
}
.pt-25px {
  padding-top: 25px !important;
}
.pt-30px {
  padding-top: 30px !important;
}
.pt-35px {
  padding-top: 35px !important;
}
.pt-40px {
  padding-top: 40px !important;
}
.pt-45px {
  padding-top: 45px !important;
}
.pt-50px {
  padding-top: 50px !important;
}
.pe-1px {
  padding-right: 1px !important;
}
.pe-2px {
  padding-right: 2px !important;
}
.pe-3px {
  padding-right: 3px !important;
}
.pe-4px {
  padding-right: 4px !important;
}
.pe-5px {
  padding-right: 5px !important;
}
.pe-6px {
  padding-right: 6px !important;
}
.pe-7px {
  padding-right: 7px !important;
}
.pe-8px {
  padding-right: 8px !important;
}
.pe-9px {
  padding-right: 9px !important;
}
.pe-10px {
  padding-right: 10px !important;
}
.pe-5px {
  padding-right: 5px !important;
}
.pe-10px {
  padding-right: 10px !important;
}
.pe-15px {
  padding-right: 15px !important;
}
.pe-20px {
  padding-right: 20px !important;
}
.pe-25px {
  padding-right: 25px !important;
}
.pe-30px {
  padding-right: 30px !important;
}
.pe-35px {
  padding-right: 35px !important;
}
.pe-40px {
  padding-right: 40px !important;
}
.pe-45px {
  padding-right: 45px !important;
}
.pe-50px {
  padding-right: 50px !important;
}
.pb-1px {
  padding-bottom: 1px !important;
}
.pb-2px {
  padding-bottom: 2px !important;
}
.pb-3px {
  padding-bottom: 3px !important;
}
.pb-4px {
  padding-bottom: 4px !important;
}
.pb-5px {
  padding-bottom: 5px !important;
}
.pb-6px {
  padding-bottom: 6px !important;
}
.pb-7px {
  padding-bottom: 7px !important;
}
.pb-8px {
  padding-bottom: 8px !important;
}
.pb-9px {
  padding-bottom: 9px !important;
}
.pb-10px {
  padding-bottom: 10px !important;
}
.pb-5px {
  padding-bottom: 5px !important;
}
.pb-10px {
  padding-bottom: 10px !important;
}
.pb-15px {
  padding-bottom: 15px !important;
}
.pb-20px {
  padding-bottom: 20px !important;
}
.pb-25px {
  padding-bottom: 25px !important;
}
.pb-30px {
  padding-bottom: 30px !important;
}
.pb-35px {
  padding-bottom: 35px !important;
}
.pb-40px {
  padding-bottom: 40px !important;
}
.pb-45px {
  padding-bottom: 45px !important;
}
.pb-50px {
  padding-bottom: 50px !important;
}
.ps-1px {
  padding-left: 1px !important;
}
.ps-2px {
  padding-left: 2px !important;
}
.ps-3px {
  padding-left: 3px !important;
}
.ps-4px {
  padding-left: 4px !important;
}
.ps-5px {
  padding-left: 5px !important;
}
.ps-6px {
  padding-left: 6px !important;
}
.ps-7px {
  padding-left: 7px !important;
}
.ps-8px {
  padding-left: 8px !important;
}
.ps-9px {
  padding-left: 9px !important;
}
.ps-10px {
  padding-left: 10px !important;
}
.ps-5px {
  padding-left: 5px !important;
}
.ps-10px {
  padding-left: 10px !important;
}
.ps-15px {
  padding-left: 15px !important;
}
.ps-20px {
  padding-left: 20px !important;
}
.ps-25px {
  padding-left: 25px !important;
}
.ps-30px {
  padding-left: 30px !important;
}
.ps-35px {
  padding-left: 35px !important;
}
.ps-40px {
  padding-left: 40px !important;
}
.ps-45px {
  padding-left: 45px !important;
}
.ps-50px {
  padding-left: 50px !important;
}
.px-1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.px-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.px-3px {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.px-4px {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.px-7px {
  padding-left: 7px !important;
  padding-right: 7px !important;
}
.px-8px {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.px-9px {
  padding-left: 9px !important;
  padding-right: 9px !important;
}
.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-15px {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-25px {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.px-30px {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-35px {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.px-40px {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.px-45px {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.px-50px {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.py-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.py-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.py-7px {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-9px {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.py-30px {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.py-35px {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.py-40px {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-45px {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.py-50px {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.fs-0px {
  font-size: 0 !important;
}
.fs-1px {
  font-size: 1px !important;
}
.fs-2px {
  font-size: 2px !important;
}
.fs-3px {
  font-size: 3px !important;
}
.fs-4px {
  font-size: 4px !important;
}
.fs-5px {
  font-size: 5px !important;
}
.fs-6px {
  font-size: 6px !important;
}
.fs-7px {
  font-size: 7px !important;
}
.fs-8px {
  font-size: 8px !important;
}
.fs-9px {
  font-size: 9px !important;
}
.fs-10px {
  font-size: 10px !important;
}
.fs-11px {
  font-size: 11px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-15px {
  font-size: 15px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-17px {
  font-size: 17px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-19px {
  font-size: 19px !important;
}
.fs-20px {
  font-size: 20px !important;
}
.fs-21px {
  font-size: 21px !important;
}
.fs-22px {
  font-size: 22px !important;
}
.fs-23px {
  font-size: 23px !important;
}
.fs-24px {
  font-size: 24px !important;
}
.fs-25px {
  font-size: 25px !important;
}
.fs-26px {
  font-size: 26px !important;
}
.fs-27px {
  font-size: 27px !important;
}
.fs-28px {
  font-size: 28px !important;
}
.fs-29px {
  font-size: 29px !important;
}
.fs-30px {
  font-size: 30px !important;
}
.fs-31px {
  font-size: 31px !important;
}
.fs-32px {
  font-size: 32px !important;
}
.fs-33px {
  font-size: 33px !important;
}
.fs-34px {
  font-size: 34px !important;
}
.fs-35px {
  font-size: 35px !important;
}
.fs-36px {
  font-size: 36px !important;
}
.fs-37px {
  font-size: 37px !important;
}
.fs-38px {
  font-size: 38px !important;
}
.fs-39px {
  font-size: 39px !important;
}
.fs-40px {
  font-size: 40px !important;
}
.fs-41px {
  font-size: 41px !important;
}
.fs-42px {
  font-size: 42px !important;
}
.fs-43px {
  font-size: 43px !important;
}
.fs-44px {
  font-size: 44px !important;
}
.fs-45px {
  font-size: 45px !important;
}
.fs-46px {
  font-size: 46px !important;
}
.fs-47px {
  font-size: 47px !important;
}
.fs-48px {
  font-size: 48px !important;
}
.fs-49px {
  font-size: 49px !important;
}
.fs-50px {
  font-size: 50px !important;
}
.fs-51px {
  font-size: 51px !important;
}
.fs-52px {
  font-size: 52px !important;
}
.fs-53px {
  font-size: 53px !important;
}
.fs-54px {
  font-size: 54px !important;
}
.fs-55px {
  font-size: 55px !important;
}
.fs-56px {
  font-size: 56px !important;
}
.fs-57px {
  font-size: 57px !important;
}
.fs-58px {
  font-size: 58px !important;
}
.fs-59px {
  font-size: 59px !important;
}
.fs-60px {
  font-size: 60px !important;
}
.fs-61px {
  font-size: 61px !important;
}
.fs-62px {
  font-size: 62px !important;
}
.fs-63px {
  font-size: 63px !important;
}
.fs-64px {
  font-size: 64px !important;
}
.fs-65px {
  font-size: 65px !important;
}
.fs-66px {
  font-size: 66px !important;
}
.fs-67px {
  font-size: 67px !important;
}
.fs-68px {
  font-size: 68px !important;
}
.fs-69px {
  font-size: 69px !important;
}
.fs-70px {
  font-size: 70px !important;
}
.fs-71px {
  font-size: 71px !important;
}
.fs-72px {
  font-size: 72px !important;
}
.fs-73px {
  font-size: 73px !important;
}
.fs-74px {
  font-size: 74px !important;
}
.fs-75px {
  font-size: 75px !important;
}
.fs-76px {
  font-size: 76px !important;
}
.fs-77px {
  font-size: 77px !important;
}
.fs-78px {
  font-size: 78px !important;
}
.fs-79px {
  font-size: 79px !important;
}
.fs-80px {
  font-size: 80px !important;
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.top-0 {
  top: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.start-0 {
  left: 0 !important;
}
.end-0 {
  right: 0 !important;
}
.top-auto {
  top: auto !important;
}
.bottom-auto {
  bottom: auto !important;
}
.start-auto {
  left: auto !important;
}
.end-auto {
  right: auto !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.line-height-1 {
  line-height: 1 !important;
}
.w-10px {
  width: 10px !important;
}
.w-1px {
  width: 1px !important;
}
.w-20px {
  width: 20px !important;
}
.w-2px {
  width: 2px !important;
}
.w-30px {
  width: 30px !important;
}
.w-3px {
  width: 3px !important;
}
.w-40px {
  width: 40px !important;
}
.w-4px {
  width: 4px !important;
}
.w-50px {
  width: 50px !important;
}
.w-5px {
  width: 5px !important;
}
.w-60px {
  width: 60px !important;
}
.w-6px {
  width: 6px !important;
}
.w-70px {
  width: 70px !important;
}
.w-7px {
  width: 7px !important;
}
.w-80px {
  width: 80px !important;
}
.w-8px {
  width: 8px !important;
}
.w-90px {
  width: 90px !important;
}
.w-9px {
  width: 9px !important;
}
.w-100px {
  width: 100px !important;
}
.w-150px {
  width: 150px !important;
}
.w-200px {
  width: 200px !important;
}
.w-250px {
  width: 250px !important;
}
.w-300px {
  width: 300px !important;
}
.w-350px {
  width: 350px !important;
}
.w-400px {
  width: 400px !important;
}
.w-450px {
  width: 450px !important;
}
.w-500px {
  width: 500px !important;
}
.w-550px {
  width: 550px !important;
}
.w-600px {
  width: 600px !important;
}
.h-10px {
  height: 10px !important;
}
.h-1px {
  height: 1px !important;
}
.h-20px {
  height: 20px !important;
}
.h-2px {
  height: 2px !important;
}
.h-30px {
  height: 30px !important;
}
.h-3px {
  height: 3px !important;
}
.h-40px {
  height: 40px !important;
}
.h-4px {
  height: 4px !important;
}
.h-50px {
  height: 50px !important;
}
.h-5px {
  height: 5px !important;
}
.h-60px {
  height: 60px !important;
}
.h-6px {
  height: 6px !important;
}
.h-70px {
  height: 70px !important;
}
.h-7px {
  height: 7px !important;
}
.h-80px {
  height: 80px !important;
}
.h-8px {
  height: 8px !important;
}
.h-90px {
  height: 90px !important;
}
.h-9px {
  height: 9px !important;
}
.h-100px {
  height: 100px !important;
}
.h-150px {
  height: 150px !important;
}
.h-200px {
  height: 200px !important;
}
.h-250px {
  height: 250px !important;
}
.h-300px {
  height: 300px !important;
}
.h-350px {
  height: 350px !important;
}
.h-400px {
  height: 400px !important;
}
.h-450px {
  height: 450px !important;
}
.h-500px {
  height: 500px !important;
}
.h-550px {
  height: 550px !important;
}
.h-600px {
  height: 600px !important;
}
.bg-gradient-default {
  background-color: #a8b6bc !important;
  background-image: linear-gradient(to bottom, #c5ced2, #9aaab1) !important;
}
:root .theme-default {
  --bs-theme: #a8b6bc !important;
  --bs-theme-rgb: 168, 182, 188 !important;
  --bs-border-color: #000 !important;
}
.text-default-100 {
  --bs-text-opacity: 1;
  color: rgba(238, 240, 242, var(--bs-text-opacity)) !important;
}
.bg-default-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(238, 240, 242, var(--bs-bg-opacity)) !important;
}
.text-default-600 {
  --bs-text-opacity: 1;
  color: rgba(134, 146, 150, var(--bs-text-opacity)) !important;
}
.bg-default-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 146, 150, var(--bs-bg-opacity)) !important;
}
.text-default-200 {
  --bs-text-opacity: 1;
  color: rgba(220, 226, 228, var(--bs-text-opacity)) !important;
}
.bg-default-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 226, 228, var(--bs-bg-opacity)) !important;
}
.text-default-700 {
  --bs-text-opacity: 1;
  color: rgba(101, 109, 113, var(--bs-text-opacity)) !important;
}
.bg-default-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(101, 109, 113, var(--bs-bg-opacity)) !important;
}
.text-default-300 {
  --bs-text-opacity: 1;
  color: rgba(203, 211, 215, var(--bs-text-opacity)) !important;
}
.bg-default-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(203, 211, 215, var(--bs-bg-opacity)) !important;
}
.text-default-800 {
  --bs-text-opacity: 1;
  color: rgba(67, 73, 75, var(--bs-text-opacity)) !important;
}
.bg-default-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(67, 73, 75, var(--bs-bg-opacity)) !important;
}
.text-default-400 {
  --bs-text-opacity: 1;
  color: rgba(185, 197, 201, var(--bs-text-opacity)) !important;
}
.bg-default-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(185, 197, 201, var(--bs-bg-opacity)) !important;
}
.text-default-900 {
  --bs-text-opacity: 1;
  color: rgba(34, 36, 38, var(--bs-text-opacity)) !important;
}
.bg-default-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(34, 36, 38, var(--bs-bg-opacity)) !important;
}
.bg-gradient-theme {
  background-color: #e83293 !important;
  background-image: linear-gradient(to bottom, #ed60ab, #e51b87) !important;
}
.border-theme {
  border-color: #000 !important;
  border-color: var(--bs-theme) !important;
}
:root .theme-theme {
  --bs-theme: #e83293 !important;
  --bs-theme-rgb: 232, 50, 147 !important;
  --bs-border-color: #000 !important;
}
.text-theme-100 {
  --bs-text-opacity: 1;
  color: rgba(250, 214, 233, var(--bs-text-opacity)) !important;
}
.bg-theme-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 214, 233, var(--bs-bg-opacity)) !important;
}
.text-theme-600 {
  --bs-text-opacity: 1;
  color: rgba(186, 40, 118, var(--bs-text-opacity)) !important;
}
.bg-theme-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 40, 118, var(--bs-bg-opacity)) !important;
}
.text-theme-200 {
  --bs-text-opacity: 1;
  color: rgba(246, 173, 212, var(--bs-text-opacity)) !important;
}
.bg-theme-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(246, 173, 212, var(--bs-bg-opacity)) !important;
}
.text-theme-700 {
  --bs-text-opacity: 1;
  color: rgba(139, 30, 88, var(--bs-text-opacity)) !important;
}
.bg-theme-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(139, 30, 88, var(--bs-bg-opacity)) !important;
}
.text-theme-300 {
  --bs-text-opacity: 1;
  color: rgba(241, 132, 190, var(--bs-text-opacity)) !important;
}
.bg-theme-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 132, 190, var(--bs-bg-opacity)) !important;
}
.text-theme-800 {
  --bs-text-opacity: 1;
  color: rgba(93, 20, 59, var(--bs-text-opacity)) !important;
}
.bg-theme-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 20, 59, var(--bs-bg-opacity)) !important;
}
.text-theme-400 {
  --bs-text-opacity: 1;
  color: rgba(237, 91, 169, var(--bs-text-opacity)) !important;
}
.bg-theme-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(237, 91, 169, var(--bs-bg-opacity)) !important;
}
.text-theme-900 {
  --bs-text-opacity: 1;
  color: rgba(46, 10, 29, var(--bs-text-opacity)) !important;
}
.bg-theme-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(46, 10, 29, var(--bs-bg-opacity)) !important;
}
.bg-gradient-primary {
  background-color: #4e9cff !important;
  background-image: linear-gradient(to bottom, #81b9ff, #358eff) !important;
}
:root .theme-primary {
  --bs-theme: #4e9cff !important;
  --bs-theme-rgb: 78, 156, 255 !important;
  --bs-border-color: #4e9cff !important;
}
.text-primary-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 235, 255, var(--bs-text-opacity)) !important;
}
.bg-primary-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 235, 255, var(--bs-bg-opacity)) !important;
}
.text-primary-600 {
  --bs-text-opacity: 1;
  color: rgba(62, 125, 204, var(--bs-text-opacity)) !important;
}
.bg-primary-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(62, 125, 204, var(--bs-bg-opacity)) !important;
}
.text-primary-200 {
  --bs-text-opacity: 1;
  color: rgba(184, 215, 255, var(--bs-text-opacity)) !important;
}
.bg-primary-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(184, 215, 255, var(--bs-bg-opacity)) !important;
}
.text-primary-700 {
  --bs-text-opacity: 1;
  color: rgba(47, 94, 153, var(--bs-text-opacity)) !important;
}
.bg-primary-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(47, 94, 153, var(--bs-bg-opacity)) !important;
}
.text-primary-300 {
  --bs-text-opacity: 1;
  color: rgba(149, 196, 255, var(--bs-text-opacity)) !important;
}
.bg-primary-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 196, 255, var(--bs-bg-opacity)) !important;
}
.text-primary-800 {
  --bs-text-opacity: 1;
  color: rgba(31, 62, 102, var(--bs-text-opacity)) !important;
}
.bg-primary-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(31, 62, 102, var(--bs-bg-opacity)) !important;
}
.text-primary-400 {
  --bs-text-opacity: 1;
  color: rgba(113, 176, 255, var(--bs-text-opacity)) !important;
}
.bg-primary-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(113, 176, 255, var(--bs-bg-opacity)) !important;
}
.text-primary-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 31, 51, var(--bs-text-opacity)) !important;
}
.bg-primary-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 31, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-secondary {
  background-color: #798e98 !important;
  background-image: linear-gradient(to bottom, #96a6ae, #6c818c) !important;
}
:root .theme-secondary {
  --bs-theme: #798e98 !important;
  --bs-theme-rgb: 121, 142, 152 !important;
  --bs-border-color: #798e98 !important;
}
.text-secondary-100 {
  --bs-text-opacity: 1;
  color: rgba(228, 232, 234, var(--bs-text-opacity)) !important;
}
.bg-secondary-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 232, 234, var(--bs-bg-opacity)) !important;
}
.text-secondary-600 {
  --bs-text-opacity: 1;
  color: rgba(97, 114, 122, var(--bs-text-opacity)) !important;
}
.bg-secondary-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 114, 122, var(--bs-bg-opacity)) !important;
}
.text-secondary-200 {
  --bs-text-opacity: 1;
  color: rgba(201, 210, 214, var(--bs-text-opacity)) !important;
}
.bg-secondary-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 210, 214, var(--bs-bg-opacity)) !important;
}
.text-secondary-700 {
  --bs-text-opacity: 1;
  color: rgba(73, 85, 91, var(--bs-text-opacity)) !important;
}
.bg-secondary-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(73, 85, 91, var(--bs-bg-opacity)) !important;
}
.text-secondary-300 {
  --bs-text-opacity: 1;
  color: rgba(175, 187, 193, var(--bs-text-opacity)) !important;
}
.bg-secondary-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(175, 187, 193, var(--bs-bg-opacity)) !important;
}
.text-secondary-800 {
  --bs-text-opacity: 1;
  color: rgba(48, 57, 61, var(--bs-text-opacity)) !important;
}
.bg-secondary-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 57, 61, var(--bs-bg-opacity)) !important;
}
.text-secondary-400 {
  --bs-text-opacity: 1;
  color: rgba(148, 165, 173, var(--bs-text-opacity)) !important;
}
.bg-secondary-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 165, 173, var(--bs-bg-opacity)) !important;
}
.text-secondary-900 {
  --bs-text-opacity: 1;
  color: rgba(24, 28, 30, var(--bs-text-opacity)) !important;
}
.bg-secondary-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 28, 30, var(--bs-bg-opacity)) !important;
}
.bg-gradient-warning {
  background-color: #ff9f0c !important;
  background-image: linear-gradient(to bottom, #ffb33f, #f29200) !important;
}
:root .theme-warning {
  --bs-theme: #ff9f0c !important;
  --bs-theme-rgb: 255, 159, 12 !important;
  --bs-border-color: #ff9f0c !important;
}
.text-warning-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 236, 206, var(--bs-text-opacity)) !important;
}
.bg-warning-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 236, 206, var(--bs-bg-opacity)) !important;
}
.text-warning-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 127, 10, var(--bs-text-opacity)) !important;
}
.bg-warning-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 127, 10, var(--bs-bg-opacity)) !important;
}
.text-warning-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 217, 158, var(--bs-text-opacity)) !important;
}
.bg-warning-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 217, 158, var(--bs-bg-opacity)) !important;
}
.text-warning-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 95, 7, var(--bs-text-opacity)) !important;
}
.bg-warning-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 95, 7, var(--bs-bg-opacity)) !important;
}
.text-warning-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 197, 109, var(--bs-text-opacity)) !important;
}
.bg-warning-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 197, 109, var(--bs-bg-opacity)) !important;
}
.text-warning-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 64, 5, var(--bs-text-opacity)) !important;
}
.bg-warning-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 64, 5, var(--bs-bg-opacity)) !important;
}
.text-warning-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 178, 61, var(--bs-text-opacity)) !important;
}
.bg-warning-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 178, 61, var(--bs-bg-opacity)) !important;
}
.text-warning-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 32, 2, var(--bs-text-opacity)) !important;
}
.bg-warning-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 32, 2, var(--bs-bg-opacity)) !important;
}
.bg-gradient-danger {
  background-color: #ff6060 !important;
  background-image: linear-gradient(to bottom, #ff9393, #ff4747) !important;
}
:root .theme-danger {
  --bs-theme: #ff6060 !important;
  --bs-theme-rgb: 255, 96, 96 !important;
  --bs-border-color: #ff6060 !important;
}
.text-danger-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 223, 223, var(--bs-text-opacity)) !important;
}
.bg-danger-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 223, 223, var(--bs-bg-opacity)) !important;
}
.text-danger-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 77, 77, var(--bs-text-opacity)) !important;
}
.bg-danger-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 77, 77, var(--bs-bg-opacity)) !important;
}
.text-danger-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 191, 191, var(--bs-text-opacity)) !important;
}
.bg-danger-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 191, 191, var(--bs-bg-opacity)) !important;
}
.text-danger-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 58, 58, var(--bs-text-opacity)) !important;
}
.bg-danger-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 58, 58, var(--bs-bg-opacity)) !important;
}
.text-danger-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 160, 160, var(--bs-text-opacity)) !important;
}
.bg-danger-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 160, 160, var(--bs-bg-opacity)) !important;
}
.text-danger-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 38, 38, var(--bs-text-opacity)) !important;
}
.bg-danger-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 38, 38, var(--bs-bg-opacity)) !important;
}
.text-danger-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 128, 128, var(--bs-text-opacity)) !important;
}
.bg-danger-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 128, 128, var(--bs-bg-opacity)) !important;
}
.text-danger-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 19, 19, var(--bs-text-opacity)) !important;
}
.bg-danger-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 19, 19, var(--bs-bg-opacity)) !important;
}
.bg-gradient-success {
  background-color: #e83293 !important;
  background-image: linear-gradient(to bottom, #ed60ab, #e51b87) !important;
}
:root .theme-success {
  --bs-theme: #e83293 !important;
  --bs-theme-rgb: 232, 50, 147 !important;
  --bs-border-color: #e83293 !important;
}
.text-success-100 {
  --bs-text-opacity: 1;
  color: rgba(250, 214, 233, var(--bs-text-opacity)) !important;
}
.bg-success-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 214, 233, var(--bs-bg-opacity)) !important;
}
.text-success-600 {
  --bs-text-opacity: 1;
  color: rgba(186, 40, 118, var(--bs-text-opacity)) !important;
}
.bg-success-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 40, 118, var(--bs-bg-opacity)) !important;
}
.text-success-200 {
  --bs-text-opacity: 1;
  color: rgba(246, 173, 212, var(--bs-text-opacity)) !important;
}
.bg-success-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(246, 173, 212, var(--bs-bg-opacity)) !important;
}
.text-success-700 {
  --bs-text-opacity: 1;
  color: rgba(139, 30, 88, var(--bs-text-opacity)) !important;
}
.bg-success-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(139, 30, 88, var(--bs-bg-opacity)) !important;
}
.text-success-300 {
  --bs-text-opacity: 1;
  color: rgba(241, 132, 190, var(--bs-text-opacity)) !important;
}
.bg-success-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 132, 190, var(--bs-bg-opacity)) !important;
}
.text-success-800 {
  --bs-text-opacity: 1;
  color: rgba(93, 20, 59, var(--bs-text-opacity)) !important;
}
.bg-success-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 20, 59, var(--bs-bg-opacity)) !important;
}
.text-success-400 {
  --bs-text-opacity: 1;
  color: rgba(237, 91, 169, var(--bs-text-opacity)) !important;
}
.bg-success-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(237, 91, 169, var(--bs-bg-opacity)) !important;
}
.text-success-900 {
  --bs-text-opacity: 1;
  color: rgba(46, 10, 29, var(--bs-text-opacity)) !important;
}
.bg-success-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(46, 10, 29, var(--bs-bg-opacity)) !important;
}
.bg-gradient-info {
  background-color: #30beff !important;
  background-image: linear-gradient(to bottom, #63ceff, #17b6ff) !important;
}
:root .theme-info {
  --bs-theme: #30beff !important;
  --bs-theme-rgb: 48, 190, 255 !important;
  --bs-border-color: #30beff !important;
}
.text-info-100 {
  --bs-text-opacity: 1;
  color: rgba(214, 242, 255, var(--bs-text-opacity)) !important;
}
.bg-info-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(214, 242, 255, var(--bs-bg-opacity)) !important;
}
.text-info-600 {
  --bs-text-opacity: 1;
  color: rgba(38, 152, 204, var(--bs-text-opacity)) !important;
}
.bg-info-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 152, 204, var(--bs-bg-opacity)) !important;
}
.text-info-200 {
  --bs-text-opacity: 1;
  color: rgba(172, 229, 255, var(--bs-text-opacity)) !important;
}
.bg-info-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(172, 229, 255, var(--bs-bg-opacity)) !important;
}
.text-info-700 {
  --bs-text-opacity: 1;
  color: rgba(29, 114, 153, var(--bs-text-opacity)) !important;
}
.bg-info-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 114, 153, var(--bs-bg-opacity)) !important;
}
.text-info-300 {
  --bs-text-opacity: 1;
  color: rgba(131, 216, 255, var(--bs-text-opacity)) !important;
}
.bg-info-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(131, 216, 255, var(--bs-bg-opacity)) !important;
}
.text-info-800 {
  --bs-text-opacity: 1;
  color: rgba(19, 76, 102, var(--bs-text-opacity)) !important;
}
.bg-info-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 76, 102, var(--bs-bg-opacity)) !important;
}
.text-info-400 {
  --bs-text-opacity: 1;
  color: rgba(89, 203, 255, var(--bs-text-opacity)) !important;
}
.bg-info-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(89, 203, 255, var(--bs-bg-opacity)) !important;
}
.text-info-900 {
  --bs-text-opacity: 1;
  color: rgba(10, 38, 51, var(--bs-text-opacity)) !important;
}
.bg-info-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(10, 38, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-dark {
  background-color: #35434a !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
}
:root .theme-dark {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-border-color: #35434a !important;
}
.text-dark-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}
.bg-dark-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}
.text-dark-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}
.bg-dark-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}
.text-dark-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}
.bg-dark-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}
.text-dark-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}
.bg-dark-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}
.text-dark-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}
.bg-dark-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}
.text-dark-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}
.bg-dark-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}
.text-dark-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}
.bg-dark-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}
.text-dark-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}
.bg-dark-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}
.bg-gradient-black {
  background-color: #000 !important;
  background-image: linear-gradient(to bottom, #1a1a1a, #000) !important;
}
:root .theme-black {
  --bs-theme: #000000 !important;
  --bs-theme-rgb: 0, 0, 0 !important;
  --bs-border-color: #000000 !important;
}
.text-black-100 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}
.bg-black-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}
.text-black-600 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
.bg-black-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
.text-black-200 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}
.bg-black-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}
.text-black-700 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
.bg-black-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
.text-black-300 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}
.bg-black-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}
.text-black-800 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
.bg-black-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
.text-black-400 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}
.bg-black-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}
.text-black-900 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, var(--bs-text-opacity)) !important;
}
.bg-black-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--bs-bg-opacity)) !important;
}
.bg-gradient-white {
  background-color: #fff !important;
  background-image: linear-gradient(to bottom, #fff, #f2f2f2) !important;
}
:root .theme-white {
  --bs-theme: #ffffff !important;
  --bs-theme-rgb: 255, 255, 255 !important;
  --bs-border-color: #ffffff !important;
}
.text-white-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}
.bg-white-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}
.text-white-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}
.bg-white-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}
.text-white-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}
.bg-white-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}
.text-white-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}
.bg-white-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-purple {
  background-color: #de8cff !important;
  background-image: linear-gradient(to bottom, #edbfff, #d773ff) !important;
}
:root .theme-purple {
  --bs-theme: #de8cff !important;
  --bs-theme-rgb: 222, 140, 255 !important;
  --bs-border-color: #de8cff !important;
}
.text-purple-100 {
  --bs-text-opacity: 1;
  color: rgba(248, 232, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(248, 232, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-600 {
  --bs-text-opacity: 1;
  color: rgba(178, 112, 204, var(--bs-text-opacity)) !important;
}
.bg-purple-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(178, 112, 204, var(--bs-bg-opacity)) !important;
}
.text-purple-200 {
  --bs-text-opacity: 1;
  color: rgba(242, 209, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 209, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-700 {
  --bs-text-opacity: 1;
  color: rgba(133, 84, 153, var(--bs-text-opacity)) !important;
}
.bg-purple-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(133, 84, 153, var(--bs-bg-opacity)) !important;
}
.text-purple-300 {
  --bs-text-opacity: 1;
  color: rgba(235, 186, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(235, 186, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-800 {
  --bs-text-opacity: 1;
  color: rgba(89, 56, 102, var(--bs-text-opacity)) !important;
}
.bg-purple-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(89, 56, 102, var(--bs-bg-opacity)) !important;
}
.text-purple-400 {
  --bs-text-opacity: 1;
  color: rgba(229, 163, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(229, 163, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-900 {
  --bs-text-opacity: 1;
  color: rgba(44, 28, 51, var(--bs-text-opacity)) !important;
}
.bg-purple-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(44, 28, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-indigo {
  background-color: #bba4ff !important;
  background-image: linear-gradient(to bottom, #e1d7ff, #a88bff) !important;
}
:root .theme-indigo {
  --bs-theme: #bba4ff !important;
  --bs-theme-rgb: 187, 164, 255 !important;
  --bs-border-color: #bba4ff !important;
}
.text-indigo-100 {
  --bs-text-opacity: 1;
  color: rgba(241, 237, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 237, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-600 {
  --bs-text-opacity: 1;
  color: rgba(150, 131, 204, var(--bs-text-opacity)) !important;
}
.bg-indigo-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(150, 131, 204, var(--bs-bg-opacity)) !important;
}
.text-indigo-200 {
  --bs-text-opacity: 1;
  color: rgba(228, 219, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 219, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-700 {
  --bs-text-opacity: 1;
  color: rgba(112, 98, 153, var(--bs-text-opacity)) !important;
}
.bg-indigo-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(112, 98, 153, var(--bs-bg-opacity)) !important;
}
.text-indigo-300 {
  --bs-text-opacity: 1;
  color: rgba(214, 200, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(214, 200, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-800 {
  --bs-text-opacity: 1;
  color: rgba(75, 66, 102, var(--bs-text-opacity)) !important;
}
.bg-indigo-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(75, 66, 102, var(--bs-bg-opacity)) !important;
}
.text-indigo-400 {
  --bs-text-opacity: 1;
  color: rgba(201, 182, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 182, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-900 {
  --bs-text-opacity: 1;
  color: rgba(37, 33, 51, var(--bs-text-opacity)) !important;
}
.bg-indigo-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(37, 33, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-red {
  background-color: #ff6060 !important;
  background-image: linear-gradient(to bottom, #ff9393, #ff4747) !important;
}
:root .theme-red {
  --bs-theme: #ff6060 !important;
  --bs-theme-rgb: 255, 96, 96 !important;
  --bs-border-color: #ff6060 !important;
}
.text-red-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 223, 223, var(--bs-text-opacity)) !important;
}
.bg-red-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 223, 223, var(--bs-bg-opacity)) !important;
}
.text-red-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 77, 77, var(--bs-text-opacity)) !important;
}
.bg-red-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 77, 77, var(--bs-bg-opacity)) !important;
}
.text-red-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 191, 191, var(--bs-text-opacity)) !important;
}
.bg-red-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 191, 191, var(--bs-bg-opacity)) !important;
}
.text-red-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 58, 58, var(--bs-text-opacity)) !important;
}
.bg-red-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 58, 58, var(--bs-bg-opacity)) !important;
}
.text-red-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 160, 160, var(--bs-text-opacity)) !important;
}
.bg-red-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 160, 160, var(--bs-bg-opacity)) !important;
}
.text-red-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 38, 38, var(--bs-text-opacity)) !important;
}
.bg-red-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 38, 38, var(--bs-bg-opacity)) !important;
}
.text-red-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 128, 128, var(--bs-text-opacity)) !important;
}
.bg-red-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 128, 128, var(--bs-bg-opacity)) !important;
}
.text-red-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 19, 19, var(--bs-text-opacity)) !important;
}
.bg-red-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 19, 19, var(--bs-bg-opacity)) !important;
}
.bg-gradient-pink {
  background-color: #e83293 !important;
  background-image: linear-gradient(to bottom, #ed60ab, #e51b87) !important;
}
:root .theme-pink {
  --bs-theme: #e83293 !important;
  --bs-theme-rgb: 232, 50, 147 !important;
  --bs-border-color: #e83293 !important;
}
.text-pink-100 {
  --bs-text-opacity: 1;
  color: rgba(250, 214, 233, var(--bs-text-opacity)) !important;
}
.bg-pink-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 214, 233, var(--bs-bg-opacity)) !important;
}
.text-pink-600 {
  --bs-text-opacity: 1;
  color: rgba(186, 40, 118, var(--bs-text-opacity)) !important;
}
.bg-pink-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 40, 118, var(--bs-bg-opacity)) !important;
}
.text-pink-200 {
  --bs-text-opacity: 1;
  color: rgba(246, 173, 212, var(--bs-text-opacity)) !important;
}
.bg-pink-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(246, 173, 212, var(--bs-bg-opacity)) !important;
}
.text-pink-700 {
  --bs-text-opacity: 1;
  color: rgba(139, 30, 88, var(--bs-text-opacity)) !important;
}
.bg-pink-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(139, 30, 88, var(--bs-bg-opacity)) !important;
}
.text-pink-300 {
  --bs-text-opacity: 1;
  color: rgba(241, 132, 190, var(--bs-text-opacity)) !important;
}
.bg-pink-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 132, 190, var(--bs-bg-opacity)) !important;
}
.text-pink-800 {
  --bs-text-opacity: 1;
  color: rgba(93, 20, 59, var(--bs-text-opacity)) !important;
}
.bg-pink-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 20, 59, var(--bs-bg-opacity)) !important;
}
.text-pink-400 {
  --bs-text-opacity: 1;
  color: rgba(237, 91, 169, var(--bs-text-opacity)) !important;
}
.bg-pink-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(237, 91, 169, var(--bs-bg-opacity)) !important;
}
.text-pink-900 {
  --bs-text-opacity: 1;
  color: rgba(46, 10, 29, var(--bs-text-opacity)) !important;
}
.bg-pink-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(46, 10, 29, var(--bs-bg-opacity)) !important;
}
.bg-gradient-green {
  background-color: #1ecc33 !important;
  background-image: linear-gradient(to bottom, #3be24f, #1bb62d) !important;
}
:root .theme-green {
  --bs-theme: #1ecc33 !important;
  --bs-theme-rgb: 30, 204, 51 !important;
  --bs-border-color: #1ecc33 !important;
}
.text-green-100 {
  --bs-text-opacity: 1;
  color: rgba(210, 245, 214, var(--bs-text-opacity)) !important;
}
.bg-green-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(210, 245, 214, var(--bs-bg-opacity)) !important;
}
.text-green-600 {
  --bs-text-opacity: 1;
  color: rgba(24, 163, 41, var(--bs-text-opacity)) !important;
}
.bg-green-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 163, 41, var(--bs-bg-opacity)) !important;
}
.text-green-200 {
  --bs-text-opacity: 1;
  color: rgba(165, 235, 173, var(--bs-text-opacity)) !important;
}
.bg-green-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(165, 235, 173, var(--bs-bg-opacity)) !important;
}
.text-green-700 {
  --bs-text-opacity: 1;
  color: rgba(18, 122, 31, var(--bs-text-opacity)) !important;
}
.bg-green-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(18, 122, 31, var(--bs-bg-opacity)) !important;
}
.text-green-300 {
  --bs-text-opacity: 1;
  color: rgba(120, 224, 133, var(--bs-text-opacity)) !important;
}
.bg-green-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(120, 224, 133, var(--bs-bg-opacity)) !important;
}
.text-green-800 {
  --bs-text-opacity: 1;
  color: rgba(12, 82, 20, var(--bs-text-opacity)) !important;
}
.bg-green-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 82, 20, var(--bs-bg-opacity)) !important;
}
.text-green-400 {
  --bs-text-opacity: 1;
  color: rgba(75, 214, 92, var(--bs-text-opacity)) !important;
}
.bg-green-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(75, 214, 92, var(--bs-bg-opacity)) !important;
}
.text-green-900 {
  --bs-text-opacity: 1;
  color: rgba(6, 41, 10, var(--bs-text-opacity)) !important;
}
.bg-green-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(6, 41, 10, var(--bs-bg-opacity)) !important;
}
.bg-gradient-yellow {
  background-color: #f9e80d !important;
  background-image: linear-gradient(to bottom, #faed3f, #e7d706) !important;
}
:root .theme-yellow {
  --bs-theme: #f9e80d !important;
  --bs-theme-rgb: 249, 232, 13 !important;
  --bs-border-color: #f9e80d !important;
}
.text-yellow-100 {
  --bs-text-opacity: 1;
  color: rgba(254, 250, 207, var(--bs-text-opacity)) !important;
}
.bg-yellow-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(254, 250, 207, var(--bs-bg-opacity)) !important;
}
.text-yellow-600 {
  --bs-text-opacity: 1;
  color: rgba(199, 186, 10, var(--bs-text-opacity)) !important;
}
.bg-yellow-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(199, 186, 10, var(--bs-bg-opacity)) !important;
}
.text-yellow-200 {
  --bs-text-opacity: 1;
  color: rgba(253, 246, 158, var(--bs-text-opacity)) !important;
}
.bg-yellow-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(253, 246, 158, var(--bs-bg-opacity)) !important;
}
.text-yellow-700 {
  --bs-text-opacity: 1;
  color: rgba(149, 139, 8, var(--bs-text-opacity)) !important;
}
.bg-yellow-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 139, 8, var(--bs-bg-opacity)) !important;
}
.text-yellow-300 {
  --bs-text-opacity: 1;
  color: rgba(251, 241, 110, var(--bs-text-opacity)) !important;
}
.bg-yellow-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(251, 241, 110, var(--bs-bg-opacity)) !important;
}
.text-yellow-800 {
  --bs-text-opacity: 1;
  color: rgba(100, 93, 5, var(--bs-text-opacity)) !important;
}
.bg-yellow-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(100, 93, 5, var(--bs-bg-opacity)) !important;
}
.text-yellow-400 {
  --bs-text-opacity: 1;
  color: rgba(250, 237, 61, var(--bs-text-opacity)) !important;
}
.bg-yellow-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 237, 61, var(--bs-bg-opacity)) !important;
}
.text-yellow-900 {
  --bs-text-opacity: 1;
  color: rgba(50, 46, 3, var(--bs-text-opacity)) !important;
}
.bg-yellow-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(50, 46, 3, var(--bs-bg-opacity)) !important;
}
.bg-gradient-teal {
  background-color: #3cd2a5 !important;
  background-image: linear-gradient(to bottom, #65dcb8, #2ec799) !important;
}
:root .theme-teal {
  --bs-theme: #3cd2a5 !important;
  --bs-theme-rgb: 60, 210, 165 !important;
  --bs-border-color: #3cd2a5 !important;
}
.text-teal-100 {
  --bs-text-opacity: 1;
  color: rgba(216, 246, 237, var(--bs-text-opacity)) !important;
}
.bg-teal-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(216, 246, 237, var(--bs-bg-opacity)) !important;
}
.text-teal-600 {
  --bs-text-opacity: 1;
  color: rgba(48, 168, 132, var(--bs-text-opacity)) !important;
}
.bg-teal-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 168, 132, var(--bs-bg-opacity)) !important;
}
.text-teal-200 {
  --bs-text-opacity: 1;
  color: rgba(177, 237, 219, var(--bs-text-opacity)) !important;
}
.bg-teal-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(177, 237, 219, var(--bs-bg-opacity)) !important;
}
.text-teal-700 {
  --bs-text-opacity: 1;
  color: rgba(36, 126, 99, var(--bs-text-opacity)) !important;
}
.bg-teal-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(36, 126, 99, var(--bs-bg-opacity)) !important;
}
.text-teal-300 {
  --bs-text-opacity: 1;
  color: rgba(138, 228, 201, var(--bs-text-opacity)) !important;
}
.bg-teal-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(138, 228, 201, var(--bs-bg-opacity)) !important;
}
.text-teal-800 {
  --bs-text-opacity: 1;
  color: rgba(24, 84, 66, var(--bs-text-opacity)) !important;
}
.bg-teal-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 84, 66, var(--bs-bg-opacity)) !important;
}
.text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(99, 219, 183, var(--bs-text-opacity)) !important;
}
.bg-teal-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(99, 219, 183, var(--bs-bg-opacity)) !important;
}
.text-teal-900 {
  --bs-text-opacity: 1;
  color: rgba(12, 42, 33, var(--bs-text-opacity)) !important;
}
.bg-teal-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 42, 33, var(--bs-bg-opacity)) !important;
}
.bg-gradient-muted {
  background-color: #617a86 !important;
  background-image: linear-gradient(to bottom, #7b939f, #566d77) !important;
}
:root .theme-muted {
  --bs-theme: #617a86 !important;
  --bs-theme-rgb: 97, 122, 134 !important;
  --bs-border-color: #617a86 !important;
}
.text-muted-100 {
  --bs-text-opacity: 1;
  color: rgba(223, 228, 231, var(--bs-text-opacity)) !important;
}
.bg-muted-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(223, 228, 231, var(--bs-bg-opacity)) !important;
}
.text-muted-600 {
  --bs-text-opacity: 1;
  color: rgba(78, 98, 107, var(--bs-text-opacity)) !important;
}
.bg-muted-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(78, 98, 107, var(--bs-bg-opacity)) !important;
}
.text-muted-200 {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
}
.bg-muted-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(192, 202, 207, var(--bs-bg-opacity)) !important;
}
.text-muted-700 {
  --bs-text-opacity: 1;
  color: rgba(58, 73, 80, var(--bs-text-opacity)) !important;
}
.bg-muted-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 73, 80, var(--bs-bg-opacity)) !important;
}
.text-muted-300 {
  --bs-text-opacity: 1;
  color: rgba(160, 175, 182, var(--bs-text-opacity)) !important;
}
.bg-muted-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(160, 175, 182, var(--bs-bg-opacity)) !important;
}
.text-muted-800 {
  --bs-text-opacity: 1;
  color: rgba(39, 49, 54, var(--bs-text-opacity)) !important;
}
.bg-muted-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(39, 49, 54, var(--bs-bg-opacity)) !important;
}
.text-muted-400 {
  --bs-text-opacity: 1;
  color: rgba(129, 149, 158, var(--bs-text-opacity)) !important;
}
.bg-muted-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(129, 149, 158, var(--bs-bg-opacity)) !important;
}
.text-muted-900 {
  --bs-text-opacity: 1;
  color: rgba(19, 24, 27, var(--bs-text-opacity)) !important;
}
.bg-muted-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 24, 27, var(--bs-bg-opacity)) !important;
}
.bg-gradient-light {
  background-color: #c0cacf !important;
  background-image: linear-gradient(to bottom, #dde2e5, #b2bec4) !important;
}
:root .theme-light {
  --bs-theme: #c0cacf !important;
  --bs-theme-rgb: 192, 202, 207 !important;
  --bs-border-color: #c0cacf !important;
}
.text-light-100 {
  --bs-text-opacity: 1;
  color: rgba(242, 244, 245, var(--bs-text-opacity)) !important;
}
.bg-light-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 244, 245, var(--bs-bg-opacity)) !important;
}
.text-light-600 {
  --bs-text-opacity: 1;
  color: rgba(154, 162, 166, var(--bs-text-opacity)) !important;
}
.bg-light-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 162, 166, var(--bs-bg-opacity)) !important;
}
.text-light-200 {
  --bs-text-opacity: 1;
  color: rgba(230, 234, 236, var(--bs-text-opacity)) !important;
}
.bg-light-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 234, 236, var(--bs-bg-opacity)) !important;
}
.text-light-700 {
  --bs-text-opacity: 1;
  color: rgba(115, 121, 124, var(--bs-text-opacity)) !important;
}
.bg-light-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 121, 124, var(--bs-bg-opacity)) !important;
}
.text-light-300 {
  --bs-text-opacity: 1;
  color: rgba(217, 223, 226, var(--bs-text-opacity)) !important;
}
.bg-light-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(217, 223, 226, var(--bs-bg-opacity)) !important;
}
.text-light-800 {
  --bs-text-opacity: 1;
  color: rgba(77, 81, 83, var(--bs-text-opacity)) !important;
}
.bg-light-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 81, 83, var(--bs-bg-opacity)) !important;
}
.text-light-400 {
  --bs-text-opacity: 1;
  color: rgba(205, 213, 217, var(--bs-text-opacity)) !important;
}
.bg-light-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 213, 217, var(--bs-bg-opacity)) !important;
}
.text-light-900 {
  --bs-text-opacity: 1;
  color: rgba(38, 40, 41, var(--bs-text-opacity)) !important;
}
.bg-light-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 40, 41, var(--bs-bg-opacity)) !important;
}
.bg-gradient-lime {
  background-color: #81f32d !important;
  background-image: linear-gradient(to bottom, #9ef65d, #73f215) !important;
}
:root .theme-lime {
  --bs-theme: #81f32d !important;
  --bs-theme-rgb: 129, 243, 45 !important;
  --bs-border-color: #81f32d !important;
}
.text-lime-100 {
  --bs-text-opacity: 1;
  color: rgba(230, 253, 213, var(--bs-text-opacity)) !important;
}
.bg-lime-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 253, 213, var(--bs-bg-opacity)) !important;
}
.text-lime-600 {
  --bs-text-opacity: 1;
  color: rgba(103, 194, 36, var(--bs-text-opacity)) !important;
}
.bg-lime-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(103, 194, 36, var(--bs-bg-opacity)) !important;
}
.text-lime-200 {
  --bs-text-opacity: 1;
  color: rgba(205, 250, 171, var(--bs-text-opacity)) !important;
}
.bg-lime-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 250, 171, var(--bs-bg-opacity)) !important;
}
.text-lime-700 {
  --bs-text-opacity: 1;
  color: rgba(77, 146, 27, var(--bs-text-opacity)) !important;
}
.bg-lime-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 146, 27, var(--bs-bg-opacity)) !important;
}
.text-lime-300 {
  --bs-text-opacity: 1;
  color: rgba(179, 248, 129, var(--bs-text-opacity)) !important;
}
.bg-lime-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(179, 248, 129, var(--bs-bg-opacity)) !important;
}
.text-lime-800 {
  --bs-text-opacity: 1;
  color: rgba(52, 97, 18, var(--bs-text-opacity)) !important;
}
.bg-lime-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(52, 97, 18, var(--bs-bg-opacity)) !important;
}
.text-lime-400 {
  --bs-text-opacity: 1;
  color: rgba(154, 245, 87, var(--bs-text-opacity)) !important;
}
.bg-lime-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 245, 87, var(--bs-bg-opacity)) !important;
}
.text-lime-900 {
  --bs-text-opacity: 1;
  color: rgba(26, 49, 9, var(--bs-text-opacity)) !important;
}
.bg-lime-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(26, 49, 9, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-100 {
  background-color: #c0cacf !important;
  background-image: linear-gradient(to bottom, #dde2e5, #b2bec4) !important;
}
:root .theme-gray-100 {
  --bs-theme: #c0cacf !important;
  --bs-theme-rgb: 192, 202, 207 !important;
  --bs-border-color: #c0cacf !important;
}
.text-gray-100-100 {
  --bs-text-opacity: 1;
  color: rgba(242, 244, 245, var(--bs-text-opacity)) !important;
}
.bg-gray-100-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 244, 245, var(--bs-bg-opacity)) !important;
}
.text-gray-100-600 {
  --bs-text-opacity: 1;
  color: rgba(154, 162, 166, var(--bs-text-opacity)) !important;
}
.bg-gray-100-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(154, 162, 166, var(--bs-bg-opacity)) !important;
}
.text-gray-100-200 {
  --bs-text-opacity: 1;
  color: rgba(230, 234, 236, var(--bs-text-opacity)) !important;
}
.bg-gray-100-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(230, 234, 236, var(--bs-bg-opacity)) !important;
}
.text-gray-100-700 {
  --bs-text-opacity: 1;
  color: rgba(115, 121, 124, var(--bs-text-opacity)) !important;
}
.bg-gray-100-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 121, 124, var(--bs-bg-opacity)) !important;
}
.text-gray-100-300 {
  --bs-text-opacity: 1;
  color: rgba(217, 223, 226, var(--bs-text-opacity)) !important;
}
.bg-gray-100-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(217, 223, 226, var(--bs-bg-opacity)) !important;
}
.text-gray-100-800 {
  --bs-text-opacity: 1;
  color: rgba(77, 81, 83, var(--bs-text-opacity)) !important;
}
.bg-gray-100-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(77, 81, 83, var(--bs-bg-opacity)) !important;
}
.text-gray-100-400 {
  --bs-text-opacity: 1;
  color: rgba(205, 213, 217, var(--bs-text-opacity)) !important;
}
.bg-gray-100-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(205, 213, 217, var(--bs-bg-opacity)) !important;
}
.text-gray-100-900 {
  --bs-text-opacity: 1;
  color: rgba(38, 40, 41, var(--bs-text-opacity)) !important;
}
.bg-gray-100-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 40, 41, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-200 {
  background-color: #a8b6bc !important;
  background-image: linear-gradient(to bottom, #c5ced2, #9aaab1) !important;
}
:root .theme-gray-200 {
  --bs-theme: #a8b6bc !important;
  --bs-theme-rgb: 168, 182, 188 !important;
  --bs-border-color: #a8b6bc !important;
}
.text-gray-200-100 {
  --bs-text-opacity: 1;
  color: rgba(238, 240, 242, var(--bs-text-opacity)) !important;
}
.bg-gray-200-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(238, 240, 242, var(--bs-bg-opacity)) !important;
}
.text-gray-200-600 {
  --bs-text-opacity: 1;
  color: rgba(134, 146, 150, var(--bs-text-opacity)) !important;
}
.bg-gray-200-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 146, 150, var(--bs-bg-opacity)) !important;
}
.text-gray-200-200 {
  --bs-text-opacity: 1;
  color: rgba(220, 226, 228, var(--bs-text-opacity)) !important;
}
.bg-gray-200-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 226, 228, var(--bs-bg-opacity)) !important;
}
.text-gray-200-700 {
  --bs-text-opacity: 1;
  color: rgba(101, 109, 113, var(--bs-text-opacity)) !important;
}
.bg-gray-200-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(101, 109, 113, var(--bs-bg-opacity)) !important;
}
.text-gray-200-300 {
  --bs-text-opacity: 1;
  color: rgba(203, 211, 215, var(--bs-text-opacity)) !important;
}
.bg-gray-200-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(203, 211, 215, var(--bs-bg-opacity)) !important;
}
.text-gray-200-800 {
  --bs-text-opacity: 1;
  color: rgba(67, 73, 75, var(--bs-text-opacity)) !important;
}
.bg-gray-200-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(67, 73, 75, var(--bs-bg-opacity)) !important;
}
.text-gray-200-400 {
  --bs-text-opacity: 1;
  color: rgba(185, 197, 201, var(--bs-text-opacity)) !important;
}
.bg-gray-200-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(185, 197, 201, var(--bs-bg-opacity)) !important;
}
.text-gray-200-900 {
  --bs-text-opacity: 1;
  color: rgba(34, 36, 38, var(--bs-text-opacity)) !important;
}
.bg-gray-200-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(34, 36, 38, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-300 {
  background-color: #90a2aa !important;
  background-image: linear-gradient(to bottom, #adbac0, #82969f) !important;
}
:root .theme-gray-300 {
  --bs-theme: #90a2aa !important;
  --bs-theme-rgb: 144, 162, 170 !important;
  --bs-border-color: #90a2aa !important;
}
.text-gray-300-100 {
  --bs-text-opacity: 1;
  color: rgba(233, 236, 238, var(--bs-text-opacity)) !important;
}
.bg-gray-300-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(233, 236, 238, var(--bs-bg-opacity)) !important;
}
.text-gray-300-600 {
  --bs-text-opacity: 1;
  color: rgba(115, 130, 136, var(--bs-text-opacity)) !important;
}
.bg-gray-300-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(115, 130, 136, var(--bs-bg-opacity)) !important;
}
.text-gray-300-200 {
  --bs-text-opacity: 1;
  color: rgba(211, 218, 221, var(--bs-text-opacity)) !important;
}
.bg-gray-300-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(211, 218, 221, var(--bs-bg-opacity)) !important;
}
.text-gray-300-700 {
  --bs-text-opacity: 1;
  color: rgba(86, 97, 102, var(--bs-text-opacity)) !important;
}
.bg-gray-300-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(86, 97, 102, var(--bs-bg-opacity)) !important;
}
.text-gray-300-300 {
  --bs-text-opacity: 1;
  color: rgba(188, 199, 204, var(--bs-text-opacity)) !important;
}
.bg-gray-300-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(188, 199, 204, var(--bs-bg-opacity)) !important;
}
.text-gray-300-800 {
  --bs-text-opacity: 1;
  color: rgba(58, 65, 68, var(--bs-text-opacity)) !important;
}
.bg-gray-300-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 65, 68, var(--bs-bg-opacity)) !important;
}
.text-gray-300-400 {
  --bs-text-opacity: 1;
  color: rgba(166, 181, 187, var(--bs-text-opacity)) !important;
}
.bg-gray-300-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(166, 181, 187, var(--bs-bg-opacity)) !important;
}
.text-gray-300-900 {
  --bs-text-opacity: 1;
  color: rgba(29, 32, 34, var(--bs-text-opacity)) !important;
}
.bg-gray-300-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 32, 34, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-400 {
  background-color: #798e98 !important;
  background-image: linear-gradient(to bottom, #96a6ae, #6c818c) !important;
}
:root .theme-gray-400 {
  --bs-theme: #798e98 !important;
  --bs-theme-rgb: 121, 142, 152 !important;
  --bs-border-color: #798e98 !important;
}
.text-gray-400-100 {
  --bs-text-opacity: 1;
  color: rgba(228, 232, 234, var(--bs-text-opacity)) !important;
}
.bg-gray-400-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 232, 234, var(--bs-bg-opacity)) !important;
}
.text-gray-400-600 {
  --bs-text-opacity: 1;
  color: rgba(97, 114, 122, var(--bs-text-opacity)) !important;
}
.bg-gray-400-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(97, 114, 122, var(--bs-bg-opacity)) !important;
}
.text-gray-400-200 {
  --bs-text-opacity: 1;
  color: rgba(201, 210, 214, var(--bs-text-opacity)) !important;
}
.bg-gray-400-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 210, 214, var(--bs-bg-opacity)) !important;
}
.text-gray-400-700 {
  --bs-text-opacity: 1;
  color: rgba(73, 85, 91, var(--bs-text-opacity)) !important;
}
.bg-gray-400-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(73, 85, 91, var(--bs-bg-opacity)) !important;
}
.text-gray-400-300 {
  --bs-text-opacity: 1;
  color: rgba(175, 187, 193, var(--bs-text-opacity)) !important;
}
.bg-gray-400-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(175, 187, 193, var(--bs-bg-opacity)) !important;
}
.text-gray-400-800 {
  --bs-text-opacity: 1;
  color: rgba(48, 57, 61, var(--bs-text-opacity)) !important;
}
.bg-gray-400-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 57, 61, var(--bs-bg-opacity)) !important;
}
.text-gray-400-400 {
  --bs-text-opacity: 1;
  color: rgba(148, 165, 173, var(--bs-text-opacity)) !important;
}
.bg-gray-400-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(148, 165, 173, var(--bs-bg-opacity)) !important;
}
.text-gray-400-900 {
  --bs-text-opacity: 1;
  color: rgba(24, 28, 30, var(--bs-text-opacity)) !important;
}
.bg-gray-400-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 28, 30, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-500 {
  background-color: #617a86 !important;
  background-image: linear-gradient(to bottom, #7b939f, #566d77) !important;
}
:root .theme-gray-500 {
  --bs-theme: #617a86 !important;
  --bs-theme-rgb: 97, 122, 134 !important;
  --bs-border-color: #617a86 !important;
}
.text-gray-500-100 {
  --bs-text-opacity: 1;
  color: rgba(223, 228, 231, var(--bs-text-opacity)) !important;
}
.bg-gray-500-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(223, 228, 231, var(--bs-bg-opacity)) !important;
}
.text-gray-500-600 {
  --bs-text-opacity: 1;
  color: rgba(78, 98, 107, var(--bs-text-opacity)) !important;
}
.bg-gray-500-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(78, 98, 107, var(--bs-bg-opacity)) !important;
}
.text-gray-500-200 {
  --bs-text-opacity: 1;
  color: rgba(192, 202, 207, var(--bs-text-opacity)) !important;
}
.bg-gray-500-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(192, 202, 207, var(--bs-bg-opacity)) !important;
}
.text-gray-500-700 {
  --bs-text-opacity: 1;
  color: rgba(58, 73, 80, var(--bs-text-opacity)) !important;
}
.bg-gray-500-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(58, 73, 80, var(--bs-bg-opacity)) !important;
}
.text-gray-500-300 {
  --bs-text-opacity: 1;
  color: rgba(160, 175, 182, var(--bs-text-opacity)) !important;
}
.bg-gray-500-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(160, 175, 182, var(--bs-bg-opacity)) !important;
}
.text-gray-500-800 {
  --bs-text-opacity: 1;
  color: rgba(39, 49, 54, var(--bs-text-opacity)) !important;
}
.bg-gray-500-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(39, 49, 54, var(--bs-bg-opacity)) !important;
}
.text-gray-500-400 {
  --bs-text-opacity: 1;
  color: rgba(129, 149, 158, var(--bs-text-opacity)) !important;
}
.bg-gray-500-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(129, 149, 158, var(--bs-bg-opacity)) !important;
}
.text-gray-500-900 {
  --bs-text-opacity: 1;
  color: rgba(19, 24, 27, var(--bs-text-opacity)) !important;
}
.bg-gray-500-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 24, 27, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-600 {
  background-color: #526872 !important;
  background-image: linear-gradient(to bottom, #678390, #475a63) !important;
}
:root .theme-gray-600 {
  --bs-theme: #526872 !important;
  --bs-theme-rgb: 82, 104, 114 !important;
  --bs-border-color: #526872 !important;
}
.text-gray-600-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 225, 227, var(--bs-text-opacity)) !important;
}
.bg-gray-600-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 225, 227, var(--bs-bg-opacity)) !important;
}
.text-gray-600-600 {
  --bs-text-opacity: 1;
  color: rgba(66, 83, 91, var(--bs-text-opacity)) !important;
}
.bg-gray-600-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(66, 83, 91, var(--bs-bg-opacity)) !important;
}
.text-gray-600-200 {
  --bs-text-opacity: 1;
  color: rgba(186, 195, 199, var(--bs-text-opacity)) !important;
}
.bg-gray-600-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 195, 199, var(--bs-bg-opacity)) !important;
}
.text-gray-600-700 {
  --bs-text-opacity: 1;
  color: rgba(49, 62, 68, var(--bs-text-opacity)) !important;
}
.bg-gray-600-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(49, 62, 68, var(--bs-bg-opacity)) !important;
}
.text-gray-600-300 {
  --bs-text-opacity: 1;
  color: rgba(151, 164, 170, var(--bs-text-opacity)) !important;
}
.bg-gray-600-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(151, 164, 170, var(--bs-bg-opacity)) !important;
}
.text-gray-600-800 {
  --bs-text-opacity: 1;
  color: rgba(33, 42, 46, var(--bs-text-opacity)) !important;
}
.bg-gray-600-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(33, 42, 46, var(--bs-bg-opacity)) !important;
}
.text-gray-600-400 {
  --bs-text-opacity: 1;
  color: rgba(117, 134, 142, var(--bs-text-opacity)) !important;
}
.bg-gray-600-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(117, 134, 142, var(--bs-bg-opacity)) !important;
}
.text-gray-600-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 21, 23, var(--bs-text-opacity)) !important;
}
.bg-gray-600-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 21, 23, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-700 {
  background-color: #44555e !important;
  background-image: linear-gradient(to bottom, #59707c, #39484f) !important;
}
:root .theme-gray-700 {
  --bs-theme: #44555e !important;
  --bs-theme-rgb: 68, 85, 94 !important;
  --bs-border-color: #44555e !important;
}
.text-gray-700-100 {
  --bs-text-opacity: 1;
  color: rgba(218, 221, 223, var(--bs-text-opacity)) !important;
}
.bg-gray-700-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(218, 221, 223, var(--bs-bg-opacity)) !important;
}
.text-gray-700-600 {
  --bs-text-opacity: 1;
  color: rgba(54, 68, 75, var(--bs-text-opacity)) !important;
}
.bg-gray-700-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(54, 68, 75, var(--bs-bg-opacity)) !important;
}
.text-gray-700-200 {
  --bs-text-opacity: 1;
  color: rgba(180, 187, 191, var(--bs-text-opacity)) !important;
}
.bg-gray-700-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(180, 187, 191, var(--bs-bg-opacity)) !important;
}
.text-gray-700-700 {
  --bs-text-opacity: 1;
  color: rgba(41, 51, 56, var(--bs-text-opacity)) !important;
}
.bg-gray-700-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(41, 51, 56, var(--bs-bg-opacity)) !important;
}
.text-gray-700-300 {
  --bs-text-opacity: 1;
  color: rgba(143, 153, 158, var(--bs-text-opacity)) !important;
}
.bg-gray-700-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(143, 153, 158, var(--bs-bg-opacity)) !important;
}
.text-gray-700-800 {
  --bs-text-opacity: 1;
  color: rgba(27, 34, 38, var(--bs-text-opacity)) !important;
}
.bg-gray-700-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(27, 34, 38, var(--bs-bg-opacity)) !important;
}
.text-gray-700-400 {
  --bs-text-opacity: 1;
  color: rgba(105, 119, 126, var(--bs-text-opacity)) !important;
}
.bg-gray-700-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(105, 119, 126, var(--bs-bg-opacity)) !important;
}
.text-gray-700-900 {
  --bs-text-opacity: 1;
  color: rgba(14, 17, 19, var(--bs-text-opacity)) !important;
}
.bg-gray-700-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(14, 17, 19, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-800 {
  background-color: #35434a !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
}
:root .theme-gray-800 {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-border-color: #35434a !important;
}
.text-gray-800-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}
.bg-gray-800-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}
.text-gray-800-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}
.bg-gray-800-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}
.text-gray-800-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}
.bg-gray-800-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}
.text-gray-800-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}
.bg-gray-800-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}
.text-gray-800-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}
.bg-gray-800-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}
.text-gray-800-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}
.bg-gray-800-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}
.text-gray-800-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}
.bg-gray-800-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}
.text-gray-800-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}
.bg-gray-800-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-900 {
  background-color: #273136 !important;
  background-image: linear-gradient(to bottom, #3c4c54, #1c2427) !important;
}
:root .theme-gray-900 {
  --bs-theme: #273136 !important;
  --bs-theme-rgb: 39, 49, 54 !important;
  --bs-border-color: #273136 !important;
}
.text-gray-900-100 {
  --bs-text-opacity: 1;
  color: rgba(212, 214, 215, var(--bs-text-opacity)) !important;
}
.bg-gray-900-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(212, 214, 215, var(--bs-bg-opacity)) !important;
}
.text-gray-900-600 {
  --bs-text-opacity: 1;
  color: rgba(31, 39, 43, var(--bs-text-opacity)) !important;
}
.bg-gray-900-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(31, 39, 43, var(--bs-bg-opacity)) !important;
}
.text-gray-900-200 {
  --bs-text-opacity: 1;
  color: rgba(169, 173, 175, var(--bs-text-opacity)) !important;
}
.bg-gray-900-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(169, 173, 175, var(--bs-bg-opacity)) !important;
}
.text-gray-900-700 {
  --bs-text-opacity: 1;
  color: rgba(23, 29, 32, var(--bs-text-opacity)) !important;
}
.bg-gray-900-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(23, 29, 32, var(--bs-bg-opacity)) !important;
}
.text-gray-900-300 {
  --bs-text-opacity: 1;
  color: rgba(125, 131, 134, var(--bs-text-opacity)) !important;
}
.bg-gray-900-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(125, 131, 134, var(--bs-bg-opacity)) !important;
}
.text-gray-900-800 {
  --bs-text-opacity: 1;
  color: rgba(16, 20, 22, var(--bs-text-opacity)) !important;
}
.bg-gray-900-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 20, 22, var(--bs-bg-opacity)) !important;
}
.text-gray-900-400 {
  --bs-text-opacity: 1;
  color: rgba(82, 90, 94, var(--bs-text-opacity)) !important;
}
.bg-gray-900-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(82, 90, 94, var(--bs-bg-opacity)) !important;
}
.text-gray-900-900 {
  --bs-text-opacity: 1;
  color: rgba(8, 10, 11, var(--bs-text-opacity)) !important;
}
.bg-gray-900-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(8, 10, 11, var(--bs-bg-opacity)) !important;
}
.bg-gradient-blue {
  background-color: #4e9cff !important;
  background-image: linear-gradient(to bottom, #81b9ff, #358eff) !important;
}
:root .theme-blue {
  --bs-theme: #4e9cff !important;
  --bs-theme-rgb: 78, 156, 255 !important;
  --bs-border-color: #4e9cff !important;
}
.text-blue-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 235, 255, var(--bs-text-opacity)) !important;
}
.bg-blue-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 235, 255, var(--bs-bg-opacity)) !important;
}
.text-blue-600 {
  --bs-text-opacity: 1;
  color: rgba(62, 125, 204, var(--bs-text-opacity)) !important;
}
.bg-blue-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(62, 125, 204, var(--bs-bg-opacity)) !important;
}
.text-blue-200 {
  --bs-text-opacity: 1;
  color: rgba(184, 215, 255, var(--bs-text-opacity)) !important;
}
.bg-blue-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(184, 215, 255, var(--bs-bg-opacity)) !important;
}
.text-blue-700 {
  --bs-text-opacity: 1;
  color: rgba(47, 94, 153, var(--bs-text-opacity)) !important;
}
.bg-blue-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(47, 94, 153, var(--bs-bg-opacity)) !important;
}
.text-blue-300 {
  --bs-text-opacity: 1;
  color: rgba(149, 196, 255, var(--bs-text-opacity)) !important;
}
.bg-blue-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 196, 255, var(--bs-bg-opacity)) !important;
}
.text-blue-800 {
  --bs-text-opacity: 1;
  color: rgba(31, 62, 102, var(--bs-text-opacity)) !important;
}
.bg-blue-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(31, 62, 102, var(--bs-bg-opacity)) !important;
}
.text-blue-400 {
  --bs-text-opacity: 1;
  color: rgba(113, 176, 255, var(--bs-text-opacity)) !important;
}
.bg-blue-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(113, 176, 255, var(--bs-bg-opacity)) !important;
}
.text-blue-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 31, 51, var(--bs-text-opacity)) !important;
}
.bg-blue-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 31, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-indigo {
  background-color: #bba4ff !important;
  background-image: linear-gradient(to bottom, #e1d7ff, #a88bff) !important;
}
:root .theme-indigo {
  --bs-theme: #bba4ff !important;
  --bs-theme-rgb: 187, 164, 255 !important;
  --bs-border-color: #bba4ff !important;
}
.text-indigo-100 {
  --bs-text-opacity: 1;
  color: rgba(241, 237, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 237, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-600 {
  --bs-text-opacity: 1;
  color: rgba(150, 131, 204, var(--bs-text-opacity)) !important;
}
.bg-indigo-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(150, 131, 204, var(--bs-bg-opacity)) !important;
}
.text-indigo-200 {
  --bs-text-opacity: 1;
  color: rgba(228, 219, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(228, 219, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-700 {
  --bs-text-opacity: 1;
  color: rgba(112, 98, 153, var(--bs-text-opacity)) !important;
}
.bg-indigo-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(112, 98, 153, var(--bs-bg-opacity)) !important;
}
.text-indigo-300 {
  --bs-text-opacity: 1;
  color: rgba(214, 200, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(214, 200, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-800 {
  --bs-text-opacity: 1;
  color: rgba(75, 66, 102, var(--bs-text-opacity)) !important;
}
.bg-indigo-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(75, 66, 102, var(--bs-bg-opacity)) !important;
}
.text-indigo-400 {
  --bs-text-opacity: 1;
  color: rgba(201, 182, 255, var(--bs-text-opacity)) !important;
}
.bg-indigo-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(201, 182, 255, var(--bs-bg-opacity)) !important;
}
.text-indigo-900 {
  --bs-text-opacity: 1;
  color: rgba(37, 33, 51, var(--bs-text-opacity)) !important;
}
.bg-indigo-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(37, 33, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-purple {
  background-color: #de8cff !important;
  background-image: linear-gradient(to bottom, #edbfff, #d773ff) !important;
}
:root .theme-purple {
  --bs-theme: #de8cff !important;
  --bs-theme-rgb: 222, 140, 255 !important;
  --bs-border-color: #de8cff !important;
}
.text-purple-100 {
  --bs-text-opacity: 1;
  color: rgba(248, 232, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(248, 232, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-600 {
  --bs-text-opacity: 1;
  color: rgba(178, 112, 204, var(--bs-text-opacity)) !important;
}
.bg-purple-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(178, 112, 204, var(--bs-bg-opacity)) !important;
}
.text-purple-200 {
  --bs-text-opacity: 1;
  color: rgba(242, 209, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(242, 209, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-700 {
  --bs-text-opacity: 1;
  color: rgba(133, 84, 153, var(--bs-text-opacity)) !important;
}
.bg-purple-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(133, 84, 153, var(--bs-bg-opacity)) !important;
}
.text-purple-300 {
  --bs-text-opacity: 1;
  color: rgba(235, 186, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(235, 186, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-800 {
  --bs-text-opacity: 1;
  color: rgba(89, 56, 102, var(--bs-text-opacity)) !important;
}
.bg-purple-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(89, 56, 102, var(--bs-bg-opacity)) !important;
}
.text-purple-400 {
  --bs-text-opacity: 1;
  color: rgba(229, 163, 255, var(--bs-text-opacity)) !important;
}
.bg-purple-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(229, 163, 255, var(--bs-bg-opacity)) !important;
}
.text-purple-900 {
  --bs-text-opacity: 1;
  color: rgba(44, 28, 51, var(--bs-text-opacity)) !important;
}
.bg-purple-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(44, 28, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-pink {
  background-color: #e83293 !important;
  background-image: linear-gradient(to bottom, #ed60ab, #e51b87) !important;
}
:root .theme-pink {
  --bs-theme: #e83293 !important;
  --bs-theme-rgb: 232, 50, 147 !important;
  --bs-border-color: #e83293 !important;
}
.text-pink-100 {
  --bs-text-opacity: 1;
  color: rgba(250, 214, 233, var(--bs-text-opacity)) !important;
}
.bg-pink-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 214, 233, var(--bs-bg-opacity)) !important;
}
.text-pink-600 {
  --bs-text-opacity: 1;
  color: rgba(186, 40, 118, var(--bs-text-opacity)) !important;
}
.bg-pink-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 40, 118, var(--bs-bg-opacity)) !important;
}
.text-pink-200 {
  --bs-text-opacity: 1;
  color: rgba(246, 173, 212, var(--bs-text-opacity)) !important;
}
.bg-pink-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(246, 173, 212, var(--bs-bg-opacity)) !important;
}
.text-pink-700 {
  --bs-text-opacity: 1;
  color: rgba(139, 30, 88, var(--bs-text-opacity)) !important;
}
.bg-pink-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(139, 30, 88, var(--bs-bg-opacity)) !important;
}
.text-pink-300 {
  --bs-text-opacity: 1;
  color: rgba(241, 132, 190, var(--bs-text-opacity)) !important;
}
.bg-pink-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(241, 132, 190, var(--bs-bg-opacity)) !important;
}
.text-pink-800 {
  --bs-text-opacity: 1;
  color: rgba(93, 20, 59, var(--bs-text-opacity)) !important;
}
.bg-pink-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 20, 59, var(--bs-bg-opacity)) !important;
}
.text-pink-400 {
  --bs-text-opacity: 1;
  color: rgba(237, 91, 169, var(--bs-text-opacity)) !important;
}
.bg-pink-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(237, 91, 169, var(--bs-bg-opacity)) !important;
}
.text-pink-900 {
  --bs-text-opacity: 1;
  color: rgba(46, 10, 29, var(--bs-text-opacity)) !important;
}
.bg-pink-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(46, 10, 29, var(--bs-bg-opacity)) !important;
}
.bg-gradient-red {
  background-color: #ff6060 !important;
  background-image: linear-gradient(to bottom, #ff9393, #ff4747) !important;
}
:root .theme-red {
  --bs-theme: #ff6060 !important;
  --bs-theme-rgb: 255, 96, 96 !important;
  --bs-border-color: #ff6060 !important;
}
.text-red-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 223, 223, var(--bs-text-opacity)) !important;
}
.bg-red-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 223, 223, var(--bs-bg-opacity)) !important;
}
.text-red-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 77, 77, var(--bs-text-opacity)) !important;
}
.bg-red-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 77, 77, var(--bs-bg-opacity)) !important;
}
.text-red-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 191, 191, var(--bs-text-opacity)) !important;
}
.bg-red-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 191, 191, var(--bs-bg-opacity)) !important;
}
.text-red-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 58, 58, var(--bs-text-opacity)) !important;
}
.bg-red-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 58, 58, var(--bs-bg-opacity)) !important;
}
.text-red-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 160, 160, var(--bs-text-opacity)) !important;
}
.bg-red-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 160, 160, var(--bs-bg-opacity)) !important;
}
.text-red-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 38, 38, var(--bs-text-opacity)) !important;
}
.bg-red-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 38, 38, var(--bs-bg-opacity)) !important;
}
.text-red-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 128, 128, var(--bs-text-opacity)) !important;
}
.bg-red-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 128, 128, var(--bs-bg-opacity)) !important;
}
.text-red-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 19, 19, var(--bs-text-opacity)) !important;
}
.bg-red-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 19, 19, var(--bs-bg-opacity)) !important;
}
.bg-gradient-orange {
  background-color: #ff9f0c !important;
  background-image: linear-gradient(to bottom, #ffb33f, #f29200) !important;
}
:root .theme-orange {
  --bs-theme: #ff9f0c !important;
  --bs-theme-rgb: 255, 159, 12 !important;
  --bs-border-color: #ff9f0c !important;
}
.text-orange-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 236, 206, var(--bs-text-opacity)) !important;
}
.bg-orange-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 236, 206, var(--bs-bg-opacity)) !important;
}
.text-orange-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 127, 10, var(--bs-text-opacity)) !important;
}
.bg-orange-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 127, 10, var(--bs-bg-opacity)) !important;
}
.text-orange-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 217, 158, var(--bs-text-opacity)) !important;
}
.bg-orange-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 217, 158, var(--bs-bg-opacity)) !important;
}
.text-orange-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 95, 7, var(--bs-text-opacity)) !important;
}
.bg-orange-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 95, 7, var(--bs-bg-opacity)) !important;
}
.text-orange-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 197, 109, var(--bs-text-opacity)) !important;
}
.bg-orange-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 197, 109, var(--bs-bg-opacity)) !important;
}
.text-orange-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 64, 5, var(--bs-text-opacity)) !important;
}
.bg-orange-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 64, 5, var(--bs-bg-opacity)) !important;
}
.text-orange-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 178, 61, var(--bs-text-opacity)) !important;
}
.bg-orange-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 178, 61, var(--bs-bg-opacity)) !important;
}
.text-orange-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 32, 2, var(--bs-text-opacity)) !important;
}
.bg-orange-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 32, 2, var(--bs-bg-opacity)) !important;
}
.bg-gradient-yellow {
  background-color: #f9e80d !important;
  background-image: linear-gradient(to bottom, #faed3f, #e7d706) !important;
}
:root .theme-yellow {
  --bs-theme: #f9e80d !important;
  --bs-theme-rgb: 249, 232, 13 !important;
  --bs-border-color: #f9e80d !important;
}
.text-yellow-100 {
  --bs-text-opacity: 1;
  color: rgba(254, 250, 207, var(--bs-text-opacity)) !important;
}
.bg-yellow-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(254, 250, 207, var(--bs-bg-opacity)) !important;
}
.text-yellow-600 {
  --bs-text-opacity: 1;
  color: rgba(199, 186, 10, var(--bs-text-opacity)) !important;
}
.bg-yellow-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(199, 186, 10, var(--bs-bg-opacity)) !important;
}
.text-yellow-200 {
  --bs-text-opacity: 1;
  color: rgba(253, 246, 158, var(--bs-text-opacity)) !important;
}
.bg-yellow-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(253, 246, 158, var(--bs-bg-opacity)) !important;
}
.text-yellow-700 {
  --bs-text-opacity: 1;
  color: rgba(149, 139, 8, var(--bs-text-opacity)) !important;
}
.bg-yellow-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(149, 139, 8, var(--bs-bg-opacity)) !important;
}
.text-yellow-300 {
  --bs-text-opacity: 1;
  color: rgba(251, 241, 110, var(--bs-text-opacity)) !important;
}
.bg-yellow-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(251, 241, 110, var(--bs-bg-opacity)) !important;
}
.text-yellow-800 {
  --bs-text-opacity: 1;
  color: rgba(100, 93, 5, var(--bs-text-opacity)) !important;
}
.bg-yellow-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(100, 93, 5, var(--bs-bg-opacity)) !important;
}
.text-yellow-400 {
  --bs-text-opacity: 1;
  color: rgba(250, 237, 61, var(--bs-text-opacity)) !important;
}
.bg-yellow-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(250, 237, 61, var(--bs-bg-opacity)) !important;
}
.text-yellow-900 {
  --bs-text-opacity: 1;
  color: rgba(50, 46, 3, var(--bs-text-opacity)) !important;
}
.bg-yellow-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(50, 46, 3, var(--bs-bg-opacity)) !important;
}
.bg-gradient-green {
  background-color: #1ecc33 !important;
  background-image: linear-gradient(to bottom, #3be24f, #1bb62d) !important;
}
:root .theme-green {
  --bs-theme: #1ecc33 !important;
  --bs-theme-rgb: 30, 204, 51 !important;
  --bs-border-color: #1ecc33 !important;
}
.text-green-100 {
  --bs-text-opacity: 1;
  color: rgba(210, 245, 214, var(--bs-text-opacity)) !important;
}
.bg-green-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(210, 245, 214, var(--bs-bg-opacity)) !important;
}
.text-green-600 {
  --bs-text-opacity: 1;
  color: rgba(24, 163, 41, var(--bs-text-opacity)) !important;
}
.bg-green-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 163, 41, var(--bs-bg-opacity)) !important;
}
.text-green-200 {
  --bs-text-opacity: 1;
  color: rgba(165, 235, 173, var(--bs-text-opacity)) !important;
}
.bg-green-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(165, 235, 173, var(--bs-bg-opacity)) !important;
}
.text-green-700 {
  --bs-text-opacity: 1;
  color: rgba(18, 122, 31, var(--bs-text-opacity)) !important;
}
.bg-green-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(18, 122, 31, var(--bs-bg-opacity)) !important;
}
.text-green-300 {
  --bs-text-opacity: 1;
  color: rgba(120, 224, 133, var(--bs-text-opacity)) !important;
}
.bg-green-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(120, 224, 133, var(--bs-bg-opacity)) !important;
}
.text-green-800 {
  --bs-text-opacity: 1;
  color: rgba(12, 82, 20, var(--bs-text-opacity)) !important;
}
.bg-green-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 82, 20, var(--bs-bg-opacity)) !important;
}
.text-green-400 {
  --bs-text-opacity: 1;
  color: rgba(75, 214, 92, var(--bs-text-opacity)) !important;
}
.bg-green-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(75, 214, 92, var(--bs-bg-opacity)) !important;
}
.text-green-900 {
  --bs-text-opacity: 1;
  color: rgba(6, 41, 10, var(--bs-text-opacity)) !important;
}
.bg-green-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(6, 41, 10, var(--bs-bg-opacity)) !important;
}
.bg-gradient-teal {
  background-color: #3cd2a5 !important;
  background-image: linear-gradient(to bottom, #65dcb8, #2ec799) !important;
}
:root .theme-teal {
  --bs-theme: #3cd2a5 !important;
  --bs-theme-rgb: 60, 210, 165 !important;
  --bs-border-color: #3cd2a5 !important;
}
.text-teal-100 {
  --bs-text-opacity: 1;
  color: rgba(216, 246, 237, var(--bs-text-opacity)) !important;
}
.bg-teal-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(216, 246, 237, var(--bs-bg-opacity)) !important;
}
.text-teal-600 {
  --bs-text-opacity: 1;
  color: rgba(48, 168, 132, var(--bs-text-opacity)) !important;
}
.bg-teal-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(48, 168, 132, var(--bs-bg-opacity)) !important;
}
.text-teal-200 {
  --bs-text-opacity: 1;
  color: rgba(177, 237, 219, var(--bs-text-opacity)) !important;
}
.bg-teal-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(177, 237, 219, var(--bs-bg-opacity)) !important;
}
.text-teal-700 {
  --bs-text-opacity: 1;
  color: rgba(36, 126, 99, var(--bs-text-opacity)) !important;
}
.bg-teal-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(36, 126, 99, var(--bs-bg-opacity)) !important;
}
.text-teal-300 {
  --bs-text-opacity: 1;
  color: rgba(138, 228, 201, var(--bs-text-opacity)) !important;
}
.bg-teal-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(138, 228, 201, var(--bs-bg-opacity)) !important;
}
.text-teal-800 {
  --bs-text-opacity: 1;
  color: rgba(24, 84, 66, var(--bs-text-opacity)) !important;
}
.bg-teal-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(24, 84, 66, var(--bs-bg-opacity)) !important;
}
.text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(99, 219, 183, var(--bs-text-opacity)) !important;
}
.bg-teal-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(99, 219, 183, var(--bs-bg-opacity)) !important;
}
.text-teal-900 {
  --bs-text-opacity: 1;
  color: rgba(12, 42, 33, var(--bs-text-opacity)) !important;
}
.bg-teal-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(12, 42, 33, var(--bs-bg-opacity)) !important;
}
.bg-gradient-cyan {
  background-color: #30beff !important;
  background-image: linear-gradient(to bottom, #63ceff, #17b6ff) !important;
}
:root .theme-cyan {
  --bs-theme: #30beff !important;
  --bs-theme-rgb: 48, 190, 255 !important;
  --bs-border-color: #30beff !important;
}
.text-cyan-100 {
  --bs-text-opacity: 1;
  color: rgba(214, 242, 255, var(--bs-text-opacity)) !important;
}
.bg-cyan-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(214, 242, 255, var(--bs-bg-opacity)) !important;
}
.text-cyan-600 {
  --bs-text-opacity: 1;
  color: rgba(38, 152, 204, var(--bs-text-opacity)) !important;
}
.bg-cyan-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(38, 152, 204, var(--bs-bg-opacity)) !important;
}
.text-cyan-200 {
  --bs-text-opacity: 1;
  color: rgba(172, 229, 255, var(--bs-text-opacity)) !important;
}
.bg-cyan-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(172, 229, 255, var(--bs-bg-opacity)) !important;
}
.text-cyan-700 {
  --bs-text-opacity: 1;
  color: rgba(29, 114, 153, var(--bs-text-opacity)) !important;
}
.bg-cyan-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(29, 114, 153, var(--bs-bg-opacity)) !important;
}
.text-cyan-300 {
  --bs-text-opacity: 1;
  color: rgba(131, 216, 255, var(--bs-text-opacity)) !important;
}
.bg-cyan-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(131, 216, 255, var(--bs-bg-opacity)) !important;
}
.text-cyan-800 {
  --bs-text-opacity: 1;
  color: rgba(19, 76, 102, var(--bs-text-opacity)) !important;
}
.bg-cyan-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(19, 76, 102, var(--bs-bg-opacity)) !important;
}
.text-cyan-400 {
  --bs-text-opacity: 1;
  color: rgba(89, 203, 255, var(--bs-text-opacity)) !important;
}
.bg-cyan-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(89, 203, 255, var(--bs-bg-opacity)) !important;
}
.text-cyan-900 {
  --bs-text-opacity: 1;
  color: rgba(10, 38, 51, var(--bs-text-opacity)) !important;
}
.bg-cyan-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(10, 38, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-white {
  background-color: #fff !important;
  background-image: linear-gradient(to bottom, #fff, #f2f2f2) !important;
}
:root .theme-white {
  --bs-theme: #ffffff !important;
  --bs-theme-rgb: 255, 255, 255 !important;
  --bs-border-color: #ffffff !important;
}
.text-white-100 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-600 {
  --bs-text-opacity: 1;
  color: rgba(204, 204, 204, var(--bs-text-opacity)) !important;
}
.bg-white-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--bs-bg-opacity)) !important;
}
.text-white-200 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-700 {
  --bs-text-opacity: 1;
  color: rgba(153, 153, 153, var(--bs-text-opacity)) !important;
}
.bg-white-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(153, 153, 153, var(--bs-bg-opacity)) !important;
}
.text-white-300 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-800 {
  --bs-text-opacity: 1;
  color: rgba(102, 102, 102, var(--bs-text-opacity)) !important;
}
.bg-white-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(102, 102, 102, var(--bs-bg-opacity)) !important;
}
.text-white-400 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, var(--bs-text-opacity)) !important;
}
.bg-white-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
}
.text-white-900 {
  --bs-text-opacity: 1;
  color: rgba(51, 51, 51, var(--bs-text-opacity)) !important;
}
.bg-white-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray {
  background-color: #526872 !important;
  background-image: linear-gradient(to bottom, #678390, #475a63) !important;
}
:root .theme-gray {
  --bs-theme: #526872 !important;
  --bs-theme-rgb: 82, 104, 114 !important;
  --bs-border-color: #526872 !important;
}
.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(220, 225, 227, var(--bs-text-opacity)) !important;
}
.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(220, 225, 227, var(--bs-bg-opacity)) !important;
}
.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(66, 83, 91, var(--bs-text-opacity)) !important;
}
.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(66, 83, 91, var(--bs-bg-opacity)) !important;
}
.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(186, 195, 199, var(--bs-text-opacity)) !important;
}
.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(186, 195, 199, var(--bs-bg-opacity)) !important;
}
.text-gray-700 {
  --bs-text-opacity: 1;
  color: rgba(49, 62, 68, var(--bs-text-opacity)) !important;
}
.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(49, 62, 68, var(--bs-bg-opacity)) !important;
}
.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(151, 164, 170, var(--bs-text-opacity)) !important;
}
.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(151, 164, 170, var(--bs-bg-opacity)) !important;
}
.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(33, 42, 46, var(--bs-text-opacity)) !important;
}
.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(33, 42, 46, var(--bs-bg-opacity)) !important;
}
.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(117, 134, 142, var(--bs-text-opacity)) !important;
}
.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(117, 134, 142, var(--bs-bg-opacity)) !important;
}
.text-gray-900 {
  --bs-text-opacity: 1;
  color: rgba(16, 21, 23, var(--bs-text-opacity)) !important;
}
.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(16, 21, 23, var(--bs-bg-opacity)) !important;
}
.bg-gradient-gray-dark {
  background-color: #35434a !important;
  background-image: linear-gradient(to bottom, #4a5e68, #2a363b) !important;
}
:root .theme-gray-dark {
  --bs-theme: #35434a !important;
  --bs-theme-rgb: 53, 67, 74 !important;
  --bs-border-color: #35434a !important;
}
.text-gray-dark-100 {
  --bs-text-opacity: 1;
  color: rgba(215, 217, 219, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(215, 217, 219, var(--bs-bg-opacity)) !important;
}
.text-gray-dark-600 {
  --bs-text-opacity: 1;
  color: rgba(42, 54, 59, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(42, 54, 59, var(--bs-bg-opacity)) !important;
}
.text-gray-dark-200 {
  --bs-text-opacity: 1;
  color: rgba(174, 180, 183, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(174, 180, 183, var(--bs-bg-opacity)) !important;
}
.text-gray-dark-700 {
  --bs-text-opacity: 1;
  color: rgba(32, 40, 44, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(32, 40, 44, var(--bs-bg-opacity)) !important;
}
.text-gray-dark-300 {
  --bs-text-opacity: 1;
  color: rgba(134, 142, 146, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(134, 142, 146, var(--bs-bg-opacity)) !important;
}
.text-gray-dark-800 {
  --bs-text-opacity: 1;
  color: rgba(21, 27, 30, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(21, 27, 30, var(--bs-bg-opacity)) !important;
}
.text-gray-dark-400 {
  --bs-text-opacity: 1;
  color: rgba(93, 105, 110, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(93, 105, 110, var(--bs-bg-opacity)) !important;
}
.text-gray-dark-900 {
  --bs-text-opacity: 1;
  color: rgba(11, 13, 15, var(--bs-text-opacity)) !important;
}
.bg-gray-dark-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(11, 13, 15, var(--bs-bg-opacity)) !important;
}
.bg-none {
  background: 0 0 !important;
}
.bg-position-center {
  background-position: center !important;
}
.bg-size-cover {
  background-size: cover !important;
}
.bg-repeat-no-repeat {
  background-repeat: no-repeat !important;
}
.bg-gradient-orange-red {
  background: #ffb33f;
}
.bg-gradient-orange-red {
  background: linear-gradient(to top, #ffb33f, #ff9393) !important;
}
.bg-gradient-red-pink {
  background: #ff9393;
}
.bg-gradient-red-pink {
  background: linear-gradient(to top, #ff9393, #ed60ab) !important;
}
.bg-gradient-yellow-orange {
  background: #faed3f;
}
.bg-gradient-yellow-orange {
  background: linear-gradient(to top, #faed3f, #ffb33f) !important;
}
.bg-gradient-yellow-red {
  background: #faed3f;
}
.bg-gradient-yellow-red {
  background: linear-gradient(to top, #faed3f, #ff9393) !important;
}
.bg-gradient-yellow-green {
  background: #faed3f;
}
.bg-gradient-yellow-green {
  background: linear-gradient(to top, #faed3f, #3be24f) !important;
}
.bg-gradient-cyan-blue {
  background: #30beff;
}
.bg-gradient-cyan-blue {
  background: linear-gradient(to top, #30beff, #4e9cff) !important;
}
.bg-gradient-cyan-indigo {
  background: #30beff;
}
.bg-gradient-cyan-indigo {
  background: linear-gradient(to top, #30beff, #bba4ff) !important;
}
.bg-gradient-custom-orange {
  background: #f6826d;
}
.bg-gradient-custom-orange {
  background: linear-gradient(to left, #f6826d, #f89e8e) !important;
}
.bg-gradient-custom-pink {
  background: #d95099;
}
.bg-gradient-custom-pink {
  background: linear-gradient(to left, #d95099, #e06daa) !important;
}
.bg-gradient-custom-teal {
  background: #31ac87;
}
.bg-gradient-custom-teal {
  background: linear-gradient(to left, #31ac87, #3cd2a5) !important;
}
.bg-gradient-custom-indigo {
  background: #e3dbfa;
}
.bg-gradient-custom-indigo {
  background: linear-gradient(to left, #e3dbfa, #fff) !important;
}
.bg-gradient-custom-blue {
  background: #4e9cff;
}
.bg-gradient-custom-blue {
  background: linear-gradient(to left, #4e9cff, #81b9ff) !important;
}
.bg-gradient-indigo-teal {
  background-image: linear-gradient(
    to left,
    #bba4ff,
    #4e9cff,
    #3cd2a5
  ) !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.text-opacity-0 {
  --bs-text-opacity: 0;
}
.text-opacity-5 {
  --bs-text-opacity: 0.05;
}
.bg-opacity-0 {
  --bs-bg-opacity: 0;
}
.bg-opacity-5 {
  --bs-bg-opacity: 0.05;
}
.opacity-1 {
  opacity: 0.1 !important;
}
.opacity-15 {
  opacity: 0.15 !important;
}
.text-opacity-10 {
  --bs-text-opacity: 0.1;
}
.text-opacity-15 {
  --bs-text-opacity: 0.15;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-15 {
  --bs-bg-opacity: 0.15;
}
.opacity-2 {
  opacity: 0.2 !important;
}
.opacity-30 {
  opacity: 0.3 !important;
}
.text-opacity-20 {
  --bs-text-opacity: 0.2;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.bg-opacity-20 {
  --bs-bg-opacity: 0.2;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.opacity-3 {
  opacity: 0.3 !important;
}
.opacity-45 {
  opacity: 0.45 !important;
}
.text-opacity-30 {
  --bs-text-opacity: 0.3;
}
.text-opacity-35 {
  --bs-text-opacity: 0.35;
}
.bg-opacity-30 {
  --bs-bg-opacity: 0.3;
}
.bg-opacity-35 {
  --bs-bg-opacity: 0.35;
}
.opacity-4 {
  opacity: 0.4 !important;
}
.opacity-60 {
  opacity: 0.6 !important;
}
.text-opacity-40 {
  --bs-text-opacity: 0.4;
}
.text-opacity-45 {
  --bs-text-opacity: 0.45;
}
.bg-opacity-40 {
  --bs-bg-opacity: 0.4;
}
.bg-opacity-45 {
  --bs-bg-opacity: 0.45;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-55 {
  --bs-text-opacity: 0.55;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-55 {
  --bs-bg-opacity: 0.55;
}
.opacity-6 {
  opacity: 0.6 !important;
}
.opacity-90 {
  opacity: 0.9 !important;
}
.text-opacity-60 {
  --bs-text-opacity: 0.6;
}
.text-opacity-65 {
  --bs-text-opacity: 0.65;
}
.bg-opacity-60 {
  --bs-bg-opacity: 0.6;
}
.bg-opacity-65 {
  --bs-bg-opacity: 0.65;
}
.opacity-7 {
  opacity: 0.7 !important;
}
.opacity-105 {
  opacity: 1.05 !important;
}
.text-opacity-70 {
  --bs-text-opacity: 0.7;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.bg-opacity-70 {
  --bs-bg-opacity: 0.7;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.opacity-8 {
  opacity: 0.8 !important;
}
.opacity-120 {
  opacity: 1.2 !important;
}
.text-opacity-80 {
  --bs-text-opacity: 0.8;
}
.text-opacity-85 {
  --bs-text-opacity: 0.85;
}
.bg-opacity-80 {
  --bs-bg-opacity: 0.8;
}
.bg-opacity-85 {
  --bs-bg-opacity: 0.85;
}
.opacity-9 {
  opacity: 0.9 !important;
}
.opacity-135 {
  opacity: 1.35 !important;
}
.text-opacity-90 {
  --bs-text-opacity: 0.9;
}
.text-opacity-95 {
  --bs-text-opacity: 0.95;
}
.bg-opacity-90 {
  --bs-bg-opacity: 0.9;
}
.bg-opacity-95 {
  --bs-bg-opacity: 0.95;
}
.opacity-10 {
  opacity: 1 !important;
}
.opacity-150 {
  opacity: 1.5 !important;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.text-opacity-105 {
  --bs-text-opacity: 1.05;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-opacity-105 {
  --bs-bg-opacity: 1.05;
}
@media (min-width: 1200px) {
  .desktop-sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 4.5rem;
  }
}
.outline-none {
  outline: 0 !important;
}
.bg-cover-2:after {
  background-image: url(/static/media/cover-2.a29f86ae.jpg);
}
.bg-cover-none:after {
  background-color: #273136;
  background-color: var(--bs-body-bg);
}
.bg-cover-3:after {
  /* background-image: url(images/cover-3.jpg); */
}
.bg-cover-none:after {
  background-color: #273136;
  background-color: var(--bs-body-bg);
}
.bg-cover-4:after {
  /* background-image: url(images/cover-4.jpg); */
}
.bg-cover-none:after {
  background-color: #273136;
  background-color: var(--bs-body-bg);
}
.bg-cover-5:after {
  /* background-image: url(images/cover-5.jpg); */
}
.bg-cover-none:after {
  background-color: #273136;
  background-color: var(--bs-body-bg);
}
.bg-cover-6:after {
  /* background-image: url(images/cover-6.jpg); */
}
.bg-cover-none:after {
  background-color: #273136;
  background-color: var(--bs-body-bg);
}
.flex-1 {
  flex: 1 1 !important;
}
.form-check-input:checked,
.list-group-item.active,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.page-item.active .page-link {
  background-color: #000;
  background-color: var(--bs-theme);
  border-color: #000;
  border-color: var(--bs-theme);
}
.btn-theme {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
  position: relative;
}
.btn-theme:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn-theme:before {
    transition: none;
  }
}
.btn-theme.disabled,
.btn-theme:disabled {
  background-color: #000;
  background-color: var(--bs-theme);
  border-color: #000;
  border-color: var(--bs-theme);
}
.btn-theme.active,
.btn-theme:active,
.btn-theme:focus,
.btn-theme:hover {
  background-color: #000;
  background-color: var(--bs-theme);
  border-color: #000;
  border-color: var(--bs-theme);
}
.btn-theme.active:before,
.btn-theme:active:before,
.btn-theme:focus:before,
.btn-theme:hover:before {
  background: rgba(255, 255, 255, 0.2);
}
.btn-outline-theme {
  color: #000;
  color: var(--bs-theme);
  border-color: #000;
  border-color: var(--bs-theme);
}
.btn-outline-theme.active,
.btn-outline-theme:hover {
  background-color: #000;
  background-color: var(--bs-theme);
  border-color: #000;
  border-color: var(--bs-theme);
}
.btn-outline-theme.disabled,
.btn-outline-theme:disabled {
  color: #000;
  color: var(--bs-theme);
}
.nav-link {
  color: #000;
  color: var(--bs-theme);
}
.nav-link:hover {
  color: rgba(232, 50, 147, 0.5);
  color: rgba(var(--bs-theme-rgb), 0.5);
}
html {
  height: 100%;
  background-color: #273136;
  background-image: linear-gradient(
    180deg,
    rgba(50, 70, 80, 0.9) 0,
    #0d101b 100%
  );
}
html:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -5;
  /* background: linear-gradient(180deg, rgba(50, 70, 80, 0.9) 0, #0d101b 100%); */
}
html:after {
  content: "";
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  /* z-index: -10; */
  /* background-color: #273136; */
  /* background-image: linear-gradient(
    180deg,
    rgba(50, 70, 80, 0.9) 0,
    #0d101b 100%
  ); */
  background-image: linear-gradient(#1011112b,#00000000),url(/static/media/LogoBG.d56c734a.jpeg) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-attachment: initial;
  height: 100%;
  /* transition: background 0.2s linear; */
  background-size: cover;
}
body {
  background: 0 0;
  font-weight: 300;
  letter-spacing: 0.046875rem;
  position: relative;
  min-height: 100vh;
  z-index: 9999;
}
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-attachment: scroll;
  background-attachment: initial;
  background-color: transparent;
  /* background-image: url(images/pattern.png); */
  background-size: 4.6875rem;
  background-repeat: repeat;
  background-attachment: initial;
  z-index: -5;
}
.app-content {
  padding: 2rem 2rem;
  flex: 1 1;
  margin-left: 16.875rem;
}
@media (max-width: 767.98px) {
  .app-content {
    padding: 1rem 1rem;
    margin-left: 0;
  }
}
.app-content .app-content-inner-padding {
  padding: 2rem 2rem;
}
@media (max-width: 767.98px) {
  .app-content .app-content-inner-padding {
    padding: 1rem 1rem;
  }
}
.app-content .app-content-inner-padding-x {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 767.98px) {
  .app-content .app-content-inner-padding-x {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.app-content .app-content-inner-padding-y {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (max-width: 767.98px) {
  .app-content .app-content-inner-padding-y {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.app-footer {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.25rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 18.875rem;
  margin-right: 2rem;
}
@media (max-width: 767.98px) {
  .app-footer {
    padding: 1rem 0;
    margin: 0 1rem;
  }
}
.app-header {
  height: 3.25rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  background: rgba(29, 40, 53, 0.95);
  display: flex;
  transition: all 0.15s ease-in-out;
}
.app-header .menu-toggler {
  border: none;
  background: 0 0;
  height: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-header .menu-toggler .bar {
  width: 1.625rem;
  display: block;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
  transition: all 0.2s linear;
}
.app-header .menu-toggler .bar + .bar {
  margin-top: 0.25rem;
}
.app-header .menu-toggler:hover .bar {
  background: rgba(255, 255, 255, 0.75);
}
.app-header .mobile-toggler {
  height: 100%;
  display: none;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .app-header .mobile-toggler {
    display: flex;
    align-items: center;
  }
}
.app-header .desktop-toggler {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .app-header .desktop-toggler {
    display: none;
  }
}
.app-header .brand {
  height: 100%;
  width: 18.875rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .app-header .brand {
    display: none;
  }
}
.app-header .brand .brand-logo {
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  font-size: 0.91875rem;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .app-header .brand .brand-logo {
    display: none;
  }
}
.app-header .brand .brand-logo img {
  max-width: 100%;
  max-height: 100%;
  margin-right: 1rem;
}
.app-header .brand .brand-logo .brand-img {
  width: 1.625rem;
  height: 1.625rem;
  background: url(/static/media/LogoT.47b4eaea.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.app-header .brand .brand-logo .brand-img .brand-img-text {
  display: block;
  letter-spacing: normal;
  letter-spacing: initial;
  font-weight: 600;
  font-size: 1.05rem;
}
.app-header .menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1;
}
.app-header .menu .menu-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.app-header .menu .menu-item .menu-link {
  text-decoration: none;
  color: #fff;
  position: relative;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}
.app-header .menu .menu-item .menu-link .menu-img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
}
.app-header .menu .menu-item .menu-link .menu-img img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 30px;
}
.app-header .menu .menu-item .menu-link .menu-icon {
  font-size: 1.25rem;
}
.app-header .menu .menu-item .menu-link .menu-badge {
  position: absolute;
  background: 0 0;
  width: 6px;
  height: 6px;
  top: 0.5rem;
  border-radius: 6px;
  right: 1rem;
}
.app-header .menu-search {
  position: absolute;
  top: -3.25rem;
  left: 0;
  right: 0;
  height: 3.25rem;
  background: rgba(29, 40, 53, 0.95);
  transition: all 0.2s ease-in-out;
}
.app-header .menu-search .menu-search-container {
  max-width: 60.4375rem;
  margin: 0 auto;
  height: 3.25rem;
  display: flex;
}
.app-header .menu-search .menu-search-container .menu-search-icon {
  padding: 0 1rem;
  font-size: 1.05rem;
  display: flex;
  align-items: center;
}
.app-header .menu-search .menu-search-container .menu-search-icon .bi:before {
  display: block;
}
.app-header .menu-search .menu-search-container .menu-search-icon a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  display: block;
  transition: all 0.2s linear;
}
.app-header .menu-search .menu-search-container .menu-search-icon a:hover {
  color: #fff;
}
.app-header .menu-search .menu-search-container .menu-search-input {
  flex: 1 1;
}
.app-header
  .menu-search
  .menu-search-container
  .menu-search-input
  .form-control {
  border: none;
  padding-left: 0;
  height: 100%;
  box-shadow: none;
}
.app-header-menu-search-toggled .app-header .menu-search {
  top: 0;
}
.app-sidebar-mobile-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1020;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  outline: 0;
  display: none;
  background: rgba(0, 0, 0, 0.25);
}
@-webkit-keyframes appSidebarSubMenuSlideInRight {
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes appSidebarSubMenuSlideInRight {
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.app-sidebar {
  width: 16.875rem;
  bottom: 0;
  position: fixed;
  top: 3.25rem;
  z-index: 1010;
  left: 0;
}
@media (max-width: 767.98px) {
  .app-sidebar {
    top: 0;
    z-index: 1030;
    background: rgba(38, 51, 66, 0.95);
    left: -16.875rem;
  }
}
.app-sidebar .slimScrollDiv {
  width: 100% !important;
}
.app-sidebar .slimScrollDiv .slimScrollBar,
.app-sidebar .slimScrollDiv .slimScrollRail {
  display: none !important;
}
.app-sidebar .app-sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-sidebar .app-sidebar-content .menu {
  position: relative;
}
.app-sidebar .app-sidebar-content .menu .menu-header {
  padding: 1rem 1rem 0.5rem;
  font-size: 0.65625rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.3);
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link {
  text-decoration: none;
  position: relative;
  padding: 0.3rem 1rem;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link .menu-icon {
  width: 1.875rem;
  height: 1.875rem;
  font-size: 1.05rem;
  margin: -0.25rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link .menu-caret {
  margin: -0.3125rem 0;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link .menu-text {
  flex: 1 1;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link:focus,
.app-sidebar .app-sidebar-content .menu .menu-item .menu-link:hover {
  color: #fff;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-submenu {
  background: 0 0;
  display: none;
  padding: 0 0 1rem;
}
.app-sidebar .app-sidebar-content .menu .menu-item .menu-submenu .menu-item {
  position: relative;
  padding-left: 2.625rem;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item.active:not(.has-sub)
  > .menu-link {
  color: #fff;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item.active
  > .menu-link
  .menu-icon {
  color: #000;
  color: var(--bs-theme);
}
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item.active.has-sub
  > .menu-submenu {
  display: block;
}
.app-sidebar .app-sidebar-content .menu .menu-item.expand > .menu-submenu {
  display: block;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item.expand
  > .menu-submenu
  > .menu-item {
  -webkit-animation: appSidebarSubMenuSlideInRight 0.3s both
    cubic-bezier(0.7, 0, 0.3, 1);
  animation: appSidebarSubMenuSlideInRight 0.3s both
    cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(2),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(2) {
  -webkit-animation-delay: 45ms;
  animation-delay: 45ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(3),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(3) {
  -webkit-animation-delay: 60ms;
  animation-delay: 60ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(4),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(4) {
  -webkit-animation-delay: 75ms;
  animation-delay: 75ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(5),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(5) {
  -webkit-animation-delay: 90ms;
  animation-delay: 90ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(6),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(6) {
  -webkit-animation-delay: 105ms;
  animation-delay: 105ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(7),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(7) {
  -webkit-animation-delay: 120ms;
  animation-delay: 120ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(8),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(8) {
  -webkit-animation-delay: 135ms;
  animation-delay: 135ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(9),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(9) {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(10),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(10) {
  -webkit-animation-delay: 165ms;
  animation-delay: 165ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(11),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(11) {
  -webkit-animation-delay: 180ms;
  animation-delay: 180ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(12),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(12) {
  -webkit-animation-delay: 195ms;
  animation-delay: 195ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(13),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(13) {
  -webkit-animation-delay: 210ms;
  animation-delay: 210ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(14),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(14) {
  -webkit-animation-delay: 225ms;
  animation-delay: 225ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(15),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(15) {
  -webkit-animation-delay: 240ms;
  animation-delay: 240ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(16),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(16) {
  -webkit-animation-delay: 255ms;
  animation-delay: 255ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(17),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(17) {
  -webkit-animation-delay: 270ms;
  animation-delay: 270ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(18),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(18) {
  -webkit-animation-delay: 285ms;
  animation-delay: 285ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(19),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(19) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(20),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(20) {
  -webkit-animation-delay: 315ms;
  animation-delay: 315ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(21),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(21) {
  -webkit-animation-delay: 330ms;
  animation-delay: 330ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(22),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(22) {
  -webkit-animation-delay: 345ms;
  animation-delay: 345ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(23),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(23) {
  -webkit-animation-delay: 360ms;
  animation-delay: 360ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(24),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(24) {
  -webkit-animation-delay: 375ms;
  animation-delay: 375ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(25),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(25) {
  -webkit-animation-delay: 390ms;
  animation-delay: 390ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(26),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(26) {
  -webkit-animation-delay: 405ms;
  animation-delay: 405ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(27),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(27) {
  -webkit-animation-delay: 420ms;
  animation-delay: 420ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(28),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(28) {
  -webkit-animation-delay: 435ms;
  animation-delay: 435ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(29),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(29) {
  -webkit-animation-delay: 450ms;
  animation-delay: 450ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(30),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(30) {
  -webkit-animation-delay: 465ms;
  animation-delay: 465ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(31),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(31) {
  -webkit-animation-delay: 480ms;
  animation-delay: 480ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(32),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(32) {
  -webkit-animation-delay: 495ms;
  animation-delay: 495ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(33),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(33) {
  -webkit-animation-delay: 510ms;
  animation-delay: 510ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(34),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(34) {
  -webkit-animation-delay: 525ms;
  animation-delay: 525ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(35),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(35) {
  -webkit-animation-delay: 540ms;
  animation-delay: 540ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(36),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(36) {
  -webkit-animation-delay: 555ms;
  animation-delay: 555ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(37),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(37) {
  -webkit-animation-delay: 570ms;
  animation-delay: 570ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(38),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(38) {
  -webkit-animation-delay: 585ms;
  animation-delay: 585ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(39),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(39) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(40),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(40) {
  -webkit-animation-delay: 615ms;
  animation-delay: 615ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(41),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(41) {
  -webkit-animation-delay: 630ms;
  animation-delay: 630ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(42),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(42) {
  -webkit-animation-delay: 645ms;
  animation-delay: 645ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(43),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(43) {
  -webkit-animation-delay: 660ms;
  animation-delay: 660ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(44),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(44) {
  -webkit-animation-delay: 675ms;
  animation-delay: 675ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(45),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(45) {
  -webkit-animation-delay: 690ms;
  animation-delay: 690ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(46),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(46) {
  -webkit-animation-delay: 705ms;
  animation-delay: 705ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(47),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(47) {
  -webkit-animation-delay: 720ms;
  animation-delay: 720ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(48),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(48) {
  -webkit-animation-delay: 735ms;
  animation-delay: 735ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(49),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(49) {
  -webkit-animation-delay: 750ms;
  animation-delay: 750ms;
}
.app-sidebar
  .app-sidebar-content
  .menu
  .collapse.expand
  > .menu-item:nth-child(50),
.app-sidebar
  .app-sidebar-content
  .menu
  .menu-item
  > .menu-submenu
  > .menu-item:nth-child(50) {
  -webkit-animation-delay: 765ms;
  animation-delay: 765ms;
}
.app-sidebar .app-sidebar-mobile-backdrop {
  display: none;
}
.app-sidebar.uppercase-mode {
  text-transform: uppercase;
}
.app-sidebar.uppercase-mode .app-sidebar-content .menu .menu-item .menu-link {
  font-size: 0.83125rem;
}
.app-theme-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
  width: 0;
  display: flex;
  align-items: center;
  right: -12rem;
  transition: right 0.2s ease-in-out;
}
.app-theme-panel .app-theme-panel-container {
  width: 12rem;
  position: absolute;
  right: 0;
}
.app-theme-panel .app-theme-panel-content {
  width: 12rem;
  background: rgba(68, 85, 94, 0.95);
  padding: 0.5rem;
  z-index: 10;
}
.app-theme-panel .app-theme-panel-content .app-theme-list {
  margin: -0.25rem;
  display: flex;
  flex-wrap: wrap;
}
.app-theme-panel .app-theme-panel-content .app-theme-list .app-theme-list-item {
  padding: 0.25rem;
  width: 25%;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-list
  .app-theme-list-item
  .app-theme-list-link {
  height: 2rem;
  display: block;
  text-decoration: none;
  position: relative;
  transition: all 0.2s linear;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-list
  .app-theme-list-item
  .app-theme-list-link:focus,
.app-theme-panel
  .app-theme-panel-content
  .app-theme-list
  .app-theme-list-item
  .app-theme-list-link:hover {
  opacity: 0.6;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-list
  .app-theme-list-item.active
  .app-theme-list-link:before {
  content: "\f00c";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
.app-theme-panel .app-theme-panel-content .app-theme-cover {
  margin: -0.25rem;
  display: flex;
  flex-wrap: wrap;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-cover
  .app-theme-cover-item {
  padding: 0.25rem;
  width: 33.33%;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-cover
  .app-theme-cover-item
  .app-theme-cover-link {
  display: block;
  text-decoration: none;
  position: relative;
  padding-top: 46%;
  background-size: cover;
  background-position: center;
  transition: all 0.2s linear;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-cover
  .app-theme-cover-item
  .app-theme-cover-link:focus,
.app-theme-panel
  .app-theme-panel-content
  .app-theme-cover
  .app-theme-cover-item
  .app-theme-cover-link:hover {
  opacity: 0.6;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-cover
  .app-theme-cover-item
  .app-theme-cover-link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(50, 70, 80, 0.9) 0, #0d101b 100%);
  opacity: 0.5;
}
.app-theme-panel
  .app-theme-panel-content
  .app-theme-cover
  .app-theme-cover-item.active
  .app-theme-cover-link:after {
  content: "\f00c";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
.app-theme-panel .app-theme-toggle-btn {
  position: absolute;
  top: 50%;
  margin-top: -1rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.09375rem;
  color: #fff;
  background: rgba(68, 85, 94, 0.95);
  text-align: center;
  left: 0;
  margin-left: -2rem;
}
.app-theme-panel.active {
  right: 0;
}
.app {
  padding-top: 3.25rem;
}
@media (min-width: 992px) {
  .app-boxed-layout {
    min-height: 100vh;
  }
  .app-boxed-layout,
  .app-boxed-layout .app-header {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
  }
  .app-boxed-layout .app-sidebar {
    left: auto;
  }
}
.app-content-full-height .app-content {
  height: calc(100vh - 3.25rem);
}
.app-content-full-height.app-without-header .app-content {
  height: 100vh;
}
.app-content-full-width .app-content {
  margin: 0;
}
.app-content-full-width .app-footer {
  margin-left: 2rem;
}
@media (max-width: 767.98px) {
  .app-content-full-width .app-footer {
    margin-left: 1rem;
  }
}
.app-footer-fixed {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.app-full-height {
  min-height: 100vh;
}
@-webkit-keyframes appSidebarCollapse {
  from {
    margin-left: 0;
    opacity: 1;
  }
  to {
    margin-left: -16.875rem;
    opacity: 0;
  }
}
@keyframes appSidebarCollapse {
  from {
    margin-left: 0;
    opacity: 1;
  }
  to {
    margin-left: -16.875rem;
    opacity: 0;
  }
}
@-webkit-keyframes appContentCollapse {
  from {
    margin-left: 16.875rem;
  }
  to {
    margin-left: 0;
  }
}
@keyframes appContentCollapse {
  from {
    margin-left: 16.875rem;
  }
  to {
    margin-left: 0;
  }
}
@-webkit-keyframes appFooterCollapse {
  from {
    margin-left: 16.875rem;
  }
  to {
    margin-left: 2rem;
  }
}
@keyframes appFooterCollapse {
  from {
    margin-left: 16.875rem;
  }
  to {
    margin-left: 2rem;
  }
}
@media (min-width: 768px) {
  .app-sidebar-collapsed .app-sidebar {
    opacity: 0;
    -webkit-animation: appSidebarCollapse 0.15s both
      cubic-bezier(0.7, 0, 0.3, 1);
    animation: appSidebarCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: -16.875rem;
  }
  .app-sidebar-collapsed .app-content {
    -webkit-animation: appContentCollapse 0.15s both
      cubic-bezier(0.7, 0, 0.3, 1);
    animation: appContentCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 0;
  }
  .app-sidebar-collapsed .app-footer {
    -webkit-animation: appFooterCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: appFooterCollapse 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 2rem;
  }
}
@-webkit-keyframes appSidebarMobileSlideInLeft {
  from {
    left: -16.875rem;
  }
  to {
    left: 0;
  }
}
@keyframes appSidebarMobileSlideInLeft {
  from {
    left: -16.875rem;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes appSidebarMobileBackdrop {
  from {
    background: 0 0;
  }
  to {
    background: rgba(0, 0, 0, 0.25);
  }
}
@keyframes appSidebarMobileBackdrop {
  from {
    background: 0 0;
  }
  to {
    background: rgba(0, 0, 0, 0.25);
  }
}
@media (max-width: 767.98px) {
  .app-sidebar-mobile-toggled .app-sidebar {
    -webkit-animation: appSidebarMobileSlideInLeft 0.1s ease;
    animation: appSidebarMobileSlideInLeft 0.1s ease;
    left: 0;
  }
  .app-sidebar-mobile-toggled
    .app-sidebar-mobile-backdrop:not(.app-sidebar-end) {
    display: block;
    background: rgba(0, 0, 0, 0.25);
    -webkit-animation: appSidebarMobileBackdrop 0.1s ease;
    animation: appSidebarMobileBackdrop 0.1s ease;
  }
}
@-webkit-keyframes appSidebarExpand {
  from {
    margin-left: -16.875rem;
  }
  to {
    margin-left: 0;
  }
}
@keyframes appSidebarExpand {
  from {
    margin-left: -16.875rem;
  }
  to {
    margin-left: 0;
  }
}
@-webkit-keyframes appContentExpand {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 16.875rem;
  }
}
@keyframes appContentExpand {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 16.875rem;
  }
}
@-webkit-keyframes appFooterExpand {
  from {
    margin-left: 2rem;
  }
  to {
    margin-left: 18.875rem;
  }
}
@keyframes appFooterExpand {
  from {
    margin-left: 2rem;
  }
  to {
    margin-left: 18.875rem;
  }
}
@media (min-width: 768px) {
  .app-sidebar-toggled .app-sidebar {
    -webkit-animation: appSidebarExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: appSidebarExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 0;
  }
  .app-sidebar-toggled .app-content {
    -webkit-animation: appContentExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: appContentExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 16.875rem;
  }
  .app-sidebar-toggled .app-footer {
    -webkit-animation: appFooterExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: appFooterExpand 0.15s both cubic-bezier(0.7, 0, 0.3, 1);
    margin-left: 18.875rem;
  }
}
.app-without-header {
  padding-top: 0;
}
.app-without-sidebar .app-content {
  margin: 0;
}
.breadcrumb {
  font-weight: 700;
}
.breadcrumb .breadcrumb-item a {
  text-decoration: none;
}
.breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
}
.btn-scroll-top {
  position: fixed;
  bottom: 1.875rem;
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(53, 67, 74, 0.75);
  color: #fff;
  text-align: center;
  text-decoration: none;
  z-index: 1020;
  border-radius: 36px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.875rem;
}
@media (max-width: 767.98px) {
  .btn-scroll-top {
    bottom: 1.25rem;
    z-index: 1020;
    right: 1.25rem;
  }
}
.btn-scroll-top:hover {
  background: #000;
  background: var(--bs-theme);
  color: #fff;
  text-decoration: none;
}
.card-expand .app-content {
  z-index: 1120;
  position: relative;
  height: calc(100vh - 3.25rem);
  overflow: hidden;
}
.card {
  --bs-border-color: #ffffff;
  position: relative;
  border: none;
}
.card.card-expand {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1120;
  border-radius: 0;
  margin: 0 !important;
  padding: 0.3125rem;
  background: rgba(29, 40, 53, 0.95);
}
.card.card-expand .card-body,
.card.card-expand .card-header {
  border-radius: 0;
}
.card.card-expand .card-body {
  flex: 1 0;
  overflow: scroll;
}
.card.card-expand:not(.border-0):before {
  top: 5px;
  bottom: 5px;
  left: 20px;
  right: 20px;
}
.card.card-expand:not(.border-0):after {
  top: 20px;
  bottom: 20px;
  left: 5px;
  right: 5px;
}
.card.card-expand .card-arrow {
  margin: 5px;
}
.card .card-header .card-header-tabs .nav-item .nav-link.active {
  border-bottom-color: transparent;
}
.card .card-body .nav-tabs .nav-item .nav-link.active {
  background: 0 0;
  border-bottom-color: none;
}
.card:after,
.card:before {
  content: "";
  position: absolute;
}
.card:not(.border-0):before {
  left: 15px;
  right: 15px;
  top: 0;
  bottom: 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  opacity: 0.6;
}
.card:not(.border-0):after {
  top: 15px;
  bottom: 15px;
  left: 0;
  right: 0;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  opacity: 0.6;
}
.card
  > :not(.card-arrow):not(.card-img-overlay):not(.card-img):not(.hljs-container) {
  position: relative;
  z-index: 10;
}
.card .card-arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card .card-arrow-bottom-left,
.card .card-arrow-bottom-right,
.card .card-arrow-top-left,
.card .card-arrow-top-right {
  width: 10px;
  height: 10px;
  position: absolute;
}
.card .card-arrow-bottom-left:before,
.card .card-arrow-bottom-right:before,
.card .card-arrow-top-left:before,
.card .card-arrow-top-right:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 8px;
  background: #000;
  opacity: 0.75;
}
.card .card-arrow-bottom-left:after,
.card .card-arrow-bottom-right:after,
.card .card-arrow-top-left:after,
.card .card-arrow-top-right:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background: #000;
  opacity: 0.75;
}
.card .card-arrow-top-left,
.card .card-arrow-top-right {
  top: 0;
}
.card .card-arrow-top-left:before,
.card .card-arrow-top-right:before {
  top: 2px;
}
.card .card-arrow-top-left:after,
.card .card-arrow-top-right:after {
  top: 0;
}
.card .card-arrow-bottom-left,
.card .card-arrow-bottom-right {
  bottom: 0;
}
.card .card-arrow-bottom-left:before,
.card .card-arrow-bottom-right:before {
  bottom: 2px;
}
.card .card-arrow-bottom-left:after,
.card .card-arrow-bottom-right:after {
  bottom: 0;
}
.card .card-arrow-bottom-left,
.card .card-arrow-top-left {
  left: 0;
}
.card .card-arrow-bottom-left:after,
.card .card-arrow-bottom-left:before,
.card .card-arrow-top-left:after,
.card .card-arrow-top-left:before {
  left: 0;
}
.card .card-arrow-bottom-right,
.card .card-arrow-top-right {
  right: 0;
}
.card .card-arrow-bottom-right:after,
.card .card-arrow-bottom-right:before,
.card .card-arrow-top-right:after,
.card .card-arrow-top-right:before {
  right: 0;
}
@media (max-width: 991.98px) {
  .card-columns {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media (max-width: 575.98px) {
  .card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}
.caret:before {
  content: "\f22c";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dropup .caret,
.dropup.caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}
@-webkit-keyframes appDropdownShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appDropdownShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
}
.dropdown-menu.dropdown-notification {
  min-width: 320px;
}
.dropdown-menu.dropdown-notification .dropdown-notification-item {
  padding: 10px 20px;
  text-decoration: none;
  color: #273136;
  border-top: 1px solid #a8b6bc;
  border-bottom: 1px solid #a8b6bc;
  display: flex;
  align-items: center;
}
.dropdown-menu.dropdown-notification .dropdown-notification-item:hover {
  background: rgba(255, 255, 255, 0.15);
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  .dropdown-notification-icon {
  width: 24px;
  font-size: 16px;
  text-align: center;
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  .dropdown-notification-icon
  + .dropdown-notification-info {
  padding-left: 15px;
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  .dropdown-notification-arrow {
  color: #90a2aa;
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  .dropdown-notification-info {
  flex: 1 1;
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  .dropdown-notification-info
  .title
  + .time {
  margin-top: 5px;
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  .dropdown-notification-info
  .time {
  font-size: 12px;
  color: #617a86;
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  .dropdown-notification-info
  + .dropdown-notification-arrow {
  padding-left: 20px;
}
.dropdown-menu.dropdown-notification
  .dropdown-notification-item
  + .dropdown-notification-item {
  margin-top: -1px;
}
.dropdown-menu.show {
  -webkit-animation: appDropdownShow 0.25s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: appDropdownShow 0.25s both cubic-bezier(0.7, 0, 0.3, 1);
}
@media (max-width: 575.98px) {
  .dropdown.dropdown-mobile-full {
    position: static;
    position: initial;
  }
  .dropdown.dropdown-mobile-full .dropdown-menu {
    left: 0.25rem !important;
    right: 0.25rem !important;
    width: auto !important;
  }
}
.form-select:not([multiple]) option {
  color: #000;
}
hr.hr-bg,
hr.hr-inverse,
hr.hr-inverse-transparent,
hr.hr-transparent {
  border: none;
  height: 1px;
}
hr.hr-transparent {
  background-color: rgba(0, 0, 0, 0.3);
}
hr.hr-inverse {
  background-color: #1a1a1a;
}
hr.hr-inverse-transparent {
  background-color: rgba(255, 255, 255, 0.3);
}
a {
  color: #000;
  color: var(--bs-theme);
}
a:hover {
  color: rgba(232, 50, 147, 0.75);
  color: rgba(var(--bs-theme-rgb), 0.75);
}
.modal .modal-content > * {
  position: relative;
}
.modal .modal-dialog.modal-full {
  max-width: 100%;
  width: 100%;
}
.modal .modal-dialog.modal-full .modal-content {
  border-radius: 0;
}
.modal.modal-cover .modal-dialog {
  min-height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  background: 0 0;
  border: none;
}
.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  padding: 1rem 0;
  color: rgba(255, 255, 255, 0.5);
}
.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link.active,
.nav.nav-tabs.nav-tabs-v2 > .nav-item > .nav-link:hover {
  color: #fff;
  border-bottom-color: #000;
  border-bottom-color: var(--bs-theme);
}
.nav.nav-tabs.nav-tabs-v2 > .nav-item.show > .nav-link {
  color: #fff;
  border-bottom-color: #000;
  border-bottom-color: var(--bs-theme);
}
@media (max-width: 767.98px) {
  .nav-wizards-container {
    overflow: scroll;
  }
  .nav-wizards-container .nav.nav-wizards-1,
  .nav-wizards-container .nav.nav-wizards-2,
  .nav-wizards-container .nav.nav-wizards-3 {
    min-width: 37.5rem;
  }
}
.nav.nav-wizards-1 .nav-item {
  padding: 0;
}
.nav.nav-wizards-1 .nav-item .nav-link {
  position: relative;
  color: rgba(255, 255, 255, 0.75);
}
.nav.nav-wizards-1 .nav-item .nav-link .nav-no {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  font-weight: 600;
  background: 0 0;
  border: 2px solid rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 10;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}
.nav.nav-wizards-1 .nav-item .nav-link .nav-text {
  text-align: center;
  margin-top: 0.3125rem;
  font-weight: 600;
  font-size: 0.8125rem;
}
.nav.nav-wizards-1 .nav-item .nav-link.active,
.nav.nav-wizards-1 .nav-item .nav-link.completed {
  color: #000;
  color: var(--bs-theme);
}
.nav.nav-wizards-1 .nav-item .nav-link.active .nav-no,
.nav.nav-wizards-1 .nav-item .nav-link.completed .nav-no {
  border-color: #000;
  border-color: var(--bs-theme);
  color: #000;
  color: var(--bs-theme);
}
.nav.nav-wizards-1 .nav-item .nav-link.active:after,
.nav.nav-wizards-1 .nav-item .nav-link.active:before,
.nav.nav-wizards-1 .nav-item .nav-link.completed:after,
.nav.nav-wizards-1 .nav-item .nav-link.completed:before {
  background: #000;
  background: var(--bs-theme);
}
.nav.nav-wizards-1 .nav-item .nav-link.active .nav-no {
  box-shadow: 0 0 0 5px rgba(#000, 0.2);
  box-shadow: 0 0 0 5px rgba(var(--bs-theme), 0.2);
}
.nav.nav-wizards-1 .nav-item .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.nav.nav-wizards-1 .nav-item .nav-link:before {
  content: "";
  position: absolute;
  top: 1.625rem;
  height: 0.125rem;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.2s linear;
}
.nav.nav-wizards-1 .nav-item .nav-link:before {
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
}
.nav.nav-wizards-1 .nav-item:first-child .nav-link:before {
  display: none;
}
.nav.nav-wizards-1 .nav-item:last-child .nav-link:after {
  display: none;
}
.nav.nav-wizards-2 .nav-item {
  padding: 0 1rem;
}
.nav.nav-wizards-2 .nav-item .nav-link {
  position: relative;
  color: rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.3);
  border-radius: 60px;
}
.nav.nav-wizards-2 .nav-item .nav-link .nav-text {
  text-align: center;
  font-weight: 600;
  font-size: 0.8125rem;
}
.nav.nav-wizards-2 .nav-item .nav-link:before {
  content: "";
  position: absolute;
  left: -2rem;
  right: 100%;
  height: 0.125rem;
  top: 50%;
  margin-top: -0.0625rem;
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 0.2s linear;
}
.nav.nav-wizards-2 .nav-item .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.nav.nav-wizards-2 .nav-item .nav-link.active,
.nav.nav-wizards-2 .nav-item .nav-link.completed {
  background: #000;
  background: var(--bs-theme);
  color: rgba(0, 0, 0, 0.85);
}
.nav.nav-wizards-2 .nav-item .nav-link.active:before,
.nav.nav-wizards-2 .nav-item .nav-link.completed:before {
  background: #000;
  background: var(--bs-theme);
}
.nav.nav-wizards-2 .nav-item .nav-link.active {
  box-shadow: 0 0 0 4px rgba(#000, 0.2);
  box-shadow: 0 0 0 4px rgba(var(--bs-theme), 0.2);
}
.nav.nav-wizards-2 .nav-item:first-child .nav-link:before {
  display: none;
}
.nav.nav-wizards-3 .nav-item {
  padding: 0;
}
.nav.nav-wizards-3 .nav-item .nav-link {
  position: relative;
  color: rgba(255, 255, 255, 0.75);
}
.nav.nav-wizards-3 .nav-item .nav-link .nav-dot {
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
  font-weight: 600;
  background: 0 0;
  border: 3px solid rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 10;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}
.nav.nav-wizards-3 .nav-item .nav-link .nav-title {
  margin-top: 0.625rem;
  font-weight: 600;
  text-align: center;
}
.nav.nav-wizards-3 .nav-item .nav-link .nav-text {
  text-align: center;
  font-size: 0.8125rem;
}
.nav.nav-wizards-3 .nav-item .nav-link.active,
.nav.nav-wizards-3 .nav-item .nav-link.completed {
  color: #000;
  color: var(--bs-theme);
}
.nav.nav-wizards-3 .nav-item .nav-link.active .nav-dot,
.nav.nav-wizards-3 .nav-item .nav-link.completed .nav-dot {
  border-color: #000;
  border-color: var(--bs-theme);
  color: #000;
  color: var(--bs-theme);
}
.nav.nav-wizards-3 .nav-item .nav-link.active:before,
.nav.nav-wizards-3 .nav-item .nav-link.completed:before {
  background: #000;
  background: var(--bs-theme);
}
.nav.nav-wizards-3 .nav-item .nav-link.active .nav-dot {
  box-shadow: 0 0 0 5px rgba(#000, 0.2);
  box-shadow: 0 0 0 5px rgba(var(--bs-theme), 0.2);
}
.nav.nav-wizards-3 .nav-item .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.nav.nav-wizards-3 .nav-item .nav-link:before {
  content: "";
  position: absolute;
  left: calc(-50% + 8px);
  top: 0.875rem;
  right: calc(50% + 8px);
  height: 0.1875rem;
  background: rgba(255, 255, 255, 0.3);
}
.nav.nav-wizards-3 .nav-item:first-child .nav-link:before {
  display: none;
}
.navbar.navbar-sticky {
  top: 5.25rem;
  position: -webkit-sticky;
  position: sticky;
  padding: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  margin-left: 1rem;
}
.navbar.navbar-sticky .nav {
  width: 100%;
  flex-direction: column;
}
.navbar.navbar-sticky .nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.25rem 1.5rem;
}
.navbar.navbar-sticky .nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar.navbar-sticky .nav .nav-link.active {
  color: #000;
  color: var(--bs-theme);
  font-weight: 600;
}
.page-header {
  color: #fff;
  padding: 0;
  border: none;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.75rem;
}
.page-header .small,
.page-header small {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}
.pagination .page-item .page-link {
  border-radius: 0.25rem;
}
.pagination .page-item + .page-item {
  margin-left: 0.3125rem;
}
pre {
  display: block;
  padding: 1rem;
  font-size: 0.875rem;
  color: #44555e;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #a8b6bc;
  border-radius: 4px;
}
.row.row-grid > [class*="col-"] + [class*="col-"] {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.row.row-grid + .row-grid {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.table.table-card tbody > tr td:first-child,
.table.table-card tbody > tr th:first-child,
.table.table-card thead > tr td:first-child,
.table.table-card thead > tr th:first-child {
  padding-left: 1rem;
}
.table.table-card tbody > tr td:last-child,
.table.table-card tbody > tr th:last-child,
.table.table-card thead > tr td:last-child,
.table.table-card thead > tr th:last-child {
  padding-right: 1rem;
}
.toasts-container {
  position: fixed;
  top: 3.25rem;
  padding-top: 1rem;
  width: 21.875rem;
  right: 0;
  padding-right: 1rem;
}
@media (max-width: 767.98px) {
  .toasts-container {
    width: 18.75rem;
  }
}
.toasts-container .toast {
  width: 100%;
}
.apexcharts-canvas .apexcharts-tooltip {
  font-size: 0.8125rem;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
}
.apexcharts-canvas .apexcharts-tooltip * {
  font-family: inherit !important;
}
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark,
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
}
.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-dark
  .apexcharts-tooltip-title,
.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background: rgba(39, 49, 54, 0.15);
  border-color: transparent;
  color: #000;
  text-transform: uppercase;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  font-family: inherit !important;
  font-weight: 600;
  padding: 0.35rem 0.75rem;
  margin: 0;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group {
  padding: 0.35rem 0.75rem;
}
.apexcharts-canvas
  .apexcharts-tooltip
  .apexcharts-tooltip-series-group
  .apexcharts-tooltip-marker {
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 0.25rem;
}
.apexcharts-canvas
  .apexcharts-tooltip
  .apexcharts-tooltip-series-group
  .apexcharts-tooltip-text
  .apexcharts-tooltip-y-group {
  padding: 0;
}
.apexcharts-canvas
  .apexcharts-tooltip
  .apexcharts-tooltip-series-group
  + .apexcharts-tooltip-series-group {
  padding-top: 0;
}
.apexcharts-canvas .apexcharts-xaxistooltip * {
  font-family: inherit !important;
}
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-dark,
.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light {
  color: #fff;
  background: #35434a;
  border-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-bottom:before,
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-bottom:after,
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-top:before,
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-top:before {
  border-top-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-dark.apexcharts-xaxistooltip-top:after,
.apexcharts-canvas
  .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-top:after {
  border-top-color: #35434a;
}
.apexcharts-canvas .apexcharts-yaxistooltip * {
  font-family: inherit !important;
}
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-dark,
.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light {
  color: #fff;
  background: #35434a;
  border-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-right:before,
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-right:before {
  border-right-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-right:after,
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-right:after {
  border-right-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-left:before,
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-left:before {
  border-left-color: #35434a;
}
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-dark.apexcharts-yaxistooltip-left:after,
.apexcharts-canvas
  .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-left:after {
  border-left-color: #35434a;
}
.apexcharts-canvas .apexcharts-legend-marker {
  width: 0.625rem !important;
  height: 0.625rem !important;
  margin-right: 0.3125rem;
}
.apexcharts-canvas .apexcharts-menu {
  background: rgba(29, 40, 53, 0.98);
  border: none;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
}
.apexcharts-canvas .apexcharts-menu .apexcharts-menu-item {
  color: rgba(255, 255, 255, 0.5);
}
.apexcharts-canvas .apexcharts-menu .apexcharts-menu-item:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}
.apexcharts-canvas.apexcharts-theme-light .apexcharts-menu-icon:hover svg,
.apexcharts-canvas.apexcharts-theme-light .apexcharts-reset-icon:hover svg,
.apexcharts-canvas.apexcharts-theme-light
  .apexcharts-selection-icon:not(.apexcharts-selected):hover
  svg,
.apexcharts-canvas.apexcharts-theme-light
  .apexcharts-zoom-icon:not(.apexcharts-selected):hover
  svg,
.apexcharts-canvas.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
.apexcharts-canvas.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg {
  fill: #fff;
}
.ui-menu.ui-autocomplete.search-bar-autocomplete {
  border: none;
  border-radius: 0;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
  font-family: inherit;
  z-index: 1030;
  padding: 0.3125rem 0;
  border-radius: 0.25rem;
}
.ui-menu.ui-autocomplete.search-bar-autocomplete
  .ui-menu-item
  .ui-menu-item-wrapper,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-menu-item a {
  padding: 0.5rem 0.9375rem;
  border-radius: 0;
  margin: 0;
  font-weight: 600;
}
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-active,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-focus,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-hover,
.ui-menu.ui-autocomplete.search-bar-autocomplete
  .ui-widget-content
  .ui-state-active,
.ui-menu.ui-autocomplete.search-bar-autocomplete
  .ui-widget-content
  .ui-state-focus,
.ui-menu.ui-autocomplete.search-bar-autocomplete
  .ui-widget-content
  .ui-state-hover,
.ui-menu.ui-autocomplete.search-bar-autocomplete
  .ui-widget-header
  .ui-state-active,
.ui-menu.ui-autocomplete.search-bar-autocomplete
  .ui-widget-header
  .ui-state-focus,
.ui-menu.ui-autocomplete.search-bar-autocomplete
  .ui-widget-header
  .ui-state-hover {
  border: none;
  background: #e6e6e6;
  color: #000;
}
.datepicker.datepicker-dropdown {
  width: 18.75rem;
  padding: 0.375rem 0.75rem 0.75rem;
  z-index: 1000 !important;
  border-radius: 0;
}
.datepicker.datepicker-dropdown:after,
.datepicker.datepicker-dropdown:before {
  display: none;
}
.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev {
  padding: 0.625rem;
  min-width: 2.5rem;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover {
  background: 0 0 !important;
  color: #000;
  color: var(--bs-theme);
}
.datepicker .next,
.datepicker .prev {
  overflow: hidden;
  text-indent: -100px;
  max-width: 1.875rem;
  padding: 0.3125rem 0;
  text-align: center;
}
.datepicker .next:before,
.datepicker .prev:before {
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  position: absolute;
  width: 1.875rem;
  text-indent: 0;
}
.datepicker .next:before {
  content: "\f061";
  right: 0;
}
.datepicker .prev:before {
  content: "\f060";
  left: 0;
}
.datepicker .datepicker-switch {
  font-weight: 600;
  font-size: 0.9625rem;
  color: #000;
  color: var(--bs-theme);
  width: 100% !important;
}
.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev {
  padding: 0.3125rem;
}
.datepicker .datepicker-days,
.datepicker .datepicker-months,
.datepicker .datepicker-years {
  position: relative;
}
.datepicker .datepicker-days table {
  width: 100%;
}
.datepicker .datepicker-days table thead tr th.dow {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.74375rem;
  font-weight: 600;
  border-radius: 0;
  padding: 0.3125rem 0;
}
.datepicker .datepicker-days table tbody tr td {
  padding: 0.5rem 0;
  border-radius: 0;
  font-weight: 600;
  font-size: 0.74375rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #90a2aa;
  width: 14%;
}
.datepicker .datepicker-days table tbody tr td.old {
  color: #35434a;
}
.datepicker .datepicker-days table tbody tr td.new {
  color: #617a86;
}
.datepicker .datepicker-days table tbody tr td.focused,
.datepicker .datepicker-days table tbody tr td.range,
.datepicker .datepicker-days table tbody tr td:hover {
  color: #000000;
  color: var(--bs-black);
  background: #000 !important;
  background: var(--bs-theme) !important;
}
.datepicker .datepicker-days table tbody tr td.active,
.datepicker .datepicker-days table tbody tr td.selected {
  background: #000 !important;
  background: var(--bs-theme) !important;
  border-color: #000 !important;
  border-color: var(--bs-theme) !important;
  color: #000000;
  color: var(--bs-black);
  text-shadow: none;
  position: relative;
}
.datepicker .datepicker-days table tbody tr td.active:after,
.datepicker .datepicker-days table tbody tr td.active:before,
.datepicker .datepicker-days table tbody tr td.selected:after,
.datepicker .datepicker-days table tbody tr td.selected:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: -1px;
  background: #000;
  background: var(--bs-theme);
  width: 1px;
  left: -1px;
}
.datepicker .datepicker-days table tbody tr td.active:after,
.datepicker .datepicker-days table tbody tr td.selected:after {
  bottom: auto;
  top: -1px;
  width: auto;
  height: 1px;
  right: -1px;
}
.datepicker .datepicker-centuries table tbody tr td,
.datepicker .datepicker-decades table tbody tr td,
.datepicker .datepicker-months table tbody tr td,
.datepicker .datepicker-years table tbody tr td {
  padding-top: 0.1875rem;
}
.datepicker .datepicker-centuries table tbody tr td span,
.datepicker .datepicker-decades table tbody tr td span,
.datepicker .datepicker-months table tbody tr td span,
.datepicker .datepicker-years table tbody tr td span {
  width: 25%;
  margin: 0;
  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.15);
  font-weight: 600;
  font-size: 0.7875rem;
  margin: 0 -1px -1px 0;
  color: #c0cacf;
}
.datepicker .datepicker-centuries table tbody tr td span.focused,
.datepicker .datepicker-centuries table tbody tr td span:hover,
.datepicker .datepicker-decades table tbody tr td span.focused,
.datepicker .datepicker-decades table tbody tr td span:hover,
.datepicker .datepicker-months table tbody tr td span.focused,
.datepicker .datepicker-months table tbody tr td span:hover,
.datepicker .datepicker-years table tbody tr td span.focused,
.datepicker .datepicker-years table tbody tr td span:hover {
  color: #000000 !important;
  color: var(--bs-black) !important;
  background: #000 !important;
  background: var(--bs-theme) !important;
}
.datepicker .datepicker-centuries table tbody tr td span.focused,
.datepicker .datepicker-decades table tbody tr td span.focused,
.datepicker .datepicker-months table tbody tr td span.focused,
.datepicker .datepicker-years table tbody tr td span.focused {
  color: #000;
  color: var(--bs-theme);
}
.datepicker .datepicker-centuries table tbody tr td span.active,
.datepicker .datepicker-decades table tbody tr td span.active,
.datepicker .datepicker-months table tbody tr td span.active,
.datepicker .datepicker-years table tbody tr td span.active {
  border-color: #000;
  border-color: var(--bs-theme);
  position: relative;
  background: #000 !important;
  background: var(--bs-theme) !important;
  color: #fff !important;
  text-shadow: none;
}
.input-daterange input.form-control:first-child {
  border-radius: 4px 0 0 4px;
}
.input-daterange input.form-control:last-child {
  border-radius: 0 4px 4px 0;
}
body .daterangepicker {
  z-index: 1030;
  border-color: rgba(255, 255, 255, 0.15);
  font-family: inherit;
  font-size: inherit;
  background: rgba(29, 40, 53, 0.98);
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
  width: auto;
}
body .daterangepicker:after,
body .daterangepicker:before {
  display: none;
}
body .daterangepicker:after {
  border-width: 0.4375rem;
  top: -0.4375rem;
  left: 0.625rem;
}
body .daterangepicker.opensright:after {
  right: 0.625rem;
  left: auto;
}
body .daterangepicker select.monthselect,
body .daterangepicker select.yearselect {
  font-size: 0.7875rem;
}
body .daterangepicker .drp-calendar {
  max-width: inherit;
  padding: 0.75rem;
}
body .daterangepicker .drp-calendar .calendar-table {
  background: 0 0;
  border: none;
  border-radius: 0;
}
body .daterangepicker .drp-calendar .calendar-table table tbody .week,
body .daterangepicker .drp-calendar .calendar-table table thead .week {
  font-size: 0.74375rem;
  color: #c0cacf;
  font-weight: 600;
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th {
  min-width: auto;
  width: auto;
  height: auto;
  line-height: 1;
  padding: 0.375rem 0.75rem;
  color: #fff;
  font-weight: 600;
  font-size: inherit;
  border: none;
}
body .daterangepicker .drp-calendar .calendar-table table thead tr th.next span,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.prev
  span {
  border-color: rgba(255, 255, 255, 0.5);
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.next:focus,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.next:hover,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.prev:focus,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.prev:hover {
  background: 0 0;
  color: #000;
  color: var(--bs-theme);
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.next:focus
  span,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.next:hover
  span,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.prev:focus
  span,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.prev:hover
  span {
  border-color: #000;
  border-color: var(--bs-theme);
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.month
  .monthselect,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.month
  .yearselect {
  border-color: rgba(255, 255, 255, 0.3);
  background: #fff;
  border-radius: 4px;
  transition: all 0.2s linear;
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.month
  .monthselect:focus,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr
  th.month
  .yearselect:focus {
  outline: 0;
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.3);
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  thead
  tr:last-child
  th {
  font-size: 0.74375rem;
  padding-bottom: 0.5rem;
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td {
  min-width: auto;
  width: auto;
  height: auto;
  line-height: 1.3;
  padding: 0.625rem 0.375rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.15);
  font-size: 0.7875rem;
  border-radius: 0 !important;
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  tbody
  tr
  td.available:focus,
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  tbody
  tr
  td.available:hover {
  background: #000;
  background: var(--bs-theme);
  border-color: #000;
  border-color: var(--bs-theme);
  color: #000;
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  tbody
  tr
  td.available.in-range {
  background: rgba(232, 50, 147, 0.75);
  background: rgba(var(--bs-theme-rgb), 0.75);
  border-color: rgba(232, 50, 147, 0.85);
  border-color: rgba(var(--bs-theme-rgb), 0.85);
  color: #000;
  border-radius: 0;
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  tbody
  tr
  td.available.active {
  background: #000;
  background: var(--bs-theme);
  border-color: #000;
  border-color: var(--bs-theme);
  color: #000;
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  tbody
  tr
  td.available.start-date {
  border-radius: 0;
}
body
  .daterangepicker
  .drp-calendar
  .calendar-table
  table
  tbody
  tr
  td.available.end-date {
  border-radius: 0;
}
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.off {
  background: 0 0;
  color: rgba(255, 255, 255, 0.25);
}
body .daterangepicker .drp-calendar.left {
  padding: 0.75rem !important;
}
body .daterangepicker .drp-calendar.left .calendar-table {
  padding: 0 !important;
}
body .daterangepicker .drp-calendar.right {
  padding: 0.75rem !important;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
@media (max-width: 575.98px) {
  body .daterangepicker .drp-calendar.right {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-left: none;
  }
}
body .daterangepicker .drp-buttons {
  padding: 0.75rem 0.75rem;
  border-color: rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.15);
  border: none;
}
body .daterangepicker .drp-buttons .btn.btn-primary {
  background: 0 0;
  color: #e83293;
  border-color: #e83293;
}
body .daterangepicker .drp-buttons .btn.btn-primary:hover {
  color: #e83293;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:focus + body .daterangepicker .drp-buttons .btn.btn-primary,
body .daterangepicker .drp-buttons .btn.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
.btn-check:active + body .daterangepicker .drp-buttons .btn.btn-primary,
.btn-check:checked + body .daterangepicker .drp-buttons .btn.btn-primary,
body .daterangepicker .drp-buttons .btn.btn-primary.active,
body .daterangepicker .drp-buttons .btn.btn-primary.dropdown-toggle.show,
body .daterangepicker .drp-buttons .btn.btn-primary:active {
  color: #000;
  background-color: #e83293;
  border-color: #e83293;
}
.btn-check:active + body .daterangepicker .drp-buttons .btn.btn-primary:focus,
.btn-check:checked + body .daterangepicker .drp-buttons .btn.btn-primary:focus,
body .daterangepicker .drp-buttons .btn.btn-primary.active:focus,
body .daterangepicker .drp-buttons .btn.btn-primary.dropdown-toggle.show:focus,
body .daterangepicker .drp-buttons .btn.btn-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 50, 147, 0.5);
}
body .daterangepicker .drp-buttons .btn.btn-primary.disabled,
body .daterangepicker .drp-buttons .btn.btn-primary:disabled {
  color: #e83293;
  background-color: transparent;
}
body .daterangepicker .drp-buttons .btn.btn-primary:active,
body .daterangepicker .drp-buttons .btn.btn-primary:hover {
  color: #000;
}
body .daterangepicker .drp-buttons .btn.btn-default {
  background: 0 0;
  color: #a8b6bc;
  border-color: #a8b6bc;
}
body .daterangepicker .drp-buttons .btn.btn-default:hover {
  color: #a8b6bc;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:focus + body .daterangepicker .drp-buttons .btn.btn-default,
body .daterangepicker .drp-buttons .btn.btn-default:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-check:active + body .daterangepicker .drp-buttons .btn.btn-default,
.btn-check:checked + body .daterangepicker .drp-buttons .btn.btn-default,
body .daterangepicker .drp-buttons .btn.btn-default.active,
body .daterangepicker .drp-buttons .btn.btn-default.dropdown-toggle.show,
body .daterangepicker .drp-buttons .btn.btn-default:active {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active + body .daterangepicker .drp-buttons .btn.btn-default:focus,
.btn-check:checked + body .daterangepicker .drp-buttons .btn.btn-default:focus,
body .daterangepicker .drp-buttons .btn.btn-default.active:focus,
body .daterangepicker .drp-buttons .btn.btn-default.dropdown-toggle.show:focus,
body .daterangepicker .drp-buttons .btn.btn-default:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
body .daterangepicker .drp-buttons .btn.btn-default.disabled,
body .daterangepicker .drp-buttons .btn.btn-default:disabled {
  color: #a8b6bc;
  background-color: transparent;
}
body .daterangepicker .drp-buttons .btn.btn-default:active,
body .daterangepicker .drp-buttons .btn.btn-default:hover {
  color: #000;
}
body .daterangepicker .ranges {
  display: none;
}
body .daterangepicker.show-ranges .ranges {
  position: relative;
  display: block;
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .ranges {
    float: none;
    margin: 0;
    padding: 0.625rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    overflow: scroll;
    max-width: 35rem;
  }
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .ranges ul {
    width: auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    padding-right: 0.625rem;
  }
}
body .daterangepicker.show-ranges .ranges ul li {
  color: #90a2aa;
  font-size: 0.8125rem;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .ranges ul li {
    border-radius: 4px;
    margin-right: 0.625rem !important;
  }
}
body .daterangepicker.show-ranges .ranges ul li:focus,
body .daterangepicker.show-ranges .ranges ul li:hover {
  background: #44555e;
  color: #90a2aa;
}
body .daterangepicker.show-ranges .ranges ul li.active {
  background: #000;
  background: var(--bs-theme);
  color: #fff;
  margin-right: -0.0625rem;
}
body .daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid rgba(255, 255, 255, 0.15) !important;
}
@media (max-width: 767.98px) {
  body .daterangepicker.show-ranges .drp-calendar.left {
    border-left: none !important;
  }
}
.app .slider.slider-horizontal {
  height: 1.5rem;
  width: 100%;
}
.app .slider.slider-horizontal .slider-track {
  height: 0.375rem;
  margin-top: -0.1875rem;
}
.app .slider.slider-vertical {
  margin: 0 auto;
  display: block;
}
.app .slider.slider-vertical .slider-track {
  width: 0.375rem;
  left: 50%;
  margin-left: -0.1875rem;
}
.app .slider.slider-disabled .slider-track {
  opacity: 0.5;
  background: rgba(255, 255, 255, 0.15);
}
.app .slider.slider-disabled .slider-handle {
  background: #90a2aa;
  cursor: not-allowed;
}
.app .slider .slider-track {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}
.app .slider .slider-track .slider-selection {
  background: #000;
  background: var(--bs-theme);
  box-shadow: none;
}
.app .slider .slider-handle {
  background: #fff;
  box-shadow: 0 0.0625rem 0.375rem rgba(39, 49, 54, 0.25);
}
.app .slider .tooltip.in {
  opacity: 1;
}
.app .slider .tooltip.top {
  margin-top: -1.875rem;
}
.app .slider .tooltip.bottom {
  margin-top: 1.5625rem;
}
.app .slider .tooltip.left {
  margin-left: -1.875rem;
}
.app .slider .tooltip.right {
  margin-left: 0.3125rem;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
  width: 15rem;
  padding: 0.9375rem;
}
.bootstrap-timepicker-widget.dropdown-menu.open:after,
.bootstrap-timepicker-widget.dropdown-menu.open:before {
  display: none;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td {
  padding: 0;
  height: auto;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td.separator {
  padding: 0 0.3125rem;
}
.bootstrap-timepicker-widget.dropdown-menu.open
  table
  tbody
  tr
  td:not(.separator) {
  min-width: 3.75rem;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td input {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.3125rem;
  line-height: 1.5;
  background: 0 0;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  width: 100%;
  margin: 0.3125rem 0;
  transition: all 0.15s ease-in-out;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td input:focus {
  border-color: rgba(255, 255, 255, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a {
  padding: 0;
  outline: 0;
  color: #90a2aa;
  display: block;
  line-height: 2.25rem;
  height: 2.25rem;
  width: auto;
  margin: 0 auto;
  border: none;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.15s linear;
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #000;
  color: var(--bs-theme);
}
.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a .glyphicon {
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
.bootstrap-timepicker-widget.dropdown-menu.open
  table
  tbody
  tr
  td
  a
  .glyphicon.glyphicon-chevron-up:before {
  content: "\f077";
}
.bootstrap-timepicker-widget.dropdown-menu.open
  table
  tbody
  tr
  td
  a
  .glyphicon.glyphicon-chevron-down:before {
  content: "\f078";
}
.bootstrap-table .dropdown-toggle .caret {
  display: none;
}
.bootstrap-table
  .fixed-table-container.fixed-height
  .fixed-table-header
  .table {
  border-bottom: none;
}
.bootstrap-table
  .fixed-table-container.fixed-height
  .fixed-table-body
  .table
  thead
  tr
  th {
  border-top: none;
  border-bottom: none;
}
.bootstrap-table .fixed-table-container .table thead tr {
  border-bottom-width: 0 !important;
}
.bootstrap-table .fixed-table-container .table thead tr th {
  border-bottom-color: currentColor !important;
  border-bottom-color: initial !important;
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable {
  position: relative;
  background-image: none !important;
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable:after {
  content: "";
  position: absolute;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  right: 0.625rem;
}
.bootstrap-table
  .fixed-table-container
  .table
  thead
  tr
  th
  .sortable.both:after {
  content: "\f0dc";
  color: rgba(255, 255, 255, 0.5);
}
.bootstrap-table .fixed-table-container .table thead tr th .sortable.asc:after {
  content: "\f0de";
  color: #000;
  color: var(--bs-theme);
}
.bootstrap-table
  .fixed-table-container
  .table
  thead
  tr
  th
  .sortable.desc:after {
  content: "\f0dd";
  color: #000;
  color: var(--bs-theme);
}
.bootstrap-table .fixed-table-container .table tbody tr:first-child {
  border-top: none;
}
.bootstrap-table .fixed-table-container .table tbody tr:first-child td {
  border-top: none;
}
.dataTables_wrapper.dt-bootstrap5 .dataTables_length .form-select {
  margin: 0 0.5rem;
}
.dataTables_wrapper.dt-bootstrap5 .dt-buttons .btn-outline-default {
  background-color: transparent;
}
.dataTables_wrapper.dt-bootstrap5 .dt-buttons .btn-outline-default:focus,
.dataTables_wrapper.dt-bootstrap5 .dt-buttons .btn-outline-default:hover {
  background-color: #a8b6bc;
}
.dataTables_wrapper.dt-bootstrap5
  .dataTable.dtr-inline
  tr
  td.dtr-control:before {
  border-width: 1px;
  margin-top: -8px;
  border-color: rgba(255, 255, 255, 0.3);
  background: 0 0;
  border-radius: 0;
}
.dataTables_wrapper.dt-bootstrap5
  .dataTable.dtr-inline
  tr.parent
  td.dtr-control:before {
  border-color: #000;
  border-color: var(--bs-theme);
  color: #000;
  color: var(--bs-theme);
  background: 0 0;
}
.fc-event-list .fc-event-item .fc-event-link {
  padding: 0.35rem 0.7rem;
  color: #fff;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.fc-event-list .fc-event-item .fc-event-link:hover {
  background: rgba(255, 255, 255, 0.15);
}
.fc-event-list .fc-event-item .fc-event-link:after {
  content: "\f0b2";
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
  margin-left: auto;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
.fc-event-list .fc-event-item + .fc-event-item {
  margin-top: 0.5rem;
}
.fc-event-link.fc-dragging,
.fc-event-link.fc-event-dragging {
  padding: 0.35rem 0.7rem;
  background: #273136;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  display: block;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
}
.fc .fc-header-toolbar,
.fc .fc-toolbar {
  margin-bottom: 0 !important;
  flex-wrap: wrap;
  align-items: center;
}
.fc .fc-header-toolbar .fc-left,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1),
.fc .fc-toolbar .fc-left,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575.98px) {
  .fc .fc-header-toolbar .fc-left .btn-group,
  .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn-group,
  .fc .fc-toolbar .fc-left .btn-group,
  .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn-group {
    width: 100%;
  }
}
.fc .fc-header-toolbar .fc-left .btn,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.fc .fc-toolbar .fc-left .btn,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn {
  min-width: 6.25rem;
  background: 0 0;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: #a8b6bc;
  border-color: #a8b6bc;
}
.fc .fc-header-toolbar .fc-left .btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover,
.fc .fc-toolbar .fc-left .btn:hover,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover {
  color: #a8b6bc;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:focus + .fc .fc-header-toolbar .fc-left .btn,
.btn-check:focus + .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.btn-check:focus + .fc .fc-toolbar .fc-left .btn,
.btn-check:focus + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.fc .fc-header-toolbar .fc-left .btn:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:focus,
.fc .fc-toolbar .fc-left .btn:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-check:active + .fc .fc-header-toolbar .fc-left .btn,
.btn-check:active + .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.btn-check:active + .fc .fc-toolbar .fc-left .btn,
.btn-check:active + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.btn-check:checked + .fc .fc-header-toolbar .fc-left .btn,
.btn-check:checked + .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.btn-check:checked + .fc .fc-toolbar .fc-left .btn,
.btn-check:checked + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.fc .fc-header-toolbar .fc-left .btn.active,
.fc .fc-header-toolbar .fc-left .btn.dropdown-toggle.show,
.fc .fc-header-toolbar .fc-left .btn:active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn.active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn.dropdown-toggle.show,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active,
.fc .fc-toolbar .fc-left .btn.active,
.fc .fc-toolbar .fc-left .btn.dropdown-toggle.show,
.fc .fc-toolbar .fc-left .btn:active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn.active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn.dropdown-toggle.show,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active + .fc .fc-header-toolbar .fc-left .btn:focus,
.btn-check:active
  + .fc
  .fc-header-toolbar
  .fc-toolbar-chunk:nth-child(1)
  .btn:focus,
.btn-check:active + .fc .fc-toolbar .fc-left .btn:focus,
.btn-check:active + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:focus,
.btn-check:checked + .fc .fc-header-toolbar .fc-left .btn:focus,
.btn-check:checked
  + .fc
  .fc-header-toolbar
  .fc-toolbar-chunk:nth-child(1)
  .btn:focus,
.btn-check:checked + .fc .fc-toolbar .fc-left .btn:focus,
.btn-check:checked + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:focus,
.fc .fc-header-toolbar .fc-left .btn.active:focus,
.fc .fc-header-toolbar .fc-left .btn.dropdown-toggle.show:focus,
.fc .fc-header-toolbar .fc-left .btn:active:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn.active:focus,
.fc
  .fc-header-toolbar
  .fc-toolbar-chunk:nth-child(1)
  .btn.dropdown-toggle.show:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active:focus,
.fc .fc-toolbar .fc-left .btn.active:focus,
.fc .fc-toolbar .fc-left .btn.dropdown-toggle.show:focus,
.fc .fc-toolbar .fc-left .btn:active:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn.active:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn.dropdown-toggle.show:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.fc .fc-header-toolbar .fc-left .btn.disabled,
.fc .fc-header-toolbar .fc-left .btn:disabled,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn.disabled,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:disabled,
.fc .fc-toolbar .fc-left .btn.disabled,
.fc .fc-toolbar .fc-left .btn:disabled,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn.disabled,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:disabled {
  color: #a8b6bc;
  background-color: transparent;
}
@media (max-width: 991.98px) {
  .fc .fc-header-toolbar .fc-left .btn,
  .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
  .fc .fc-toolbar .fc-left .btn,
  .fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn {
    min-width: 5.625rem;
  }
}
.fc .fc-header-toolbar .fc-left .btn:active,
.fc .fc-header-toolbar .fc-left .btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover,
.fc .fc-toolbar .fc-left .btn:active,
.fc .fc-toolbar .fc-left .btn:hover,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover {
  color: #000;
}
.fc .fc-header-toolbar .fc-center,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2),
.fc .fc-toolbar .fc-center,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) {
  flex: 1 1;
  padding: 1rem;
}
.fc .fc-header-toolbar .fc-right,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3),
.fc .fc-toolbar .fc-right,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) {
  padding: 1rem;
}
.fc .fc-header-toolbar .fc-right .btn,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.fc .fc-toolbar .fc-right .btn,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background: 0 0;
  color: #a8b6bc;
  border-color: #a8b6bc;
}
.fc .fc-header-toolbar .fc-right .btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover,
.fc .fc-toolbar .fc-right .btn:hover,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover {
  color: #a8b6bc;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:focus + .fc .fc-header-toolbar .fc-right .btn,
.btn-check:focus + .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.btn-check:focus + .fc .fc-toolbar .fc-right .btn,
.btn-check:focus + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.fc .fc-header-toolbar .fc-right .btn:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:focus,
.fc .fc-toolbar .fc-right .btn:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-check:active + .fc .fc-header-toolbar .fc-right .btn,
.btn-check:active + .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.btn-check:active + .fc .fc-toolbar .fc-right .btn,
.btn-check:active + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.btn-check:checked + .fc .fc-header-toolbar .fc-right .btn,
.btn-check:checked + .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.btn-check:checked + .fc .fc-toolbar .fc-right .btn,
.btn-check:checked + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.fc .fc-header-toolbar .fc-right .btn.active,
.fc .fc-header-toolbar .fc-right .btn.dropdown-toggle.show,
.fc .fc-header-toolbar .fc-right .btn:active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn.active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn.dropdown-toggle.show,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active,
.fc .fc-toolbar .fc-right .btn.active,
.fc .fc-toolbar .fc-right .btn.dropdown-toggle.show,
.fc .fc-toolbar .fc-right .btn:active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.dropdown-toggle.show,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active + .fc .fc-header-toolbar .fc-right .btn:focus,
.btn-check:active
  + .fc
  .fc-header-toolbar
  .fc-toolbar-chunk:nth-child(3)
  .btn:focus,
.btn-check:active + .fc .fc-toolbar .fc-right .btn:focus,
.btn-check:active + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:focus,
.btn-check:checked + .fc .fc-header-toolbar .fc-right .btn:focus,
.btn-check:checked
  + .fc
  .fc-header-toolbar
  .fc-toolbar-chunk:nth-child(3)
  .btn:focus,
.btn-check:checked + .fc .fc-toolbar .fc-right .btn:focus,
.btn-check:checked + .fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:focus,
.fc .fc-header-toolbar .fc-right .btn.active:focus,
.fc .fc-header-toolbar .fc-right .btn.dropdown-toggle.show:focus,
.fc .fc-header-toolbar .fc-right .btn:active:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn.active:focus,
.fc
  .fc-header-toolbar
  .fc-toolbar-chunk:nth-child(3)
  .btn.dropdown-toggle.show:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active:focus,
.fc .fc-toolbar .fc-right .btn.active:focus,
.fc .fc-toolbar .fc-right .btn.dropdown-toggle.show:focus,
.fc .fc-toolbar .fc-right .btn:active:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.active:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.dropdown-toggle.show:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.fc .fc-header-toolbar .fc-right .btn.disabled,
.fc .fc-header-toolbar .fc-right .btn:disabled,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn.disabled,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:disabled,
.fc .fc-toolbar .fc-right .btn.disabled,
.fc .fc-toolbar .fc-right .btn:disabled,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.disabled,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:disabled {
  color: #a8b6bc;
  background-color: transparent;
}
.fc .fc-header-toolbar .fc-right .btn:active,
.fc .fc-header-toolbar .fc-right .btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover,
.fc .fc-toolbar .fc-right .btn:active,
.fc .fc-toolbar .fc-right .btn:hover,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover {
  color: #000;
}
.fc .fc-view .fc-event,
.fc .fc-view .fc-event-dot {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: transparent;
}
.fc .fc-view .fc-event-dot.fc-daygrid-dot-event,
.fc .fc-view .fc-event.fc-daygrid-dot-event {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: transparent;
}
.fc .fc-view.fc-dayGrid-view .fc-day-number,
.fc .fc-view.fc-dayGrid-view .fc-daygrid-day-number,
.fc .fc-view.fc-dayGrid-view .fc-week-number,
.fc .fc-view.fc-dayGridMonth-view .fc-day-number,
.fc .fc-view.fc-dayGridMonth-view .fc-daygrid-day-number,
.fc .fc-view.fc-dayGridMonth-view .fc-week-number {
  padding: 0.125rem 0.25rem;
}
.fc .fc-view.fc-dayGrid-view .fc-day-grid-event,
.fc .fc-view.fc-dayGrid-view .fc-daygrid-day-events,
.fc .fc-view.fc-dayGridMonth-view .fc-day-grid-event,
.fc .fc-view.fc-dayGridMonth-view .fc-daygrid-day-events {
  margin-top: 0.125rem;
}
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-bg table,
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-slats table {
  border: none;
}
.fc
  .fc-view.fc-timeGrid-view
  .fc-time-grid
  .fc-bg
  table
  tbody
  tr:first-child
  td,
.fc
  .fc-view.fc-timeGrid-view
  .fc-time-grid
  .fc-bg
  table
  thead
  tr:first-child
  td,
.fc
  .fc-view.fc-timeGrid-view
  .fc-time-grid
  .fc-slats
  table
  tbody
  tr:first-child
  td,
.fc
  .fc-view.fc-timeGrid-view
  .fc-time-grid
  .fc-slats
  table
  thead
  tr:first-child
  td {
  border-top: none;
}
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table {
  border: none !important;
}
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table td,
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table th {
  border: none !important;
}
.fc .fc-view a {
  text-decoration: none;
}
.fc .fc-view .fc-daygrid-event,
.fc .fc-view .fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc .fc-view .fc-timegrid-event.fc-event-mirror,
.fc .fc-view .fc-timegrid-more-link {
  box-shadow: none;
  border-radius: 0;
}
.fc td,
.fc th {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.3);
}
.fc .fc-view-harness {
  height: auto !important;
}
.fc .fc-scroller-liquid-absolute {
  position: relative !important;
}
.fc a:not([href]) {
  color: inherit;
}
.hljs {
  display: block;
  overflow-x: auto;
  color: #617a86;
  font-size: 0.7875rem;
}
.hljs-code,
.hljs-emphasis {
  font-style: italic;
}
.hljs-name,
.hljs-tag {
  color: #90a2aa;
}
.hljs-bullet,
.hljs-string {
  color: #bba4ff;
}
.hljs-attr {
  color: #3cd2a5;
}
.hljs a {
  color: inherit;
}
.hljs a:focus,
.hljs a:hover {
  color: inherit;
  text-decoration: underline;
}
.hljs-container {
  padding: 1rem 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 1;
}
.hljs-container pre {
  border: none;
  padding: 0;
  margin: 0;
  background: 0 0;
  border-radius: 0;
}
body .typeahead__container {
  font: inherit;
}
body .typeahead__container .typeahead__field .typeahead__query input {
  background: 0 0;
  border-color: rgba(255, 255, 255, 0.3);
  border-radius: 0 4px 4px 0;
}
body .typeahead__container .typeahead__field .typeahead__query input:focus {
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
body
  .typeahead__container
  .typeahead__field
  .typeahead__query
  .typeahead__cancel-button {
  color: transparent;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
body
  .typeahead__container
  .typeahead__field
  .typeahead__query
  .typeahead__cancel-button:before {
  content: "\f00d";
  position: absolute;
  color: rgba(255, 255, 255, 0.75);
  color: var(--bs-body-color);
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
body .typeahead__container .typeahead__result .typeahead__list {
  background: rgba(29, 40, 53, 0.98);
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
  border: none;
  border-radius: 4px;
}
body
  .typeahead__container
  .typeahead__result
  .typeahead__list
  .typeahead__item
  > a {
  color: rgba(255, 255, 255, 0.75);
}
body
  .typeahead__container
  .typeahead__result
  .typeahead__list
  .typeahead__item
  > a:focus,
body
  .typeahead__container
  .typeahead__result
  .typeahead__list
  .typeahead__item
  > a:hover {
  background: rgba(255, 255, 255, 0.15);
}
body
  .typeahead__container
  .typeahead__result
  .typeahead__list
  .typeahead__item.active
  > a {
  background: rgba(255, 255, 255, 0.15);
}
body
  .typeahead__container
  .typeahead__result
  .typeahead__list
  .typeahead__item
  + .typeahead__item {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.jvectormap-container {
  position: relative;
}
.jvectormap-container .jvectormap-goback,
.jvectormap-container .jvectormap-zoomin,
.jvectormap-container .jvectormap-zoomout {
  width: 1.125rem;
  height: 1.125rem;
  padding: 0;
  box-sizing: border-box;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.75);
  left: 1rem;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jvectormap-without-padding .jvectormap-container .jvectormap-goback,
.jvectormap-without-padding .jvectormap-container .jvectormap-zoomin,
.jvectormap-without-padding .jvectormap-container .jvectormap-zoomout {
  left: 0;
}
.jvectormap-container .jvectormap-goback:hover,
.jvectormap-container .jvectormap-zoomin:hover,
.jvectormap-container .jvectormap-zoomout:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.45);
}
.jvectormap-container .jvectormap-zoomin {
  top: 1rem;
}
.jvectormap-without-padding .jvectormap-container .jvectormap-zoomin {
  top: 0;
}
.jvectormap-container .jvectormap-zoomout {
  top: 2.5625rem;
}
.jvectormap-without-padding .jvectormap-container .jvectormap-zoomout {
  top: 1.5625rem;
}
.jvectormap-container .jvectormap-legend {
  background: rgba(53, 67, 74, 0.85);
  color: rgba(255, 255, 255, 0.75);
  box-shadow: none;
  border-radius: 0;
}
.jvectormap-container .jvectormap-legend-title {
  font-size: 0.6875rem;
  padding: 0.3rem 0.5rem;
  background: rgba(255, 255, 255, 0.15);
}
.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend {
  padding: 0;
  margin: 1rem 1rem 0 0;
}
.jvectormap-container
  .jvectormap-legend-cnt-v
  .jvectormap-legend
  .jvectormap-legend-inner {
  padding: 0.5rem;
}
.jvectormap-container
  .jvectormap-legend-cnt-v
  .jvectormap-legend
  .jvectormap-legend-tick {
  display: flex;
  align-items: center;
}
.jvectormap-container
  .jvectormap-legend-cnt-v
  .jvectormap-legend
  .jvectormap-legend-tick-sample {
  width: 1rem;
  height: 1rem;
}
.jvectormap-container
  .jvectormap-legend-cnt-v
  .jvectormap-legend
  .jvectormap-legend-tick-text {
  padding-left: 0.5rem;
}
.jvectormap-tip {
  background: rgba(255, 255, 255, 0.9) !important;
  color: #000 !important;
  font-weight: 600 !important;
  padding: 0.25rem 0.5rem !important;
  border: none !important;
  font-family: inherit !important;
  font-size: 0.7875rem !important;
  z-index: 1000;
  border-radius: 4px !important;
}
.pace {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pace .pace-progress {
  position: fixed;
  z-index: 2000;
  top: 3.25rem;
  width: 100%;
  height: 0.125rem;
  background: #000;
  background: var(--bs-theme);
  right: 100%;
}
.pace-top .pace .pace-progress {
  top: 0;
}
.pace .pace-progress-inner {
  display: block;
  position: absolute;
  width: 6.25rem;
  height: 100%;
  opacity: 1;
  box-shadow: none;
  right: 0;
}
.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 4.25rem;
  width: 1.125rem;
  height: 1.125rem;
  border: solid 0.125rem transparent;
  border-top-color: #000;
  border-top-color: var(--bs-theme);
  border-left-color: rgba(255, 255, 255, 0.25);
  right: 1rem;
}
.pace-top .pace .pace-activity {
  top: 1rem;
}
.pace.pace-inactive {
  display: none;
}
@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.ps {
  position: relative;
}
.ps .ps__rail-x,
.ps .ps__rail-y {
  z-index: 10;
}
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: 0 0;
}
.ps .ps__thumb-y {
  border-radius: 0;
  background: 0 0;
}
.ps .ps__thumb-y:before {
  content: "";
  background: #aaa;
  position: absolute;
  top: 0.0625rem;
  bottom: 0.0625rem;
  right: 0;
  left: 0;
}
.app-sidebar .ps__rail-x,
.app-sidebar .ps__rail-y {
  display: none;
}
body .picker {
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  border-radius: 4px;
}
body .picker > .pc-element {
  margin: 0.375rem 0 0 0.75rem;
  padding: 0.125rem 0.625rem !important;
  border: none;
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.75);
  border-radius: 30px;
}
body .picker > .pc-element:hover {
  background: #c0cacf;
  color: #35434a;
}
body .picker > .pc-element .pc-close {
  position: relative;
  margin: 0;
  top: 0;
  color: #fff;
  opacity: 0.5;
  font-size: 0.75rem;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  margin-left: 0.5rem;
  right: 0;
}
body .picker > .pc-element .pc-close:after {
  content: "\f00d" !important;
}
body .picker > .pc-element .pc-close:hover {
  opacity: 1;
}
body .picker > .pc-element + .pc-select {
  padding-top: 0.375rem;
}
body .picker > .pc-element + .pc-select .pc-element {
  margin-top: -0.375rem;
}
body .picker .pc-select {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  min-width: initial;
}
body .picker .pc-select .pc-element {
  display: block;
  margin: 0;
  font-weight: 300;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
}
body .picker .pc-select .pc-list {
  padding: 0.5rem 0;
  top: 100%;
  background: rgba(29, 40, 53, 0.98);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
  border-radius: 0;
}
body .picker .pc-select .pc-list input[type="search"] {
  display: block;
  width: calc(100% - 20px);
  margin: 0 0.625rem 0.3125rem;
  padding: 0.375rem 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 4px;
  font-size: 0.875rem;
}
body .picker .pc-select .pc-list input[type="search"]:focus {
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
body .picker .pc-select .pc-list ul > li {
  padding: 6px 20px;
  background: 0 0;
  color: rgba(255, 255, 255, 0.5);
}
body .picker .pc-select .pc-list ul > li:nth-child(even) {
  background: 0 0;
}
body .picker .pc-select .pc-list ul > li:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}
.sp-original-input-container .sp-add-on {
  min-width: 2.1875rem;
  background-image: none;
}
.sp-original-input-container .sp-add-on .sp-colorize {
  position: relative;
  overflow: hidden;
}
.sp-original-input-container .sp-add-on .sp-colorize:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid transparent;
}
body .sp-container {
  background: rgba(29, 40, 53, 0.98);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0;
}
body .sp-container .sp-palette-container {
  border-color: rgba(255, 255, 255, 0.15);
}
body .sp-container .sp-picker-container .sp-input-container .sp-input {
  color: rgba(255, 255, 255, 0.75) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}
body .sp-container .sp-picker-container .sp-cancel {
  background: 0 0;
  border: 1px solid #a8b6bc;
  border: 1px solid var(--bs-default);
  color: #a8b6bc;
  color: var(--bs-default);
}
body .sp-container .sp-picker-container .sp-cancel:focus,
body .sp-container .sp-picker-container .sp-cancel:hover {
  background: #a8b6bc;
  background: var(--bs-default);
  color: #000;
}
body .sp-container .sp-picker-container .sp-choose {
  background: 0 0;
  border: 1px solid #000;
  border: 1px solid var(--bs-theme);
  color: #000;
  color: var(--bs-theme);
}
body .sp-container .sp-picker-container .sp-choose:focus,
body .sp-container .sp-picker-container .sp-choose:hover {
  background: #000;
  background: var(--bs-theme);
  color: #000;
}
.note-editor {
  background: 0 0;
  font-family: "Chakra Petch", sans-serif !important;
}
.note-editor.note-airframe,
.note-editor.note-frame {
  color: rgba(255, 255, 255, 0.75);
}
.note-editor.note-airframe .note-toolbar,
.note-editor.note-frame .note-toolbar {
  padding: 0.5rem 1rem 0.75rem;
  background: 0 0;
  border-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
}
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn {
  line-height: 1.5;
  min-height: 2.1875rem;
  background: 0 0;
  color: #a8b6bc;
  border-color: #a8b6bc;
}
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:hover,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:hover {
  color: #a8b6bc;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:focus
  + .note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn,
.btn-check:focus
  + .note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn,
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:focus,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.btn-check:active
  + .note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn,
.btn-check:active
  + .note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn,
.btn-check:checked
  + .note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn,
.btn-check:checked
  + .note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn,
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn.active,
.note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn.dropdown-toggle.show,
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:active,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn.active,
.note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn.dropdown-toggle.show,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:active {
  color: #000;
  background-color: #a8b6bc;
  border-color: #a8b6bc;
}
.btn-check:active
  + .note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn:focus,
.btn-check:active
  + .note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn:focus,
.btn-check:checked
  + .note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn:focus,
.btn-check:checked
  + .note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn:focus,
.note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn.active:focus,
.note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn.dropdown-toggle.show:focus,
.note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn:active:focus,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn.active:focus,
.note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn.dropdown-toggle.show:focus,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(168, 182, 188, 0.5);
}
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn.disabled,
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:disabled,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn.disabled,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:disabled {
  color: #a8b6bc;
  background-color: transparent;
}
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:focus,
.note-editor.note-airframe .note-toolbar .note-btn-group > .note-btn:hover,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:focus,
.note-editor.note-frame .note-toolbar .note-btn-group > .note-btn:hover {
  color: #000;
}
.note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn
  .note-icon-caret:before,
.note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn
  .note-icon-caret:before {
  content: "";
}
.note-editor.note-airframe
  .note-toolbar
  .note-btn-group
  > .note-btn
  > .note-icon-caret:first-child,
.note-editor.note-frame
  .note-toolbar
  .note-btn-group
  > .note-btn
  > .note-icon-caret:first-child {
  margin-left: -0.25rem;
}
.note-editor.note-airframe .note-toolbar .note-dropdown-menu,
.note-editor.note-frame .note-toolbar .note-dropdown-menu {
  border: 0;
  padding: 0.5rem 0;
  background: rgba(29, 40, 53, 0.98);
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
  border-radius: 4px;
}
.note-editor.note-airframe
  .note-toolbar
  .note-dropdown-menu
  .note-dropdown-item,
.note-editor.note-frame .note-toolbar .note-dropdown-menu .note-dropdown-item {
  padding: 6px 20px;
  color: rgba(255, 255, 255, 0.5);
}
.note-editor.note-airframe
  .note-toolbar
  .note-dropdown-menu
  .note-dropdown-item:hover,
.note-editor.note-frame
  .note-toolbar
  .note-dropdown-menu
  .note-dropdown-item:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}
.note-editor.note-airframe
  .note-toolbar
  .note-palette
  .note-color-palette
  .note-color-row,
.note-editor.note-frame
  .note-toolbar
  .note-palette
  .note-color-palette
  .note-color-row {
  display: flex;
}
.note-editor.note-airframe
  .note-toolbar
  .note-palette
  .note-color-palette
  .note-color-row
  .note-color-btn,
.note-editor.note-frame
  .note-toolbar
  .note-palette
  .note-color-palette
  .note-color-row
  .note-color-btn {
  display: block;
  min-height: 0;
  min-height: initial;
}
.note-editor.note-airframe .note-toolbar .note-para .note-dropdown-menu,
.note-editor.note-frame .note-toolbar .note-para .note-dropdown-menu {
  min-width: 14.6875rem;
}
.note-editor.note-airframe .note-editing-area .note-editable,
.note-editor.note-frame .note-editing-area .note-editable {
  padding: 1rem 1rem !important;
}
.note-editor.note-airframe.fullscreen,
.note-editor.note-frame.fullscreen {
  top: 3.25rem !important;
  background: rgba(29, 40, 53, 0.98);
}
@media (min-width: 768px) {
  .note-editor.note-airframe.fullscreen,
  .note-editor.note-frame.fullscreen {
    left: 16.875rem !important;
  }
}
.note-editor.note-airframe.codeview .note-editing-area .note-codable,
.note-editor.note-frame.codeview .note-editing-area .note-codable {
  background: 0 0;
}
.note-editor.note-airframe .note-statusbar .note-resizebar .note-icon-bar,
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.note-editor .note-tooltip .note-tooltip-content {
  border-radius: 2px;
}
.note-editor .note-modal {
  padding: 3.25rem 0;
  overflow: scroll;
}
.note-editor .note-modal .note-modal-content {
  border: 0;
  background: #1d2835;
  border-radius: 0;
  box-shadow: none;
}
.note-editor .note-modal .note-modal-content .note-modal-header {
  border: 0;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-header
  .note-modal-title {
  color: #fff;
  font-weight: 600;
  font-size: 1.09375rem;
}
.note-editor .note-modal .note-modal-content .note-modal-header .close {
  color: #fff;
}
.note-editor .note-modal .note-modal-content .note-modal-body {
  padding: 1rem;
}
.note-editor .note-modal .note-modal-content .note-modal-body .form-group {
  padding-bottom: 1rem;
}
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-body
  .form-group
  .note-form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: inherit;
  display: block;
  padding: 0;
}
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-body
  .form-group
  .note-form-control {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  background: 0 0;
}
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-body
  .form-group
  .note-form-control:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
.note-editor .note-modal .note-modal-content .note-modal-body .checkbox label {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-body
  .checkbox
  label
  input {
  margin-right: 0.5rem;
}
.note-editor .note-modal .note-modal-content .note-modal-footer {
  border: 0;
  height: auto;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: flex-end;
}
.note-editor .note-modal .note-modal-content .note-modal-footer .note-btn {
  border: none;
  border-radius: 4px;
}
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-footer
  .note-btn.note-btn-primary {
  background: 0 0;
  border: 1px solid #000;
  border: 1px solid var(--bs-theme);
  color: #000;
  color: var(--bs-theme);
}
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-footer
  .note-btn.note-btn-primary:focus,
.note-editor
  .note-modal
  .note-modal-content
  .note-modal-footer
  .note-btn.note-btn-primary:hover {
  background: #000;
  background: var(--bs-theme);
  color: #000;
}
body .note-modal-backdrop {
  background: #1d2835;
  opacity: 0.95;
  display: none !important;
}
.card > .note-editor.note-frame {
  border: none;
}
.card > form > .note-editor.note-frame {
  border: none;
}
.tagit {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tagit.form-control {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
}
.tagit.ui-widget {
  margin-bottom: 0;
  font-family: inherit;
  box-shadow: none !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  color: rgba(255, 255, 255, 0.75) !important;
  position: relative !important;
  padding: 0.1875rem 0.75rem !important;
  line-height: 1.3125rem !important;
  border-radius: 4px !important;
  transition: all 0.15s ease-in-out;
  display: flex;
  flex-wrap: wrap;
}
.tagit.ui-widget li {
  float: none;
}
.tagit.ui-widget li.tagit-choice {
  font-size: 0.8125rem !important;
  font-weight: 400;
  padding: 0.125rem 0.625rem !important;
  margin-top: 0.0625rem !important;
  margin-bottom: 0.0625rem !important;
  border: none;
  background: rgba(255, 255, 255, 0.3) !important;
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-right: 4px !important;
}
.tagit.ui-widget li.tagit-choice .tagit-close {
  position: relative;
  line-height: 1.3125rem;
  text-align: center;
  top: 0;
  height: 1.3125rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 0 -0.3125rem -0.0625rem 0.3125rem;
}
.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close {
  font-size: 0.625rem;
  color: rgba(255, 255, 255, 0.5);
  text-indent: 0;
  height: 100%;
  padding-top: 0.125rem;
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close:after {
  content: "\f00d" !important;
}
.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close:hover {
  box-shadow: none !important;
  color: rgba(255, 255, 255, 0.75);
}
.tagit.ui-widget li.tagit-choice .tagit-close:focus .ui-icon-close:before,
.tagit.ui-widget li.tagit-choice .tagit-close:hover .ui-icon-close:before {
  color: rgba(0, 0, 0, 0.4);
}
.tagit.ui-widget li.tagit-new {
  margin: 0.125rem 0;
  height: 1.4375rem;
  display: flex;
  align-items: center;
}
.tagit.ui-widget li.tagit-new input {
  color: rgba(255, 255, 255, 0.75);
}
.tagit-autocomplete {
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  padding: 0.5rem 0;
  background: rgba(29, 40, 53, 0.98);
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.075);
}
.tagit-autocomplete .ui-menu-item .ui-menu-item-wrapper {
  padding: 6px 20px;
  border: none;
  color: rgba(255, 255, 255, 0.5);
}
.tagit-autocomplete .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  background: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
}
.calendar {
  min-height: calc(100vh - 3.25rem);
  display: flex;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 767.98px) {
  .calendar {
    border: none;
  }
}
.calendar .calendar-body {
  flex: 1 1;
}
.calendar .calendar-body + .calendar-sidebar {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.calendar .calendar-body .fc .fc-head-container th {
  border-bottom-width: 0;
}
.calendar .calendar-body .fc table {
  border: none !important;
}
.calendar .calendar-body .fc table tbody tr,
.calendar .calendar-body .fc table thead tr {
  border-width: 0;
}
.calendar .calendar-body .fc table tbody tr td:first-child,
.calendar .calendar-body .fc table tbody tr th:first-child,
.calendar .calendar-body .fc table thead tr td:first-child,
.calendar .calendar-body .fc table thead tr th:first-child {
  border-left-width: 0 !important;
}
.calendar .calendar-body .fc table tbody tr td:last-child,
.calendar .calendar-body .fc table tbody tr th:last-child,
.calendar .calendar-body .fc table thead tr td:last-child,
.calendar .calendar-body .fc table thead tr th:last-child {
  border-right-width: 0 !important;
}
.calendar .calendar-body .fc table .fc-head-container {
  border: none;
}
.calendar .calendar-sidebar {
  width: 13.75rem;
  padding: 1rem;
}
@media (max-width: 991.98px) {
  .calendar .calendar-sidebar {
    display: none;
  }
}
.calendar .calendar-sidebar + .calendar-body {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.calendar .calendar-sidebar .calendar-sidebar-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: flex-end;
}
.coming-soon {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.coming-soon .coming-soon-img img {
  max-width: 100%;
}
.coming-soon .coming-soon-timer {
  padding: 2rem;
  min-height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.coming-soon .coming-soon-content {
  min-height: 50vh;
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.15) 0,
    transparent 100%
  );
  flex: 1 1;
}
.coming-soon .coming-soon-content > * {
  max-width: 32.5rem;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon .is-countdown {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0;
}
.coming-soon .is-countdown:after,
.coming-soon .is-countdown:before {
  content: "";
  display: table;
  clear: both;
}
.coming-soon .is-countdown .countdown-row {
  display: block;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coming-soon .is-countdown .countdown-show4 .countdown-section {
  width: 7.5rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.15);
  margin: 0.75rem;
  color: #fff;
  border-radius: 12px;
}
@media (max-width: 575.98px) {
  .coming-soon .is-countdown .countdown-show4 .countdown-section {
    width: 5rem;
    padding: 0.5rem 0;
    margin: 0.25rem;
  }
}
.coming-soon .is-countdown .countdown-amount {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.25;
}
@media (max-width: 575.98px) {
  .coming-soon .is-countdown .countdown-amount {
    font-size: 1.75rem;
  }
}
.coming-soon .is-countdown .countdown-period {
  font-size: 1.00625rem;
  color: rgba(255, 255, 255, 0.75);
  line-height: 1.5;
}
@media (max-width: 575.98px) {
  .coming-soon .is-countdown .countdown-period {
    font-size: 0.7875rem;
  }
}
.error-page {
  min-height: 100vh;
  padding: 4rem 0;
  display: flex;
  align-items: center;
}
.error-page .error-page-content {
  margin: 0 auto;
  position: relative;
  padding: 0 1rem;
  text-align: center;
}
.error-page .error-page-content .error-code {
  color: #fff;
  left: 0;
  right: 0;
  font-size: 7.375rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 5px;
  text-shadow: 10px 10px rgba(255, 255, 255, 0.15);
}
@media (max-width: 575.98px) {
  .error-page .error-page-content .error-code {
    font-size: 6.375rem;
  }
}
.error-page .error-page-content .error-page-icon i {
  font-size: 8rem;
  color: #90a2aa;
}
.error-page .error-page-content .h1,
.error-page .error-page-content h1 {
  font-size: 3rem;
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .error-page .error-page-content .h1,
  .error-page .error-page-content h1 {
    font-size: 3.25rem;
  }
}
.error-page .error-page-content .h3,
.error-page .error-page-content h3 {
  font-size: 1.125rem;
  font-weight: 400;
}
@media (max-width: 575.98px) {
  .error-page .error-page-content .h3,
  .error-page .error-page-content h3 {
    font-size: 1rem;
  }
}
.error-page .error-page-content .link-divider {
  width: 0.25rem;
  height: 0.25rem;
  display: inline-block;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
  top: -0.125rem;
  margin: 0 1rem;
  border-radius: 4px;
}
.gallery-menu-container {
  min-width: 12.5rem;
}
@media (min-width: 768px) {
  .gallery-menu-container {
    min-height: calc(100vh - 3.25rem);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
}
.gallery-menu {
  padding: 1rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .gallery-menu {
    overflow: scroll;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    position: -webkit-sticky;
    position: sticky;
    top: 3.25rem;
    display: flex;
    flex-wrap: nowrap;
  }
}
.gallery-menu .gallery-menu-header {
  padding: 1rem 1rem 0.25rem;
  font-weight: 600;
  font-size: 90%;
}
@media (max-width: 767.98px) {
  .gallery-menu .gallery-menu-header {
    display: none;
  }
}
.gallery-menu .gallery-menu-item .gallery-menu-link {
  padding: 0.35rem 1.25rem;
  display: block;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .gallery-menu .gallery-menu-item .gallery-menu-link {
    white-space: nowrap;
    padding: 0.9375rem 1.25rem 0.625rem;
  }
}
.gallery-menu .gallery-menu-item .gallery-menu-link .fa {
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 767.98px) {
  .gallery-menu .gallery-menu-item .gallery-menu-link .fa {
    display: block;
    margin: 0 0 0.1875rem !important;
    width: auto !important;
    font-size: 1.125rem;
  }
}
.gallery-menu .gallery-menu-item .gallery-menu-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.gallery-menu .gallery-menu-item .gallery-menu-link.active {
  color: #000;
  color: var(--bs-theme);
  font-weight: 600;
}
.gallery-menu .gallery-menu-item .gallery-menu-link.active .fa {
  color: inherit;
}
.gallery-content-container {
  width: 100%;
}
@media (min-width: 768px) {
  .gallery-content-container {
    min-height: calc(100vh - 3.25rem);
  }
}
.gallery {
  padding: 1rem;
}
.gallery + .gallery {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.gallery .gallery-title {
  font-size: 1.09375rem;
  color: #fff;
  font-weight: 600;
  margin-bottom: 1rem;
}
.gallery .gallery-title a {
  color: #fff;
  text-decoration: none;
}
.gallery .gallery-title a i {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 0.3125rem;
}
.gallery .gallery-title .small,
.gallery .gallery-title small {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.7875rem;
}
.gallery .gallery-image-list {
  list-style-type: none;
  margin: -0.15rem;
  padding: 0;
}
.gallery .gallery-image-list:after,
.gallery .gallery-image-list:before {
  content: "";
  display: table;
  clear: both;
}
.gallery .gallery-image-list > li {
  position: relative;
  padding-top: 12.5%;
  width: 16.6667%;
  float: left;
}
@media (max-width: 991.98px) {
  .gallery .gallery-image-list > li {
    width: 25%;
    padding-top: 18.75%;
  }
}
@media (max-width: 767.98px) {
  .gallery .gallery-image-list > li {
    width: 33.33%;
    padding-top: 33.33%;
  }
}
@media (max-width: 575.98px) {
  .gallery .gallery-image-list > li {
    width: 50%;
    padding-top: 50%;
  }
}
.gallery .gallery-image-list > li:hover {
  z-index: 10;
}
.gallery .gallery-image-list > li a {
  position: absolute;
  left: 0.15rem;
  right: 0.15rem;
  bottom: 0.15rem;
  top: 0.15rem;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 992px) {
  .gallery .gallery-image-list > li a:hover {
    left: -15%;
    right: -15%;
    top: -15%;
    bottom: -15%;
  }
}
.gallery .gallery-image-list > li .img,
.gallery .gallery-image-list > li img {
  max-width: 100%;
}
.gallery .gallery-image-list > li .img-portrait {
  height: 100%;
  width: auto;
  max-height: 100%;
  max-width: inherit;
}
@media (max-width: 767.98px) {
  .gallery .gallery-image-list > li .img-portrait-xs {
    height: 100%;
    width: auto;
    max-height: 100%;
    max-width: inherit;
  }
}
.login {
  min-height: 100vh;
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .login {
    padding: 1rem;
  }
}
.login .login-content {
  max-width: 22.5rem;
  margin: 0 auto;
  position: relative;
  flex: 1 1;
}
.mailbox {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mailbox .mailbox-toolbar {
  padding: 0.75rem;
  display: flex;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-toolbar {
    overflow: scroll;
    white-space: nowrap;
    padding: 0.5rem;
    flex-wrap: nowrap;
  }
}
.mailbox .mailbox-toolbar .mailbox-toolbar-text {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-toolbar .mailbox-toolbar-text {
    padding: 0;
  }
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link {
  padding: 0.5rem 1rem;
}
.mailbox .mailbox-toolbar .mailbox-toolbar-item {
  padding: 0.5rem;
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  border-radius: 4px;
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link:hover {
  background: rgba(255, 255, 255, 0.25);
}
.mailbox .mailbox-toolbar .mailbox-toolbar-link.active {
  background: #000;
  background: var(--bs-theme);
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
}
.mailbox .mailbox-body {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  display: flex;
  flex: 1 1;
}
.mailbox .mailbox-body .mailbox-sidebar {
  max-width: 22.5rem;
  height: 100%;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-body .mailbox-sidebar {
    max-width: 100%;
    height: auto;
  }
}
.mailbox .mailbox-body .mailbox-sidebar + .mailbox-content {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-body .mailbox-sidebar + .mailbox-content {
    border-left: none;
  }
}
.mailbox .mailbox-body .mailbox-content {
  height: 100%;
  flex: 1 1;
}
.mailbox .mailbox-empty-message {
  min-height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-icon i {
  font-size: 6.125rem;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-img {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-img img {
  width: 100%;
  height: 100%;
  max-width: 30rem;
  max-height: 30rem;
  opacity: 0.5;
}
.mailbox .mailbox-empty-message .mailbox-empty-message-title {
  font-size: 1.3125rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}
.mailbox .mailbox-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mailbox .mailbox-list .mailbox-list-item {
  padding: 1rem;
  display: flex;
}
.mailbox .mailbox-list .mailbox-list-item + .mailbox-list-item {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.mailbox .mailbox-list .mailbox-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.mailbox
  .mailbox-list
  .mailbox-list-item.unread
  .mailbox-message
  .mailbox-title {
  font-weight: 600;
  color: #fff;
}
.mailbox .mailbox-list .mailbox-list-item.active {
  background: rgba(255, 255, 255, 0.1);
}
.mailbox
  .mailbox-list
  .mailbox-list-item.has-attachment
  .mailbox-checkbox:after {
  content: "\f0c1";
  position: absolute;
  top: 1.5625rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  width: 1.125rem;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  left: 0;
}
.mailbox .mailbox-list .mailbox-checkbox {
  position: relative;
}
.mailbox .mailbox-list .mailbox-checkbox + .mailbox-message {
  padding-left: 0.5rem;
}
.mailbox .mailbox-list .mailbox-message {
  flex: 1 1;
}
.mailbox .mailbox-list .mailbox-message .mailbox-list-item-link {
  display: block;
  color: #fff;
  text-decoration: none;
  position: relative;
}
.mailbox .mailbox-list .mailbox-message .mailbox-sender {
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-list .mailbox-message .mailbox-sender {
    display: block;
  }
}
.mailbox .mailbox-list .mailbox-message .mailbox-sender .mailbox-sender-name {
  font-weight: 600;
  color: #000;
  color: var(--bs-theme);
  flex: 1 1;
  margin-right: auto;
}
.mailbox .mailbox-list .mailbox-message .mailbox-sender .mailbox-time {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  padding-left: 0.5rem;
}
.mailbox .mailbox-list .mailbox-message .mailbox-title {
  color: rgba(255, 255, 255, 0.75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18.125rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-list .mailbox-message .mailbox-title {
    max-width: none;
    max-width: initial;
    white-space: normal;
    white-space: initial;
  }
}
.mailbox .mailbox-list .mailbox-message .mailbox-desc {
  position: relative;
  font-size: 0.8125rem;
  color: rgba(255, 255, 255, 0.5);
  max-height: 2.625rem;
  overflow: hidden;
}
.mailbox .mailbox-detail {
  padding: 1.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-detail {
    padding: 1rem;
  }
}
.mailbox .mailbox-detail .mailbox-detail-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0 0 1.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-detail .mailbox-detail-header {
    padding-bottom: 1rem;
  }
}
.mailbox .mailbox-detail .mailbox-detail-content {
  padding: 1.5rem 0;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-detail .mailbox-detail-content {
    padding: 1rem 0;
  }
}
.mailbox .mailbox-detail .mailbox-detail-body {
  font-size: 0.875rem;
}
.mailbox .mailbox-detail .mailbox-detail-attachment {
  display: flex;
  flex-wrap: wrap;
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment {
  background: rgba(255, 255, 255, 0.15);
  width: 7.5rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  margin-right: 0.5rem;
}
.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment a {
  display: block;
  text-decoration: none;
}
.mailbox
  .mailbox-detail
  .mailbox-detail-attachment
  .mailbox-attachment
  .document-file {
  height: 3.75rem;
  overflow: hidden;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
}
.mailbox
  .mailbox-detail
  .mailbox-detail-attachment
  .mailbox-attachment
  .document-file
  i {
  display: block;
  line-height: 3.75rem;
  font-size: 2rem;
  color: #fff;
}
.mailbox
  .mailbox-detail
  .mailbox-detail-attachment
  .mailbox-attachment
  .document-file
  img {
  max-width: 100%;
}
.mailbox
  .mailbox-detail
  .mailbox-detail-attachment
  .mailbox-attachment
  .document-name {
  color: #fff;
  padding: 0.125rem 0.625rem;
  font-size: 0.8125rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mailbox .mailbox-form .mailbox-form-header {
  padding: 1rem 1.5rem;
}
@media (max-width: 991.98px) {
  .mailbox .mailbox-form .mailbox-form-header {
    padding: 0.75rem 1rem;
  }
}
.mailbox .mailbox-form .mailbox-form-header .form-control {
  background: rgba(255, 255, 255, 0.15);
}
.mailbox .mailbox-form .note-editor {
  border: none;
  border-radius: 0;
}
.mailbox .mailbox-form .note-editor .note-toolbar {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.mailbox .mailbox-form .note-editor .note-editing-area {
  background: rgba(255, 255, 255, 0.15);
}
.pos {
  background: var(--app-pos-bg);
  height: 100%;
}
.pos .pos-container {
  height: 100%;
  padding: 0;
}
@media (min-width: 992px) {
  .pos .pos-container {
    display: flex;
  }
  .pos .pos-container > div + div {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
}
.pos .pos-menu {
  background: var(--app-component-bg);
  display: flex;
}
@media (min-width: 992px) {
  .pos .pos-menu {
    width: 140px;
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  .pos .pos-menu {
    overflow: scroll;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}
.pos .pos-menu .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
@media (max-width: 991.98px) {
  .pos .pos-menu .logo {
    width: 140px;
    height: 5.9375rem;
    flex: 0 0 140px;
  }
}
.pos .pos-menu .logo a {
  display: block;
  text-decoration: none;
  color: inherit;
}
.pos .pos-menu .logo .logo-img {
  text-align: center;
  height: 2.875rem;
}
.pos .pos-menu .logo .logo-text {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 0.25rem;
}
.pos .pos-menu .logo img {
  max-width: 100%;
  max-height: 100%;
}
.pos .pos-menu .nav-container {
  flex: 1 1;
}
@media (min-width: 992px) {
  .pos .pos-menu .nav-container {
    overflow: hidden;
    padding: 0;
  }
}
.pos .pos-menu .nav.nav-tabs {
  margin: 0;
  padding: 0;
  display: block;
  border: 0;
}
@media (max-width: 991.98px) {
  .pos .pos-menu .nav.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
  }
}
@media (max-width: 991.98px) {
  .pos .pos-menu .nav.nav-tabs .nav-item {
    min-width: 5.625rem;
  }
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link {
  position: relative;
  padding: 1rem;
  white-space: nowrap;
  text-align: center;
  color: rgba(255, 255, 255, 0.25);
  border: none;
  font-size: 0.65625rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card {
  margin: -0.75rem -0.25rem;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card .card-body {
  padding: 0.5rem 0.7rem;
  margin: 0.5rem;
  flex: 1 1;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card .card-arrow,
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card:after,
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .card:before {
  visibility: hidden;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link .fa {
  display: block;
  margin: 0.225rem auto 0.35rem auto !important;
  font-size: 1.25rem;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active {
  color: #fff;
}
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active .card .card-arrow,
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active .card:after,
.pos .pos-menu .nav.nav-tabs .nav-item .nav-link.active .card:before {
  visibility: visible;
}
.pos .pos-content {
  flex: 1 1;
}
@media (min-width: 992px) {
  .pos .pos-content {
    overflow: scroll;
  }
}
@media (max-width: 991.98px) {
  .pos .pos-content {
    height: calc(100% - 96px);
  }
}
.pos .pos-content .pos-content-container {
  padding: 0 2rem;
}
.pos .pos-sidebar {
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 360px;
}
@media (max-width: 991.98px) {
  .pos .pos-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    z-index: 1021;
    background: linear-gradient(180deg, rgba(50, 70, 80, 0.9) 0, #0d101b 100%);
    display: none;
  }
}
.pos .pos-sidebar .pos-sidebar-header {
  padding: 1rem 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
}
.pos .pos-sidebar .pos-sidebar-header .back-btn {
  border: none;
  display: none;
  margin-right: 1rem;
}
@media (max-width: 991.98px) {
  .pos .pos-sidebar .pos-sidebar-header .back-btn {
    display: block;
  }
}
.pos .pos-sidebar .pos-sidebar-header .back-btn .btn {
  color: #fff;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.09375rem;
}
.pos .pos-sidebar .pos-sidebar-header .icon img {
  height: 1.875rem;
}
.pos .pos-sidebar .pos-sidebar-header .icon + .title {
  padding-left: 0.625rem;
}
.pos .pos-sidebar .pos-sidebar-header .title {
  font-weight: 600;
  flex: 1 1;
}
.pos .pos-sidebar .pos-sidebar-header .order {
  display: block;
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs {
  padding: 0 1.5rem;
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs .nav-item .nav-link.active {
  color: #fff;
}
.pos .pos-sidebar .pos-sidebar-nav .nav.nav-tabs .nav-item + .nav-item {
  margin-left: 0.5rem;
}
.pos .pos-sidebar .pos-sidebar-body {
  padding: 0;
  margin: 0;
  overflow: scroll;
  flex: 1 1;
}
.pos .pos-sidebar .pos-sidebar-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem 1.5rem;
}
.pos .pos-sidebar .pos-order {
  padding: 1.5rem;
  position: relative;
  display: flex;
}
.pos .pos-sidebar .pos-order .pos-order-product {
  display: flex;
  flex: 1 1;
}
.pos .pos-sidebar .pos-order .pos-order-product .img {
  width: 4.5rem;
  height: 4.5rem;
  background-position: center;
  background-size: cover;
}
.pos .pos-sidebar .pos-order .pos-order-product > * + * {
  margin-left: 1rem;
}
.pos .pos-sidebar .pos-order > * + * {
  margin-left: 1rem;
}
.pos .pos-sidebar .pos-order .pos-order-confirmation {
  position: absolute;
  top: 0;
  left: 0.0625rem;
  right: 0.0625rem;
  bottom: 0;
  z-index: 10;
  background: rgba(59, 70, 82, 0.88);
  padding: 1rem;
  margin: 0;
}
.pos .pos-sidebar .pos-order .pos-order-confirmation > * {
  position: relative;
}
.pos .pos-sidebar .pos-order .pos-order-confirmation .btn {
  min-width: 3.75rem;
}
.pos .pos-sidebar .pos-order + .pos-order {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.pos .pos-header {
  padding: 0.66rem 1.75rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
}
.pos .pos-header .logo,
.pos .pos-header .nav,
.pos .pos-header .time {
  width: 33.33%;
}
@media (max-width: 767.98px) {
  .pos .pos-header .logo,
  .pos .pos-header .nav,
  .pos .pos-header .time {
    width: 50%;
  }
}
.pos .pos-header .logo {
  display: flex;
}
.pos .pos-header .logo > a,
.pos .pos-header .logo > div {
  text-align: center;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.pos .pos-header .logo > a .logo-img img,
.pos .pos-header .logo > div .logo-img img {
  height: 2rem;
}
.pos .pos-header .logo > a .logo-text,
.pos .pos-header .logo > div .logo-text {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.0625rem;
  margin-left: 1rem;
}
.pos .pos-header .time {
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .pos .pos-header .time {
    display: none;
  }
}
.pos .pos-header .nav {
  align-items: center;
  justify-content: flex-end;
}
.pos .pos-header .nav .nav-item .nav-link {
  padding: 0.333rem 1rem;
  color: #fff;
}
@media (max-width: 767.98px) {
  .pos .pos-header .nav .nav-item .nav-link {
    padding: 0.333rem 0.66rem;
  }
}
.pos .pos-header .nav .nav-item .nav-link .nav-icon {
  height: 1.25rem;
  font-size: 1.25rem;
}
.pos .pos-header .nav .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.pos .pos-product {
  text-decoration: none;
  color: #fff;
  position: relative;
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pos .pos-product .img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 11.25rem;
  transition: all 0.2s linear;
}
.pos .pos-product .info {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
}
.pos .pos-product .info .title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--app-component-color);
  margin: 0 0 0.125rem;
}
.pos .pos-product .info .desc {
  color: rgba(255, 255, 255, 0.5);
  margin: 0 0 1rem;
  line-height: 1.4;
  flex: 1 1;
}
.pos .pos-product .info .price {
  margin: 0;
  font-weight: 600;
  color: var(--app-component-color);
  font-size: 1rem;
}
.pos .pos-product.not-available {
  cursor: not-allowed;
}
.pos .pos-product.not-available .not-available-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 11.25rem;
  background: rgba(59, 70, 82, 0.88);
  color: #fff;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pos .pos-table-booking {
  display: block;
  margin-bottom: 1.25rem;
  text-decoration: none;
  color: #fff;
}
.pos .pos-table-booking:hover .pos-table-booking-container {
  background: rgba(255, 255, 255, 0.2);
}
.pos .pos-table-booking .pos-table-booking-container {
  padding: 0;
  background: rgba(255, 255, 255, 0.1);
}
.pos .pos-table-booking .pos-table-booking-container .pos-table-booking-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-header
  .no {
  font-size: 2.5rem;
  color: #fff;
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking {
  font-size: 0.8125rem;
  padding: 0.333rem 1rem;
  display: flex;
  align-items: center;
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking
  .time {
  width: 4.6875rem;
  font-weight: 600;
  font-size: 0.74375rem;
  color: rgba(255, 255, 255, 0.5);
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking
  .info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1;
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking
  .status {
  font-size: 0.375rem;
  color: #617a86;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking
  .status.upcoming {
  color: #ff9f0c;
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking
  .status.in-progress {
  color: #e83293;
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking.highlight {
  color: #000;
  color: var(--bs-theme);
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking.highlight
  .time {
  color: rgba(232, 50, 147, 0.75);
  color: rgba(var(--bs-theme-rgb), 0.75);
}
.pos
  .pos-table-booking
  .pos-table-booking-container
  .pos-table-booking-body
  .booking
  + .booking {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.pos .pos-task {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
@media (min-width: 768px) {
  .pos .pos-task {
    display: flex;
  }
}
.pos .pos-task .pos-task-info {
  width: 30%;
  padding: 1.5rem;
  text-align: right;
}
@media (max-width: 767.98px) {
  .pos .pos-task .pos-task-info {
    width: auto;
    position: relative;
    text-align: left;
  }
}
.pos .pos-task .pos-task-body {
  padding: 1.5rem;
  flex: 1 1;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 767.98px) {
  .pos .pos-task .pos-task-body {
    border-left: none;
    padding-top: 0;
  }
}
.pos .pos-task .pos-task-body .pos-task-completed {
  font-size: 1rem;
  line-height: 2.25rem;
}
@media (max-width: 767.98px) {
  .pos .pos-task .pos-task-body .pos-task-completed {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: -0.625rem;
    padding-top: 0.625rem;
  }
}
.pos .pos-task .pos-task-body .pos-task-product {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-img {
  padding-top: 75%;
  position: relative;
  margin-bottom: 1rem;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-img .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-img .caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(53, 67, 74, 0.75);
  color: #fff;
  font-size: 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-info {
  display: flex;
  flex: 1 1;
  margin-bottom: 1rem;
}
.pos .pos-task .pos-task-body .pos-task-product .pos-task-product-action .btn {
  display: block;
}
.pos
  .pos-task
  .pos-task-body
  .pos-task-product
  .pos-task-product-action
  .btn
  + .btn {
  margin-top: 0.75rem;
}
.pos
  .pos-task
  .pos-task-body
  .pos-task-product.completed
  .pos-task-product-info {
  opacity: 0.33;
}
.pos .pos-checkout-table {
  padding: 0.0625rem;
  margin: 0;
  height: 100%;
}
.pos .pos-checkout-table .pos-checkout-table-container {
  color: #fff;
  display: block;
  padding: 0;
  height: 100%;
  text-decoration: none;
  position: relative;
  transition: all 0.1s linear;
  margin: 0.25rem;
}
.pos .pos-checkout-table .pos-checkout-table-container:before {
  transition: all 0.1s linear;
}
.pos
  .pos-checkout-table
  .pos-checkout-table-container
  .pos-checkout-table-header {
  padding: 1rem;
  text-align: center;
}
.pos
  .pos-checkout-table
  .pos-checkout-table-container
  .pos-checkout-table-header
  .status {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 0.5rem;
}
.pos
  .pos-checkout-table
  .pos-checkout-table-container
  .pos-checkout-table-info
  > .row {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0;
}
.pos
  .pos-checkout-table
  .pos-checkout-table-container
  .pos-checkout-table-info
  > .row
  > div {
  padding: 0.25rem 0.5rem;
  text-align: center;
}
.pos
  .pos-checkout-table
  .pos-checkout-table-container
  .pos-checkout-table-info
  > .row
  > div
  + div {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) {
  position: relative;
  z-index: 10;
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) .table-container {
  position: relative;
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) .table-container:before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(45, 51, 58, 0.75);
}
.pos .pos-checkout-table.in-use:hover:not(.disabled) .table-container:after {
  content: "\f00c";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--app-component-color);
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
.pos
  .pos-checkout-table.selected:not(.disabled)
  .pos-checkout-table-container
  .table-info-row,
.pos
  .pos-checkout-table.selected:not(.disabled):hover
  .pos-checkout-table-container
  .table-info-row {
  color: var(--app-component-color);
}
.pos
  .pos-checkout-table.selected:not(.disabled)
  .pos-checkout-table-container:before,
.pos
  .pos-checkout-table.selected:not(.disabled):hover
  .pos-checkout-table-container:before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(232, 50, 147, 0.5);
  background: rgba(var(--bs-theme-rgb), 0.5);
}
.pos
  .pos-checkout-table.selected:not(.disabled)
  .pos-checkout-table-container:after,
.pos
  .pos-checkout-table.selected:not(.disabled):hover
  .pos-checkout-table-container:after {
  content: "\f00c";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Font Awesome\5 Free, Font Awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
}
.pos .pos-checkout-table.disabled .pos-checkout-table-container {
  cursor: not-allowed;
  background: var(--app-component-bg);
  color: var(--app-component-color);
}
.pos .pos-checkout-table.disabled .pos-checkout-table-container .status {
  color: #ff6060;
}
.pos .pos-checkout-table.available .pos-checkout-table-container .status {
  color: #e83293;
}
.pos
  .pos-checkout-table.available
  .pos-checkout-table-container
  .pos-checkout-table-info {
  color: transparent;
}
.pos
  .pos-checkout-table.available
  .pos-checkout-table-container
  .pos-checkout-table-info
  i {
  color: transparent !important;
}
.pos .pos-checkout-table.in-use .pos-checkout-table-container {
  background: rgba(255, 255, 255, 0.15);
  position: relative;
}
.pos .pos-checkout-table.in-use .pos-checkout-table-container .status {
  color: #ff9f0c;
}
.pos.pos-vertical > .pos-container {
  flex-direction: column;
}
.pos.pos-vertical > .pos-container .pos-header + .pos-content {
  height: calc(100% - 52px);
}
.pos.pos-vertical > .pos-container > div + div {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 991.98px) {
  .pos.pos-mobile-sidebar-toggled {
    z-index: 20;
  }
  .pos.pos-mobile-sidebar-toggled .pos-content,
  .pos.pos-mobile-sidebar-toggled .pos-menu {
    opacity: 0.25;
  }
  .pos.pos-mobile-sidebar-toggled .pos-sidebar {
    display: flex;
  }
  .pos.pos-mobile-sidebar-toggled.pos-vertical > .pos-container > .pos-content {
    opacity: 1;
  }
}
.modal.modal-pos .close {
  position: absolute;
  top: 1.5625rem;
  z-index: 10;
  right: 1.5625rem;
}
@media (min-width: 992px) {
  .modal.modal-pos .modal-pos-product {
    display: flex;
  }
  .modal.modal-pos .modal-pos-product .modal-pos-product-img,
  .modal.modal-pos .modal-pos-product .modal-pos-product-info {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}
.modal.modal-pos .modal-pos-product .modal-pos-product-img {
  padding: 1rem;
}
.modal.modal-pos .modal-pos-product .modal-pos-product-img .img {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 15.625rem;
}
@media (min-width: 992px) {
  .modal.modal-pos .modal-pos-product .modal-pos-product-img .img {
    min-height: 18.75rem;
  }
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info {
  padding: 1.5rem;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.modal.modal-pos .modal-pos-product .modal-pos-product-info .option-list {
  margin: -0.333rem;
  padding: 0.333rem 0;
  display: flex;
  flex-wrap: wrap;
}
.modal.modal-pos
  .modal-pos-product
  .modal-pos-product-info
  .option-list
  .option {
  width: 33.33%;
  max-width: 33.33%;
  padding: 0.333rem;
  flex: 0 0 33.33%;
}
.modal.modal-pos
  .modal-pos-product
  .modal-pos-product-info
  .option-list
  .option
  .option-input {
  display: none;
}
.modal.modal-pos
  .modal-pos-product
  .modal-pos-product-info
  .option-list
  .option
  .option-input:checked
  + .option-label {
  color: #e83293;
  color: var(--bs-success);
  border: 1px solid #e83293;
  border: 1px solid var(--bs-success);
  background: rgba(232, 50, 147, 0.1);
  background: rgba(var(--bs-success-rgb), 0.1);
}
.modal.modal-pos
  .modal-pos-product
  .modal-pos-product-info
  .option-list
  .option
  .option-label {
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 0.625rem;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal.modal-pos
  .modal-pos-product
  .modal-pos-product-info
  .option-list
  .option
  .option-label
  .option-text {
  display: block;
  line-height: 1.2;
  opacity: 0.75;
  font-size: 0.8125rem;
  text-align: center;
}
.modal.modal-pos
  .modal-pos-product
  .modal-pos-product-info
  .option-list
  .option
  .option-label
  .option-price {
  font-weight: 600;
  margin-top: 0.25rem;
  line-height: 1.2;
}
.pos-mobile-sidebar-toggler {
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  z-index: 1020;
  background: #fff;
  padding: 0.5rem;
  width: 3.75rem;
  height: 3.75rem;
  text-decoration: none;
  color: #000;
  display: none;
  border-radius: 50px;
  box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.175);
  align-items: center;
  z-index: 10;
}
.pos-mobile-sidebar-toggler:before {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  border: 2px solid #fff;
  border-radius: 50px;
}
@media (max-width: 991.98px) {
  .pos-mobile-sidebar-toggler {
    display: flex;
  }
}
.pos-mobile-sidebar-toggler:hover {
  color: #526872;
}
.pos-mobile-sidebar-toggler i {
  font-size: 2rem;
  display: block;
  margin: 0 auto;
}
.pos-mobile-sidebar-toggler .badge {
  position: absolute;
  right: -0.3rem;
  top: -0.3rem;
  background-color: #ff6060;
  background-color: var(--bs-red);
  color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.profile .profile-img {
  height: 12.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
  z-index: 10;
}
.profile .profile-img img {
  max-width: 100%;
  width: 100%;
}
.profile .profile-tab {
  position: relative;
  padding: 0 1.5rem;
  flex: 1 1;
}
@media (max-width: 991.98px) {
  .profile .profile-tab {
    overflow: scroll;
    padding: 0 1rem;
    display: flex;
    flex-wrap: nowrap;
  }
}
.profile .profile-tab .nav-item .nav-link {
  text-align: center;
}
.profile .profile-tab .nav-item .nav-link .nav-value {
  font-size: 1.3125rem;
  font-weight: 600;
  margin-bottom: -0.35rem;
}
@media (max-width: 767.98px) {
  .profile .profile-tab .nav-item .nav-link .nav-value {
    font-size: 1.00625rem;
  }
}
.profile .profile-tab .nav-item + .nav-item {
  margin-left: 1.5rem;
}
.profile .profile-container {
  display: flex;
}
@media (max-width: 991.98px) {
  .profile .profile-container {
    display: block;
  }
}
.profile .profile-container .profile-sidebar {
  width: 15.625rem;
  padding: 1.5rem;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 991.98px) {
  .profile .profile-container .profile-sidebar {
    display: none;
  }
}
.profile .profile-container .profile-content {
  flex: 1 1;
}
.profile .profile-container .profile-content .profile-content-container {
  padding: 1.5rem;
}
@media (max-width: 991.98px) {
  .profile .profile-container .profile-content .profile-content-container {
    padding: 1rem;
  }
}
.profile .profile-img-list {
  list-style-type: none;
  margin: -0.25rem;
  padding: 0;
}
.profile .profile-img-list:after,
.profile .profile-img-list:before {
  content: "";
  display: table;
  clear: both;
}
.profile .profile-img-list .profile-img-list-item {
  width: 25%;
  padding: 0.25rem;
  float: left;
}
.profile .profile-img-list .profile-img-list-item.main {
  width: 50%;
}
.profile .profile-img-list .profile-img-list-item .profile-img-list-link {
  display: block;
  padding-top: 75%;
  overflow: hidden;
  position: relative;
}
.profile
  .profile-img-list
  .profile-img-list-item
  .profile-img-list-link
  .profile-img-content,
.profile .profile-img-list .profile-img-list-item .profile-img-list-link img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.profile
  .profile-img-list
  .profile-img-list-item
  .profile-img-list-link
  .profile-img-content:before,
.profile
  .profile-img-list
  .profile-img-list-item
  .profile-img-list-link
  img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
.profile
  .profile-img-list
  .profile-img-list-item.with-number
  .profile-img-number {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  font-size: 1.53125rem;
  font-weight: 600;
  line-height: 1.625rem;
  margin-top: -0.765625rem;
  text-align: center;
}
.register {
  min-height: 100vh;
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  .register {
    padding: 1rem;
  }
}
.register .register-content {
  flex: 1 1;
  max-width: 22.5rem;
  margin: 0 auto;
  position: relative;
}
.search-result .search-input {
  position: relative;
  max-width: 45rem;
  margin-bottom: 0.9375rem;
}
.search-result .search-close {
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  width: 1.625rem;
  height: 1.625rem;
  text-align: center;
  font-size: 1.25rem;
  padding: 0 0 0.125rem;
  color: #fff;
  background: rgba(255, 255, 255, 0.25);
  text-decoration: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0.75rem;
}
.search-result .search-close:focus,
.search-result .search-close:hover {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.5);
  color: #fff;
}
.search-result .search-tab {
  list-style-type: none;
  margin: 0 -2rem;
  padding: 0 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  white-space: nowrap;
  display: flex;
}
@media (max-width: 767.98px) {
  .search-result .search-tab {
    margin: 0 -0.9375rem;
    padding: 0 0.9375rem;
    overflow: scroll;
  }
}
.search-result .search-tab .search-tab-item .search-tab-link {
  display: block;
  padding: 0.625rem;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  margin-bottom: -1px;
}
.search-result .search-tab .search-tab-item .search-tab-link:focus,
.search-result .search-tab .search-tab-item .search-tab-link:hover {
  border-bottom-color: #90a2aa;
}
.search-result .search-tab .search-tab-item .search-tab-link.active {
  font-weight: 600;
  color: #000;
  color: var(--bs-theme);
  border-bottom-color: #000;
  border-bottom-color: var(--bs-theme);
}
.search-result .search-tab .search-tab-item + .search-tab-item {
  margin-left: 1rem;
}
.search-result .search-result-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.search-result .search-result-list .search-result-item {
  padding: 2rem 0;
  display: flex;
}
.search-result .search-result-list .search-result-item:after,
.search-result .search-result-list .search-result-item:before {
  content: "";
  display: table;
  clear: both;
}
.search-result .search-result-list .search-result-item:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.search-result .search-result-list .search-result-item + .search-result-item {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.search-result .search-result-list .search-result-item .search-result-media {
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .search-result .search-result-list .search-result-item .search-result-media {
    width: 5rem;
    height: 3.75rem;
  }
}
.search-result
  .search-result-list
  .search-result-item
  .search-result-media
  img {
  max-width: 100%;
  max-height: 100%;
}
.search-result
  .search-result-list
  .search-result-item
  .search-result-media
  + .search-result-content {
  padding-left: 1.875rem;
}
.search-result .search-result-list .search-result-item .search-result-content {
  flex: 1 1;
}
.search-result
  .search-result-list
  .search-result-item
  .search-result-content
  .h3,
.search-result
  .search-result-list
  .search-result-item
  .search-result-content
  h3 {
  margin: 0 0 0.3125rem;
  font-size: 1.25rem;
}
.search-result
  .search-result-list
  .search-result-item
  .search-result-content
  .h3
  a,
.search-result
  .search-result-list
  .search-result-item
  .search-result-content
  h3
  a {
  color: #fff;
  text-decoration: none;
}
.search-result
  .search-result-list
  .search-result-item
  .search-result-content
  .h3
  a:hover,
.search-result
  .search-result-list
  .search-result-item
  .search-result-content
  h3
  a:hover {
  text-decoration: underline;
}
.widget-chat {
  margin: 0;
  padding: 0;
}
.widget-chat .widget-chat-item {
  display: flex;
  align-items: flex-end;
}
.widget-chat .widget-chat-item + .widget-chat-item {
  margin-top: 1rem;
}
.widget-chat .widget-chat-item > div + div {
  padding-left: 0.75rem;
}
.widget-chat .widget-chat-date {
  font-size: 0.75rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  padding: 0.75rem;
  text-align: center;
}
.widget-chat .widget-chat-content {
  flex: 1 1;
}
.widget-chat .widget-chat-name,
.widget-chat .widget-chat-status {
  font-size: 0.6875rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.75);
  margin: 0.25rem 0;
  line-height: 1.5;
}
.widget-chat .widget-chat-media {
  width: 1.75rem;
}
.widget-chat .widget-chat-media img {
  max-width: 100%;
  display: block;
  border-radius: 28px;
}
.widget-chat .widget-chat-message {
  background: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  display: inline-block;
  position: relative;
  border-radius: 8px;
}
.widget-chat .widget-chat-message + .widget-chat-message {
  margin-top: 0.3rem;
}
.widget-chat .widget-chat-message.last:before {
  content: "";
  border: 0.3125rem solid transparent;
  border-bottom-color: #fff;
  position: absolute;
  bottom: 0.375rem;
  left: -0.25rem;
  border-right-color: #fff;
}
.widget-chat .reply .widget-chat-content {
  text-align: right;
}
.widget-chat .reply .widget-chat-message {
  background: #000;
  background: var(--bs-theme);
  color: rgba(0, 0, 0, 0.85);
}
.widget-chat .reply .widget-chat-message.last:before {
  border-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: var(--bs-theme);
  border-left-color: #000;
  border-left-color: var(--bs-theme);
  left: auto;
  right: -0.25rem;
}
.widget-img-list {
  margin: -0.0625rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.widget-img-list .widget-img-list-item {
  padding: 0.0625rem;
  width: 20%;
}
.widget-img-list .widget-img-list-item a {
  display: block;
  text-decoration: none;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}
.widget-img-list .widget-img-list-item a .img,
.widget-img-list .widget-img-list-item a img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
}
.widget-img-list .widget-img-list-item a .img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.widget-reminder .widget-reminder-item {
  position: relative;
  display: flex;
}
.widget-reminder .widget-reminder-header + .widget-reminder-item,
.widget-reminder .widget-reminder-item + .widget-reminder-header,
.widget-reminder .widget-reminder-item + .widget-reminder-item {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.widget-reminder .widget-reminder-content,
.widget-reminder .widget-reminder-time {
  padding: 1rem;
}
.widget-reminder .widget-reminder-content {
  flex: 1 1;
}
.widget-reminder .widget-reminder-time {
  width: 5rem;
  font-size: 0.8125rem;
  text-align: right;
}
.widget-reminder .widget-reminder-divider {
  width: 0.1875rem;
  background: rgba(255, 255, 255, 0.3);
}
.widget-reminder + .card-body,
.widget-reminder + .card-footer,
.widget-reminder + .card-header {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.widget-user-list {
  display: flex;
  align-items: center;
}
.widget-user-list .widget-user-list-item .widget-user-list-link {
  border: 2px solid #273136;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}
.widget-user-list .widget-user-list-item .widget-user-list-link img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.widget-user-list .widget-user-list-item + .widget-user-list-item {
  margin-left: -1.25rem;
}

.fallback-spinner {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.effect-1,
.effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #2962ff;
  border-radius: 50%;
  box-sizing: border-box;
}

.effect-1 {
  -webkit-animation: rotate 1s ease infinite;
          animation: rotate 1s ease infinite;
}
.effect-2 {
  -webkit-animation: rotateOpacity 1s ease infinite 0.1s;
          animation: rotateOpacity 1s ease infinite 0.1s;
}
.effect-3 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #2962ff;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}
.fallback-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

